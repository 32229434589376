.blog-home, #blog_list_1-7 .blog-grid {
  .block_cont {
    background: #f7f7f7;
    border-radius: 10px;
    padding: 0 0 19px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .block_top {
      img {
        max-width: 100%;
        width: 100%;
        display: block;
        border-radius: 10px 10px 0 0;
      }
    }
    .block_bas {
      h3 {
        font-size: 16px;
        text-decoration: none;
        text-align: center;
        font-weight: 600;
        text-transform: uppercase;
        padding: 15px 10px 0;
      }

      .blog_desc {
        padding: 0 10px;
        text-align: center;
      }

      .date_blog-cat {
        font-weight: 600;
        color: $vert-dark;
        margin-bottom: 15px;
        padding: 0 10px;
        font-size: 10px;
        a.categorie_blog {
          color: $orange;
          font-size: 10px;
        }
      }

    }

    .prestablog_more {
      margin: auto 0 0 0;
      font-size: 18px;
      text-align: center;
    }
  }
}

.prestablogcat.products-section-title {
  margin-top: 40px !important;
  margin-bottom: 10px !important;
}
.info_blog {
  font-weight: 600;
  font-size: 14px;
  display: block;
  margin-bottom: 30px;
}

.categories-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 40px 0 60px;
  li {
    margin: 0 7px;
    a {
      font-family: Teko, sans-serif;
      font-size: 25px;
    }
  }
}

.prestablogcatContent {
  .block_cont {
    margin-bottom: 30px;
    height: calc(100% - 30px);
  }
}

.prestablogtitle {
  text-align: center;
  font-size: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
}

#prestablogfront {
  margin-bottom: 90px;
  margin-top: 40px;
  h1 {
    max-width: 80%;
    img.titre-logo {
      top: 25px;
    }
  }
  .info_blog {
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    a {
      color: $orange;
    }
  }
  h2 {
    font-size: 30px;
    @media (min-width: 992px) {
      font-size: 40px;
    }
  }
  h3 {
    font-size: 25px;
    @media (min-width: 992px) {
      font-size: 30px;
    }
  }
  h4 {
    font-size: 20px;
    @media (min-width: 992px) {
      font-size: 25px;
    }
  }

  .prestablogtitle {
    margin-top: 70px;
  }

  .blogsoc-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 70px;
    li {
      margin: 0 10px;
    }
  }
}



#blog_product_linked {
  .img-thumbnail {
    border: none;
  }
  .product_blog {
    margin-bottom: 30px;
    background: #f7f7f7;
    border-radius: 10px;
    height: calc(100% - 30px);
    padding: 5px 7px 19px;
    a {
      display: block;
      .titre_product_blog {

        text-align: center;
        display: block;
        padding: 6px 0 0;
        margin: 0;
        font-size: 16px;


      }
      .price_product_blog {
        font-weight: 500;
        text-align: center;
        margin-top: 8px;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        .blog_product_regular_price {
          text-decoration: line-through;
          font-size: 16px;
          margin-left: 5px;
        }


      }
      img {
        text-align: center;
        margin: 0 auto 15px;
        display: block;
        aspect-ratio: 1 / 1;
        object-fit: contain;
        width: 100%;
      }
    }

  }
}

#blog_list_1-7 {
  display: flex;
  justify-content: center;
  .blog-grid {
    width: 100%;
    @media (min-width: 768px) {
      width: 50%;
    }
    @media (min-width: 992px) {
      width: 25%;
    }
    .block_bas {
      h3 {
        margin-bottom: 15px;
      }
    }
  }
}