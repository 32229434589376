.featured-products {
  &.featured-products-swiper {
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    position: relative;
    .product-miniature {
      flex-basis: auto!important;
      max-width: 100% !important;
    }
    .swiper-wrapper {
      flex-direction: row;
      padding-left: 10px;
      min-width: 100%;
    }
    .swiper-button-next {
      right: 8px;
    }
    .swiper-button-prev {
      left: auto;
      right: 60px;
    }
    .swiper-button-prev,
    .swiper-button-next {
      border-radius: 50%;
      border: 1px solid #C2C2C2;
      width: 36px;
      height: 36px;
      top: 57%;
      color: #232615;
      transition: all 0.3s ease;

      &:hover {
        color: $brand-hover;
        border: 1px solid $brand-hover;
      }
    }
    .swiper-button-next::after, .swiper-button-prev::after {
      font-size: 16px;
    }
  }
  .products {
    .product-miniature {
      &:nth-child(4n+5) {
        clear: both;
      }
    }
  }
  .products-section-title span {
    display: block;
    font-size: 14px;
    text-transform: none;
    padding-top: 7px;
    color: #777;
  }
}
.featured-products,
.product-miniature  {
  text-align: center;

  .product-name-product-manufacturer {
    display: flex;
    flex-direction: column-reverse;
  }
  .btn-row {
    margin-top: 15px;
    transition: all .3s ease;
    .btn-primary {
      padding: 12.5px 36px;
      font-size: 18px;
    }
  }
  .btn-quick-view {
    transition: all .2s ease;
  }
  .bonwishlist-hook-wrapper {
    transition: all .35s ease;
  }

  .boncompare-hook-wrapper {
    transition: all .55s ease;
  }

  .bonwishlist-hook-wrapper,.boncompare-hook-wrapper,.btn-quick-view {
    transform: translateX(20%);
    opacity: 0;
  }

  .product-thumbnail {
    display: block;
  }
  .product-title a {
    color: #232615;
    font-size: 16px;
    text-decoration: none;
    text-align: center;
    font-weight: 400;
    &:hover {
      color: $brand-hover;
    }
  }
  .thumbnail-container-inner {
    position: relative;
  }
  .thumbnail-container {
    .comments_note {
      span {
        display: none;
      }
    }
    .thumbnail-container-images {
      position: relative;
      overflow: hidden;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      img {
        max-width: 100%;
        height: auto;
      }
      .add-to-cart-block{
        display: flex;
        align-content: center;
        justify-content: space-around;
        align-items: center;
        position: absolute;
        flex-direction: column;
        @media (max-width: 1200px) {
          top: calc(10px + (18 - 10) * ((100vw - 120px) / (1200 - 120)));
        }
        @media (max-width: 991px) {
          top: calc(10px + (18 - 10) * ((100vw - 120px) / (991 - 120)));
        }
        @media (max-width: 576px) {
          top: calc(10px + (18 - 10) * ((100vw - 120px) / (576 - 120)));
        }
        top: calc(5px + (18 - 5) * ((100vw - 120px) / (1880 - 120)));
        right: 18px;
        width: max-content;
        z-index: 1;
        transition: all .3s linear;
        > div{
          width: 37px;
          height: 37px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
        }

        .bon-tooltip {
          position: relative;
          cursor: pointer;
          .quick-view{
            background: none;
            border: none;
            color: #232615;
            padding: 0;
            font-size: 21px;
            transition: all 0.3s ease 0s;

            i:before {
              transition: all 0.3s ease 0s;
            }

            &.active {
              animation: pulsefar 1s ease;
            }
            &:focus{
              outline: none;
            }
          }
          &.view-product i {
            color: $brand-primary;
            position: unset!important;
            transition: all 0.3s ease 0s;
            @media (any-hover: hover) {
              &:hover {
                color: $brand-hover;
              }
            }
          }
          &.view-product a{
            font-size: 25px;
          }
        }
        .bon-tooltip {
          &.btn-quick-view {
            @media (any-hover: hover) {
              &:hover .quick-view i:before {
                //color: $brand-hover;
                color: #fff;
              }
            }
          }
        }
      }
    }

    .thumbnail-container-bottom{
      transition: all .3s ease;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      overflow: hidden;
    }
    .highlighted-informations {
      margin: 0 0 19px 0;
    }

    .highlighted-informations,
    .bonthumbnails,
    .quick-view {
      display: block;
      cursor: pointer;
    }
  }
  .product-description {
    position: relative;
    padding: 5px 7px 19px;
    z-index: 1;
    background: #F7F7F7;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    min-height: 143px;
  }
}

.products-section-title {
  position: relative;
  letter-spacing: 1.5px;
  width: 100%;
  font-weight: 500;
  font-size: 35px;
  line-height: 1;
  transition: all .3s ease;
  @media (min-width: 992px) {
    font-size: 60px;
  }
}
.img-subtitle {
  max-width: 100%;
  display: block;
  margin: 0 auto 0.5rem;
}

.product-title {
  text-align: center;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: horizontal;
  height: 45px;
  padding: 6px 0 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}
.product-price-and-shipping {
  color: $gray-black;
  font-weight: 500;
  text-align: center;
  margin-top: 8px;
  font-size: 20px;
  .price-has-discount {
    color: $brand-primary;
  }
}
.variant-links {
  display: flex;
  justify-content: center;
  position: relative;
  text-align: center;
  width: 100%;
  top: -0.25em;
  padding-top: 10px;
  min-height: 2.5rem;
  background: white;
}
.highlighted-informations {
  .quick-view {
    color: $gray;
    font-size: $base-font-size;
  }
}
.product-miniature {
  @media (min-width: 1200px) {
    &.col-lg-3 {
      flex: 0 0 20%!important;
      max-width: 20%!important;
    }
  }

  .product-flags {
    &.new {
      display: block;
      background: $brand-primary;
      font-weight: 500;

      color: white;
      z-index: 1;
      @include box-shadow-light;
    }
    &.on-sale {
      background: $brand-primary;
      &:after {
        border-color: $brand-primary;
      }
    }
  }

  .online-only,
  .pack {
    display: block;
    color: white;
    background: $brand-primary;

    font-size: $base-font-size;
    font-weight: 600;
    @include box-shadow-light;
    &.online-only {
      font-size: $font-size-xs;
      margin-top: 13rem;
      margin-left: 8.688rem;
      &::before{
        content:"\E30A";
        font-family: 'Material Icons';
        vertical-align: middle;
        margin: 0.3125rem;
      }
    }
  }
}
.comments_note {
  text-align: center;
  color: $gray;
}
.regular-price {
  color: #232615;
  text-decoration: line-through;
  font-size: 16px;
  margin-left: 5px;
}
.count {
  color: $gray;
  font-weight: 400;
  position: relative;
  bottom: 0.5rem;
}
.all-product-link {
  float: none!important;
  color: $gray;
  font-weight: 700;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
@media screen and (min-width: 1881px) {
  .featured-products {
    margin: 30px 0 20px 0!important;
  }
  .product-miniature {
    margin: 0 0 20px 0!important;
  }
  .products-section-title {
    margin-bottom: 30px;
  }
  .products-section-title_svg {
    width: 188px;
    &.left {
      margin-right: 37px;
    }
    &.right {
      margin-left: 37px;
    }
  }
}
@media (max-width: 1880px) {
  .featured-products {
    margin: calc(10px + (30 - 10) * ((100vw - 320px) / (1880 - 320))) 0 calc(10px + (20 - 10) * ((100vw - 320px) / (1880 - 320))) 0!important;
  }
  .product-miniature {
    margin: 0 0 calc(10px + (20 - 10) * ((100vw - 320px) / (1880 - 320))) 0!important;
  }
  .products-section-title_svg {
    width: calc(120px + (188 - 120) * ((100vw - 992px) / (1880 - 992)));
    &.left {
      margin-right: 37px;
    }
    &.right {
      margin-left: 37px;
    }
  }
  .products-section-title {
    //font-size: calc(20px + (35 - 20) * ((100vw - 320px) / (1880 - 320)));
    margin-bottom: calc(14px + (30 - 14) * ((100vw - 320px) / (1880 - 320)));
  }
}
@media (max-width: 1279px) {
  body {
    .highlighted-informations,
    .bonthumbnails {
      display: none!important;
    }
    .featured-products .thumbnail-container, .product-accessories .thumbnail-container, .product-miniature .thumbnail-container {
      padding: 0;
    }
  }
  .featured-products,
  .product-accessories,
  .product-miniature {
    margin: 0 0 20px 0 !important;
    text-align: center;
    .thumbnail-container-bottom{
      position: relative;
    }
  }

  .featured-products .thumbnail-container .thumbnail-container-bottom .add-to-cart-block,
  .product-miniature .thumbnail-container .thumbnail-container-bottom .add-to-cart-block{
    top: -43px;
  }
  #products, .featured-products, .product-accessories, .product-miniature {
    .thumbnail-container {
      .bonwishlist-hook-wrapper, .boncompare-hook-wrapper, .btn-quick-view {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}

@media only screen and (min-width: 1280px) {
  .featured-products,
  .product-miniature {
    .btn-row {
      height: 0;
      .btn-primary {
        transform: translateY(45px);
        opacity: 0;
      }
    }
    .thumbnail-container {
      &:hover {
        .thumbnail-container-bottom {
          transform: translateY(-45px);
          margin-bottom: -45px;
        }

        .btn-row {
          height: 45px;

          .btn-primary {
            transform: translateY(0);
            opacity: 1;
          }
        }
      }
    }
  }
  #products,.featured-products,.product-accessories,.product-miniature  {
    .thumbnail-container {
      .thumbnail-container-images {
        position: relative;
        overflow: hidden;
        .bonthumbnails {
          float: right;
          width: 22.4%;
        }
      }
      .thumbnail-container-bottom {
        width: 100.1%;
      }

      &:hover, &:focus {
        border-bottom: none;
        z-index: 9;
        position: relative;
        .bonwishlist-hook-wrapper,.boncompare-hook-wrapper,.btn-quick-view {
          transform: translateY(0);
          opacity: 1;
        }
        .productcountdown {
          //display: none;
          pointer-events: none;
          opacity: 0;
          transition: all 0.3s ease;
        }
        .add-to-wish-list {
          display: block;
        }
        .comments_note {
          display: block;
        }

        .thumbnail-container-bottom {
          background: #fff;
          //z-index: 999;
          .add-to-cart-block{
            top: -60px;
            transition: all .3s linear;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1310px){
  .featured-products .thumbnail-container .thumbnail-container-bottom .add-to-cart-block .bon-tooltip.btn-quick-view,
  .product-miniature .thumbnail-container .thumbnail-container-bottom .add-to-cart-block .bon-tooltip.btn-quick-view{
    .bon-tooltiptext{
      left: 60px;
      &::after{
        left: 35px;
      }
    }
  }
}

@media only screen and (max-width: 1280px){
  .product-miniature {
    padding-bottom: 0;
  }
  .featured-products .thumbnail-container .thumbnail-container-bottom .add-to-cart-block .bon-tooltip,
  .product-miniature .thumbnail-container .thumbnail-container-bottom .add-to-cart-block .bon-tooltip{
    padding: 11px 16px;
  }
}
@media screen and (max-width: 991px) {
  .products-section-title_svg {
    display: none;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px){
  #products,.featured-products,.product-accessories{
    .thumbnail-container {
      .thumbnail-container-images .quick-view {
        display: block;
        left: 10px;
        bottom: 10px;
      }
    }
  }
}


@media only screen and (max-width: 480px){
  #products,.featured-products,.product-accessories{
    .thumbnail-container {
      .ajax_add_to_cart_button {
        width: 100%;
      }

      .thumbnail-container-images .quick-view {
        display: block;
        left: 10px;
        bottom: 10px;
      }
    }
  }
  .product-miniature {
    .product-thumbnail {
      img {
        max-height: 200px;
        width: 100%;
        object-fit: contain;
        background-color: white;
      }
    }
    .btn-row {
      .btn-primary {
        padding: 9.5px 25px;
        font-size: 16px;
      }
    }
  }
}