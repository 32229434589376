.bonslider-thumb-wrapper {
  display: none;
}

#bonnews.bon-home h2:hover, #bonnews.bon-home .author-date_post {
  color: $orange;
}

#bonbanners {
  .bonbanners-list {
    margin-bottom: 0 !important;
  }
  .bonbanners-item:nth-child(3) {
    height: 100%;
    img {
      height: 100%;
      object-fit: cover;
    }
  }
}

#index {
  .page-home {
    .btn-primary, .btn-secondary {
      &:not(.add-to-cart) {
        font-size: 20px;
      }
    }
  }
  @media (min-width: 768px) {
    .layout_layout5:not(.click_open_submenu) .mm_menus_li:hover > a, .layout_layout5.click_open_submenu .mm_menus_li.open_li > a, .layout_layout5 .mm_menus_li.active > a, .layout_layout5 .mm_extra_item button[type="submit"]:hover i, #header .layout_layout5:not(.click_open_submenu) .mm_menus_li:hover > a, #header .layout_layout5.click_open_submenu .mm_menus_li.open_li > a, #header .layout_layout5 .mm_menus_li.active > a, .layout_layout5:hover .ybc-menu-vertical-button {
      color: $vert-light;
    }
  }
  .boncategoruproduct, .featured-products, .blog-home {
    padding-top: 60px !important;

  }

  .only-mobile {
    display: none;
  }

  @media (max-width: 767px) {
    #bonbanners, .presentation-qgs, .blog-home {
      display: none;
    }
    .only-mobile {
      display: block;
      #bonbanners, .presentation-qgs, .blog-home {
        display: block;
      }
      .presentation-qgs {
        margin-bottom: 0 !important;
      }
      #bonbanners {
        .bonbanners-list {
          .bonbanners-item {
            &:not(:last-of-type) {
              display: none;
            }
            .img-responsive {
              min-height: 300px;
              object-fit: cover;
              object-position: right;
            }
          }

        }
      }
    }
    .featured-products {
      padding-top: 30px !important;
    }
  }

  .boncategoruproduct {
    .tabs-items {
      .tab {
        font-family: Teko, sans-serif;
        font-size: 25px;
      }
    }
    .h1 {
      font-size: 35px;
      @media (min-width: 992px) {
        font-size: 45px;
      }
    }
    @media (max-width: 767px) {
      .img-content {
        .product-category-img {
          position: absolute;
          object-position: top;
        }
        span {
          position: relative;
          top: auto;
          left: auto;
          padding: 15px;
          display: block;
        }
      }
    }
  }



  #bonslider {
    .swiper-button-next, .swiper-button-prev {
      top: 60%;
    }
    @media (max-width: 767px) {
      .img-responsive {
        min-height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
      .bonslider-item {
        height: auto;
      }

      .link-bonslider {
        position: relative;
        display: flex;
        height: 100%;
        padding: 30px;
        flex-direction: column-reverse;
        justify-content: flex-end;
        .bonslider-subitem, .bonslider-item-description {
          position: relative !important;
          z-index: 2;
          top: auto !important;
          right: auto !important;
          img {
            display: block;
            margin-top: 30px;
            max-width: 100%;
          }
        }
      }

    }
    .h2, .h3 {
      text-transform: none;
      font-weight: 300;
      font-size: 25px;
      line-height: 1.2;
      @media (min-width: 992px) {
        font-size: 35px;
      }
    }
    .h1 {
      font-weight: 500;
      font-size: 40px;
      line-height: 1;
      @media (min-width: 992px) {
        font-size: 70px;
      }
      @media (min-width: 1200px) {
        font-size: 95px;
        margin-bottom: 10px;
      }
    }
    .h3 {
      font-family: "Teko", sans-serif;
    }
  }

  #bonbanners {
    .h1 {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 25px;
      line-height: 1;
      @media (min-width: 992px) {
        font-size: 34px;
        line-height: 43px;
      }
    }
    .h2 {
      font-size: 20px;
      line-height: 1;
      @media (min-width: 992px) {
        font-size: 30px;
      }
    }
    .h3 {
      font-family: Teko, sans-serif;
      font-weight: 300;
      font-size: 20px;
      line-height: 1.2;
      text-align: center;
      @media (min-width: 992px) {
        font-size: 25px;
      }
    }
    .bonbanners-item {
      &:nth-child(1) {
        .h3 {
          text-align: left;
        }
      }
    }
  }
}

#bon_manufacturers_block {
  h2 {
    margin-bottom: 0 !important;
  }
  @media (max-width: 767px) {
    .btn-primary {
      text-align: center;
      font-size: 16px !important;
    }
  }
}


.presentation-qgs {
  background-color: $orange;
  padding-top: 60px;
  padding-bottom: 30px;
  margin-top: 60px;
  margin-bottom: 15px;
  * {
    color: $white !important;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    img {
      width: 150px;
      margin-bottom: 15px;
    }
    p {
      color: $brand-primary;
      font-size: 14px;
      max-width: 100%;
      margin: 0 auto 15px;
      @media (min-width: 992px) {
        //width: 850px;
        font-size: 16px;
      }
    }
  }
}

#footer_contact {
  background-color: $orange;
  padding-bottom: 30px;
  padding-top: 50px;
  margin-bottom: 40px;
  .footer_contact_wrapper {
    justify-content: center;
  }
}