#footer_before {
  #bonhtmlcontent {
    width: 100%;
    .container {
      width: 100% !important;
    }
  }
  .link-htmlcontent {
    .box-htmlcontent {
      justify-content: center;
      flex-direction: column;
      font-family: Teko, sans-serif;
      .h3, .h5 {
        text-align: center;
        text-transform: uppercase;
        font-size: 25px;
        display: block;
      }
      .h3 {
        font-weight: 300;
        margin-bottom: 0;
        color: #7a7a7a;
      }
      .h5 {
        font-weight: 500;
        color: #7a7a7a;
      }
      .box-icon {
        margin-right: 0;
        margin-bottom: 10px;
      }

    }
  }
}

@media (max-width: 767px) {
  #footer .footer-container .block_newsletter, #footer .footer-container .links {
    padding: 0;
  }
}


#footer {

  .navbar-toggler {
    i::before {
      color: $white;
    }
  }
  .collapse:not(.show) {
    display: block;
  }
  #footer_infos {
    p {
      font-size: 13px;
      line-height: 1.7;
      color: $white;
    }
    a {
      font-size: 13px;
      line-height: 1.7;
      color: $white;
      text-decoration: underline !important;
    }
    @media (max-width: 767px) {
      padding: 0 20px;
    }
  }

  .block_newsletter {
    @include search-box;


    .collapse {
      display: inherit;
    }

    #footer_newsletter {

      p {
        font-size: 13px;
        line-height: 1.7;
        color: $body-color;
        max-width: 80%;

        &.alert-success {
          color: $brand-success;
        }

        &.alert-danger {
          color: $brand-danger;
        }
      }
    }



    form {
      .input-wrapper {
        overflow: hidden;
        width: max-content;
        margin-bottom: 15px;
        border-bottom: 1px solid #DCDCDC;
        transition: all .3s ease;
        &:focus-within {
          border-bottom-color: $brand-header-hover;
        }
      }

      input[type=email] {
        padding: 11px 11px 11px 0;
        width: 239px;
        border: none;
        float: left;
        background: transparent;
        color: $body-color;
        font-size: 13px;
        &::placeholder {
          color: $body-color;
        }
        &:focus {
          outline: 0;
        }

      }

      .btn-footer {
        width: 44px;
        height: 44px;
        margin-left: 5px;
        position: relative;
        float: left;
        box-shadow: none;
        background: none;

        &:hover {
          &:after {
            color: $brand-hover;
          }
        }

        &:after {
          content: "\e049";
          font-family: "bonicon";
          font-size: 20px;
          position: absolute;
          top: 10px;
          transition: all .3s ease;
          right: 11px;
          color: $brand-footer-title;
          cursor: pointer;
        }
      }
    }

    margin-bottom: 0.625rem;
  }


  .footer-container {
    .app-stores {
      img {
        transition: all 0.2s linear;

        &:hover {
          background-color: #eaeaea;
        }

      }

      .app-store {
        margin-right: 15px;
      }
    }

    .block-contact {
      overflow: hidden;

      .block-contact-title {
        color: #232615;
      }

      ul {
        position: relative;
      }

      a {
        &::after {
          display: none;
        }

        &:hover {
          color: $gray-black;
        }
      }

      .footer {
        .h3 {
          margin-bottom: 20px;
        }

        // &_adsress,
        // &_phone,
        // &_fax,
        // &_email {
        //   &:before {
        //     font-family: "fl-puppets";
        //     font-size: 15px;
        //     margin-right: 8px;
        //     color: $gray;
        //     transition: all 0.2s linear;
        //   }
        // }

        // &_adsress {
        //   &:before {
        //     content: "\e02e";
        //   }
        // }

        // &_phone {
        //   &:before {
        //     content: "\e054";
        //   }
        // }

        // &_fax {
        //   &:before {
        //     content: "\e028";
        //   }
        // }

        // &_email {
        //   &:before {
        //     content: "\e03c";
        //     font-size: 14px;
        //   }
        // }

        // &_phone,
        // &_fax,
        // &_email {
        //   &:hover {
        //     &:before {
        //       color: #232615;
        //     }
        //   }
        // }
      }

    }
  }

  .block-contact-logo {
    margin: 45px 0 0px;
    display: inline-block;
    text-align: center;

    .logo {
      max-width: 100%;
    }
  }

  .linklist {
    .blockcms-title a {
      color: $gray-darker;
    }
  }

  .account-list a {
    color: $gray;

    &:hover {
      color: $brand-primary;
    }
  }



  #block_myaccount_infos .myaccount-title a {
    font-size: 16px;

    &:hover {
      color: $brand-primary;
    }
  }

  .footer-container {
    padding: 0 0 15px;
    overflow: hidden;
    background: $brand-primary;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);

    #block_myaccount_infos {
      .myaccount-title a {
        color: $white;
      }
    }

    .h3 {
      font-size: 16px;
      margin-bottom: 22px;
      font-weight: 600;
      color: $white;
      text-transform: uppercase;
      font-family: Teko, sans-serif;
    }

    .links {
      padding-top: 40px;



      li {
        margin-bottom: 10px;

        a {
          color: $body-color!important;
          cursor: pointer;
          font-size: 13px;
          position: relative;
        }

        span {
          font-size: 13px;
          color: $body-color;
          font-weight: normal;
          display: inline-block;
          padding-left: 27px;
          position: relative;
          line-height: 1.6;

          &:before {
            position: absolute;
            top: -2px;
            left: 0;
            font-size: 18px;
            color: $body-color;
          }
        }
      }
    }

    .links:not(.block-contact) {
      li {
        a {
          display: flex;
          -ms-flex-align: center;
          align-items: center;
          max-width: max-content;
          &:before {
            content: "";
            width: 0;
            height: 1px;
            transition: all .3s ease;
            background: $body-color;
            opacity: 0;
          }

          &:hover:before {
            opacity: 1;
            width: 10px;
            margin-right: 10px;
          }
        }
      }
    }
  }

  .footer-container-bottom {
    background: $brand-primary;
    padding: 15px 0 6px;
    color: $white;

    a {
      color: $body-color;
      font-size: 13px;

      &:hover {
        opacity: .8;
      }
    }

    .footer-payment {
      height: 30px;
      text-align: right;

      img {
        margin-right: 10px;
      }
    }
  }
}
#footer_before {
  background-color: $white;
  .footer_before-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    .footer_before-descr {
      text-align: center;
      p {
        color: #232615;
        font-size: 14px;
        margin: 0;
      }
    }
  }
}
#footer_contact{
  .footer_contact_wrapper{
    display: flex;
    &>div{
      display: flex;
      align-items: center;
      .footer_image{
        margin-right: 25px;
        width: 43px;
        height: 43px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: auto;
          max-width: 100%;
          height: auto;
        }
      }

      .footer_contact_text{
        p{
          font-size: 16px;
          margin-bottom: 4px;
          color: $brand-primary;
        }
        span, a {
          color: $brand-primary;
          transition: all .3s ease;
          font-weight: 400;
        }
        @media screen and (any-hover: hover){
          a:hover {
            opacity: .8;
          }
        }
      }
    }
  }
}

/*** Responsive part ***/
@media screen and (min-width: 1881px) {
  #footer_contact {
    .footer_contact_wrapper {
      & > div {
        .footer_contact_text {
          span, a {
            font-size: 14px;
          }
        }
      }
    }
  }
  #footer_contact {
    .footer_contact_wrapper {
      & > div {
        &:not(:last-child) {
          margin-right: 179px;
        }
      }
    }
  }
  #footer_before {
    padding: 60px 0;
    .footer_before {
      &-logo {
        margin-bottom: 32px;
      }
      &-descr {
        margin-bottom: 60px;
        padding: 0 483px;
      }
    }
  }
}
@media screen and (max-width: 1880px){
  #footer_contact {
    .footer_contact_wrapper {
      & > div {
        &:not(:last-child) {
          margin-right: calc(-11px + (179 - 20) * ((100vw - 576px) / (1880 - 576)));
        }
      }
    }
  }
  #footer_before {
    padding: calc(15px + (60 - 15) * ((100vw - 320px) / (1880 - 320))) 0;
    .footer_before {
      &-logo {
        margin-bottom: calc(10px + (32 - 10) * ((100vw - 320px) / (1880 - 320)));
      }
      &-descr {
        margin-bottom: calc(15px + (60 - 15) * ((100vw - 320px) / (1880 - 320)));
        padding: 0 calc(0px + (483 - 0) * ((100vw - 320px) / (1880 - 320)));
      }
    }
  }
}
@media screen and (min-width: 992px) and (max-width: 1880px){
  #footer_contact {
    .footer_contact_wrapper {
      & > div {
        .footer_contact_text {
          span, a {
            font-size: calc(12px + (14 - 12) * ((100vw - 992px) / (1880 - 992)));
          }
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .app-store img,
  .google-play img {
    margin-bottom: 20px;
  }

  #footer .block_newsletter form input[type=email] {
    width: 196px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #footer .block_newsletter form input[type=email] {
    width: 131px;
    padding: 5px;
    font-size: 11px;
  }
  #footer {
    .block_newsletter {
      form {
        .input-wrapper {
          width: 180px;
        }

        .input-wrapper {
          display: flex;
          flex-wrap: wrap;
        }

      }
    }
  }
}

@media (max-width: 991px) {
  .footer-container {
    #block_myaccount_infos .myaccount-title a {
      font-size: 14px;
    }

    padding: 30px 0 3px;

    .h3 {
      font-size: 14px;
    }

    li {
      margin-bottom: 10px;

      span {
        margin: 0;
        font-size: 13px;
        padding-left: 10px;
      }
    }



    .links {
      ul {
        >li a {
          margin: 0;
        }
      }
    }

    .block-social {
      ul {
        li {
          padding: 0 0 15px;
          margin: 0 5px 0 0;
        }
      }
    }

    .footer-container-bottom {
      .footer-payment {
        margin: 0;
      }
    }
  }
}



@media (max-width: 767px) {
  #footer {
    .footer-container {
      margin-top: 0;
      padding: 6px 5px;

      .wrapper {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      .block_newsletter {


        form {
          margin-left: 10px;
        }
        .collapse {
          display: none;

          &.show {
            display: block;
          }
        }

        form input[type=email] {
          margin-left: 10px;
          width: 100%;
          height: fit-content;
        }

        form .input-wrapper {
          display: flex;
          justify-content: center;
          width: 100%;
        }

        .btn-primary {
          margin-right: 10px;
          padding: 11px 14px;
        }

        #footer_newsletter {
          padding: 0 10px;

          p.alert {
            margin: 10px auto;
            max-width: 81%!important;
          }

          >p {
            padding-left: 10px;
          }
        }
      }

      #block_myaccount_infos .myaccount-title a {
        font-size: 15px;
      }


      .links,
      .block_newsletter {
        padding-top: 0;

        .row {
          margin: 0;
        }

        .h3 {
          font-size: 15px;
        }

        ul {
          margin-bottom: 0;
          padding-left: 20px;

          >li {
            font-weight: normal;

            span {
              padding-left: 30px;

              &:before {
                left: 0;
                top: 5px;
              }
            }

            a {
              padding-left: 12px;

              &:hover {
                &:after {
                  width: calc(100% - 12px);
                }
              }

              &:first-child {
                padding-left: 0;
              }
            }
          }
        }

        .title {
          padding: 9px 0px 9px 20px;
          position: relative;

          .h3 {
            transition: all 0.2s linear;

            &:hover {
              color: $brand-primary;
              cursor: pointer;
            }
          }

          .collapse-icons .remove {
            display: none;
          }
        }

        .title[aria-expanded="true"] .collapse-icons {
          .add {
            display: none;
          }

          .remove {
            display: block;
          }
        }

        .navbar-toggler {
          display: inline-block;
          padding: 0;
          position: absolute;
          top: 10px;
          right: 0;

          i {
            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      .links {
        margin-bottom: 0;
        ul {
          li {
            &:first-child {
              padding-top: 10px;
            }
          }
        }

        .collapse:not(.show) {
          display: none;
        }
      }

      .app-stores {
        padding-left: 10px;
      }
    }

    .footer-container-bottom {
      text-align: center;
      padding: 15px 0 6px;

      .footer-payment {
        text-align: center;
        margin: 10px 0 0 0;
      }
    }
  }
}

@media (max-width: 575px) {
  #footer_contact {
    .footer_contact_wrapper {
      flex-direction: column;
      & > div {
        &:not(:last-child) {
          margin-right: 0;
          margin-bottom: calc(10px + (25 - 10) * ((100vw - 320px) / (575 - 320)));
        }
        .footer_image {
          margin-right: calc(5px + (25 - 5) * ((100vw - 320px) / (575 - 320)));
        }
        .footer_contact_text {
          span, a {
            font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (575 - 320)));
          }
        }
      }
    }
  }
  .footer-container {
    .product-container {
      left: 0;

      &.active-list {
        bottom: 0;
      }

      .product-list {
        .product-item {
          margin-bottom: 0;

          .product-item-inner {
            width: 320px;
          }
        }
      }
    }

    #footer_newsletter {
      p.alert {
        margin: 10px auto;
        max-width: 96%!important;
      }
      form {
        //display: flex;
        //flex-direction: row-reverse;

        .input-wrapper {
          width: 100%;
          padding-bottom: 0;
        }
      }
    }
  }

  .block-social ul li,
  .social-sharing ul li {
    margin: 0 10px 10px 0;
  }
}