#stores {
  .card-title {
    margin-bottom: 20px;
  }

  .page-header {
    h1 {

      padding: 20px 0 50px;
    }
  }

  .page-stores {
    width: 100%;
    margin: 0 auto;

    .store-picture {
      img {
        width: 100%;
        height: auto;
      }
    }

    .store-item-container {
      padding-bottom: 50px;

      ul {
        margin-bottom: 0;
        font-size: 13px;
        padding: 0;
      }

      .divide-left {
        .bon-table-second {
          border: none;
        }

        .bon-table-second {
          td {
            width: 50%;
            padding: 14px 9px 14px 0;
          }

          td:first-child {
            padding-left: 40px;
          }

          td:last-child {
            padding-right: 40px;
          }

          td,
          li {
            font-size: 14px;
          }

          tr:nth-child(odd) {
            background-color: #fafafa;
            border: none;
          }

          tr:nth-child(even) {
            background-color: rgba(250, 250, 250, 0.2);
            border: none;
          }
        }
      }

      .store-description {
        font-size: $base-font-size;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #F0F0F0;
        border-top: 1px solid #F0F0F0;

        .store-contact-wrapper {
          display: flex;
          flex-direction: column;

        }

        address {
          font-size: 13px;
          line-height: 1.7rem;
          margin-bottom: 0;
        }

        .store-block-addres {
          border-bottom: 1px solid #F0F0F0;
        }

        .store-block-contact,
        .store-block-addres {
          padding: 26px 0;
        }
      }
    }

    .store-item-footer {



      i.material-icons {
        margin-right: $small-space;
        color: $gray;
        font-size: $base-font-size;
      }

      li {
        margin-bottom: $small-space;
      }
    }
  }
}

/*** Responsive part ***/
@media (min-width: 991px) and (max-width: 1270px) {
  #stores {
    .page-stores {
      .store-picture img {
        width: auto;
        height: 264px;
      }

      .store-item-container {
        .divide-left {
          .bon-table-second td {
            font-size: 13px;
            padding: 9px 0;
          }

          .bon-table-second td:first-child {
            padding-left: 10px;
          }

          .bon-table-second td:last-child {
            padding-right: 10px;
          }
        }

        .store-description {
          border-bottom: none;
          border-top: none;
          align-items: end;
          padding-left: 20px;
        }

        .store-description .store-block-contact {
          padding: 9px 0;
        }

        .store-description .store-block-addres {
          padding: 9px 0;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  #stores {
    .page-stores {
      .store-item-container {
        .store-description {
          border: none;

          .store-contact-wrapper {
            width: 100%;
            flex-direction: row;
            justify-content: space-around;

            .store-block-addres {
              border: none;
            }
          }
        }
      }
    }

  }
}

@media (max-width: 767px) {
  #stores {
    .store-picture {
      padding-right: 10px;
    }

    .divide-left {
      padding-left: 10px;
    }

    .page-stores {
      width: 100%;

      .store-item-container {
        padding: 1rem 0;
      }
    }
  }
}

@media (max-width: 575px) {
  #stores {
    .page-header h1 {

      padding: 0 0 20px 0;
    }

    .card-title {
      margin-top: 10px;
      margin-bottom: 0;
      text-align: center;
    }

    .page-stores {
      .store-item-container {
        display: block;

        .store-description {
          padding-bottom: 20px;
        }

        .divide-left .bon-table-second td {
          width: 40%;
        }

        .divide-left .bon-table-second td:first-child {
          padding-left: 20px;
        }

        .divide-left .bon-table-second td:last-child {
          padding-right: 20px;
        }

        .store-description {
          align-items: flex-start;

          a {
            margin-bottom: 0.5rem;
          }

          .store-contact-wrapper {
            flex-direction: row;

            .store-block-addres {
              border: none;
              padding-bottom: 0;

              h4 {
                font-size: 16px;
              }
            }
          }
        }
      }

      .store-item-footer {
        display: block;

        li {
          margin-bottom: $small-space;
        }

        .card-block {
          padding: 0.75rem 0.75rem 0;
        }
      }
    }
  }
}

@media (max-width: 350px) {
  #stores .page-stores .store-item-container ul {
    font-size: 12px;
  }

  #stores .page-stores .store-item-container .store-description address {
    font-size: 12px;
  }
}