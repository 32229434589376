/*** SOME GENERIC STYLES ***/
@mixin customer-area-base-box {
  @include box-shadow;
  background: white;
  padding: 1rem;
}

@mixin customer-area-box {
  @include customer-area-base-box;
  font-size: $font-size-sm;
  color: $gray;
}

.table-labeled {
  th {
    vertical-align: middle;
  }

  td {
    vertical-align: middle;
  }

  .label {
    font-weight: 400;
    border-radius: 3px;
    font-size: inherit;
    padding: 0.25rem 0.375rem;
    margin: 0.125rem;
    color: white;
    white-space: nowrap;
  }
}

#order-slip {
  .container {
    padding: 0;
  }
}
.table, a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: $brand-primary;
}
.page-order {
  .table {
    margin-bottom: 0;
  }

  table {
    th {
      padding: 0.5rem;
    }

    td {
      padding: 0.5rem;
    }

    thead {
      th {
        text-align: center;
      }
    }
  }
}

#authentication {
  .tooltip.tooltip-bottom {
    padding: 0;
    margin: 0;
  }

  .custom-checkbox {
    display: flex;

    span {
      flex: 0 0 0.9375rem;
    }

    em {
      padding-top: 10px;
      display: block;
      font-size: 12px;
    }
  }
}

#identity,
#authentication {
  .radio-inline {
    padding: 0;

    &:first-child {
      margin-right: 10px;
    }

    .custom-radio {
      margin-right: 0;
      border: 1px solid rgba(0, 0, 0, .125);
    }
  }
}

#identity {
  .custom-checkbox {
    display: inline-block;
  }

  .custom-checkbox input[type=checkbox]+span .checkbox-checked {
    top: 1px;
  }

}

.form-footer {
  text-align: center;
}

/*** Most of the customer accpunt pages ***/
.page-customer-account {
  #content {
    .order-actions {
      a {
        padding: 0 0.125rem;
      }
    }

    .forgot-password {
      text-align: center;
      font-size: $font-size-sm;
      margin-top: 1rem;
      padding-bottom: 0.9375rem;
    }

    .no-account {
      text-align: center;
      font-size: $base-font-size;
    }
  }
}

/*** Login page ***/
.page-authentication {
  #content {
    max-width: 640px;
    margin: 0 auto;
    margin-bottom: 50px;
  }

  .custom-checkbox input[type=checkbox]+span {
    border: 1px solid rgba(0, 0, 0, .125);
  }

  .custom-checkbox input[type=checkbox]+span .checkbox-checked {
    left: 0;
    top: 2px;
  }
}

/*** Addresses page ***/
.page-addresses {
  .col-lg-4.col-md-6.col-sm-6 {
    padding: 0;
  }

  .address {
    background: white;
    margin-bottom: 1.875rem;
    font-size: $font-size-sm;

    .address-body {
      padding: 15px 0 15px 0;

      h4 {
        font-size: $base-font-size;
        font-weight: 400;
      }
    }

    .address-footer {
      border-top: 1px solid $gray-line;
      padding: 8px;

      a {
        margin-right: 0.5rem;

        &:hover {
          color: $brand-primary;
        }

        i {
          font-size: $base-font-size;
        }

        span {
          font-size: $font-size-lg;
          vertical-align: middle;

          &:hover {
            color: $brand-primary;
          }
        }
      }
    }
  }

  .addresses-footer {
    a {
      i {
        font-size: $base-font-size;
      }

      span {
        font-size: $base-font-size;
        vertical-align: middle;
        margin-top: $small-space;
      }
    }
  }
}

/*** Order details page ***/
.page-order-detail {
  font-size: 0.875rem;

  .box {
    border: 1px solid $gray-line;
    padding: 20px;
    margin-bottom: 1rem;
  }

  h3 {
    font-size: $base-font-size;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  #order-infos {
    ul {
      margin: 0;
    }
  }

  #order-history {

    .history-lines {
      .history-line {
        padding: 0.5rem 0;
        border-bottom: 1px solid $gray-lighter;

        &:last-child {
          border-bottom: 0;
        }

        .label {
          display: inline-block;
          margin: 0.25rem 0;
          padding: 0.25rem 0.375rem;
          color: white;
          border-radius: 3px;
        }
      }
    }
  }

  .addresses {
    margin: 0 -0.9375rem;

    h4 {
      font-size: $base-font-size;
      font-weight: 400;
    }
  }

  #order-products {
    &.return {
      margin-bottom: 1rem;

      th {
        &.head-checkbox {
          width: 30px;
        }
      }

      td {
        padding: 1.375rem 0.75rem;

        &.qty {
          min-width: 125px;

          .current {
            width: 30%;
            float: left;
            text-align: right;
            padding-right: 0.5rem;
          }

          .select {
            width: 70%;
            float: left;
            margin: -0.625rem 0;
            padding-left: 0.25rem;

            select {
              text-align: center;
            }
          }
        }
      }
    }
  }

  .order-items {
    padding: 0 !important;

    .order-item {
      padding: 1rem 1rem 0;
      border-bottom: 1px solid $gray-lighter;

      .checkbox {
        width: 30px;
        float: left;
        padding: 0 0.9375rem;
      }

      .content {
        width: calc(100% - 30px);
        float: left;
        padding: 0 0.9375rem;
      }

      .desc {
        .name {
          font-weight: bold;
        }

        margin-bottom: 1rem;
      }

      .qty {
        margin-bottom: 1rem;

        .q {
          margin-bottom: 0.25rem;
        }

        .s {
          margin-bottom: 0.25rem;
        }
      }
    }
  }

  .messages {
    .message {
      margin-top: 0.5rem;
      border-bottom: 1px solid $gray-lighter;

      &:last-child {
        border-bottom: 0;
      }

      >div {
        margin-bottom: 0.5rem;
      }
    }
  }

  .customization {
    margin-top: 0.75rem;
  }
}

/*** Order return page ***/
#order-return-infos {
  .thead-default th {
    color: $gray-darker;
  }

  .customization {
    margin-top: 0.75rem;
  }
}

/*** My account landing page ***/
#my-account {
  .account-bon-logout {
    &:hover {
      background-color: $brand-hover;
    }
    &:before {
      content: none;
    }
  }
}
.page-my-account {
  #content {
    .links {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 100%;
      a {
        text-align: center;
        display: inline-block;
        font-size: 18px;
        font-weight: 400;
        text-transform: uppercase;
        color: $gray-black;
        transition: all .2s linear;
        padding: 0 0.9375rem;
        margin-bottom: 1.875rem;

        span.link-item {
          display: block;
          height: 100%;
          border: 1px solid #ebebeb;
          padding: 20px;
          transition: all .2s linear;

          &:hover {
            box-shadow: 0 2px 8px rgba(0, 0, 0, .16);
          }
        }

        i {
          display: block;
          font-size: 2.6rem;
          width: 100%;
          padding-bottom: 3.4rem;
          transition: all .2s linear;
        }

        &:hover {
          i {
            color: $brand-primary;
          }
        }
      }
    }
  }
}

/*** History page ***/
#history {
  .table-bordered, .table-bordered td, .table-bordered th {
    width: 100%;
  }
  th, td {
    color: #232615;
  }

  .orders {
    margin: 0 -1rem;

    .order {
      a {
        h3 {
          color: $gray;
        }
      }

      padding: 0.75rem 1rem;
      border-bottom: 1px solid $gray-lighter;

      .label {
        display: inline-block;
        margin: 0.25rem 0;
        padding: 0.25rem 0.375rem;
        color: white;
        border-radius: 3px;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

/*** FOOTER ***/
.page-footer {
  padding: 15px 0 20px 0;

  .account-link {
    margin-right: 1rem;

    i {
      font-size: $base-font-size;
    }

    span {
      font-size: $font-size-sm;
      vertical-align: middle;
    }
  }
}

.login-form {
  margin-top: 15px;
  padding-bottom: 20px;

  #login-form {
    .form-footer {
      display: flex;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 575px) {
  .page-authentication #content {
    margin-bottom: 0;
  }
}