#js-product-list-top {
  .h1 {
    span {
      font-weight: normal;
      font-size: 14px;
      text-transform: none;
    }
  }
}

#products {
  color: $gray;

  .products-select {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .up {
    margin-bottom: 1rem;

    .btn-secondary {
      color: $gray;
      text-transform: inherit;

      .material-icons {
        margin-right: 0;
      }
    }
  }

  .sort-to-one {
    .thumbnail-container {
      padding-bottom: 0;

      &:hover {
        margin: 0;
        border: none;
        box-shadow: none;
        cursor: pointer;

        .thumbnail-container-bottom {
          border: none;
          box-shadow: none;
          padding: 0;
        }

        .productcountdown {
          display: block;
        }

        .product-flags {
          margin-top: 10px;
        }

      }


    }
  }
}

#category #wrapper>.container {
  margin-top: 37px;
}

#subcategories {
  margin: 0;
  margin-top: 25px;
}

.block-category {
  &.without_image {
    min-height: 13.75rem;
    background-color: $brand-header;
  }

  .category-image {
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

#best-sales,
#prices-drop,
#new-products {
  .products-selection h1 {
    margin-left: 10px;
  }
}

.products-selection {
  .change-grid-button {
    float: left;

    .grid-to-1,
    .grid-to-2,
    .grid-to-3,
    .grid-to-4 {
      cursor: pointer;
      width: 30px;
      background-color: transparent;
      position: relative;
      border: none;
      height: 30px;

      &:after {
        color: #232615;
        font-family: "Material Icons";
        font-size: 30px;
        position: absolute;
        top: 5px;
        left: 0;
      }

      &:focus {
        outline: none;
      }
    }

    .grid-to-2 {
      &:after {
        content: '\e8fe';
      }
    }

    .grid-to-3 {
      &:after {
        content: '\e5c3';
      }
    }

    .grid-to-4 {
      &:after {
        content: '\e5c3';
      }
    }

    .grid-to-1 {
      &:after {
        content: '\e5c3';
      }
    }
  }

  .sort-by-row {
    display: flex;
    align-items: center;
    float: right;
    margin-right: 18px;
  }

  .sort-by {
    white-space: nowrap;
    word-break: break-word;
    margin-left: 0.9375rem;
    text-align: right;
    margin-right: 3px;
    color: $gray-black;
    font-size: 13px;
  }
}

.products-sort-order {
  color: $gray;
  position: relative;

  .select-title {
    color: $gray-black;
    cursor: pointer;
    margin: 1px 0 0 5px;
    font-size: 13px;

    &:after {
      content: "\E5CF";
      font-family: Material Icons;
      font-size: 20px;
      color: $brand-primary;
      position: absolute;
      top: 2px;
      right: -22px;
    }
  }

  .select-list {
    display: block;
    padding: 5px 0;
    color: #232615;
    font-size: 13px;

    &:hover {
      color: $brand-primary;
      text-decoration: none;
    }
  }

  .dropdown-menu {
    right: -20px;
    left: auto;
    border: 1px solid #ebebeb;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .16);
    background: #fff;
    border-radius: 0;
    color: $gray-black;
    margin: 10px 0 0 0;
    padding: 15px 20px;
  }
}

.facet-dropdown {
  color: $gray;
  padding-left: 0;
  padding-right: 0;
  background: $gray-light;
  border: 3px solid transparent;
  box-sizing: border-box;
  box-shadow: 1px 1px 1px 1px $gray-light;

  &.open {
    border: 0;

    >.select-title {
      border: 3px solid $brand-primary;
      background: $gray-lighter;
    }
  }

  .select-title {
    display: inline-flex;
    align-items: center;
    margin-left: 0;
    width: 100%;
    color: $gray-darker;
    background: $gray-light;
    padding: $small-space $small-space $small-space $medium-space;
    cursor: pointer;

    >i {
      margin-left: auto;
    }
  }

  .select-list {
    display: block;
    color: $gray-darker;
    background: $gray-lighter;
    padding: $small-space $medium-space;

    &:hover {
      background: $brand-primary;
      color: white;
      text-decoration: none;
    }
  }

  .dropdown-menu {
    padding: 0;
    margin-top: 3px;
    left: auto;
    width: 100%;
    background: $gray-lighter;
    border: none;
    box-shadow: 1px 1px 1px 1px $gray-light;
  }
}

#search_filters,
#search_filters_brands,
#search_filters_suppliers {
  margin-bottom: 1.563rem;
  background: white;
  padding: 16px 0 0 0;

  .h6 {
    color: $gray-black;
    font-weight: normal;

    font-size: 16px;
    margin-bottom: 20px;
    padding-bottom: 12px;
    border-bottom: 1px solid $gray-line;
  }

  .facet {

    .color,
    .custom-checkbox input[type="checkbox"]+span.color {
      vertical-align: -3px;
    }

    &:first-child {
      padding-top: 0;
    }

    .collapse {
      display: block;
    }

    padding-top: 20px;

    .facet-title {
      color: $gray-black;
    }

    .facet-label {
      margin-bottom: 0;

      a {
        margin-top: 0.4375rem;
        color: $gray-black;
        display: inline-block;
        font-size: 13px;
        transition: all 0.2s linear;

        >.magnitude {
          color: #b2b2b2;
        }

        &:hover,
        &:focus {
          color: $brand-primary;
          text-decoration: none;
        }
      }
    }
  }
}

body #search_filters .facet .facet-title {
  width: auto;
}

body #search_filters>p.text-uppercase.h6 {
  display: none;
}

#search_filters .facet .color-boxes {
  float: left;
  padding-bottom: 10px;

  .facet-label a.search-link {
    display: none;

    .color {
      margin-left: 0px;
    }
  }

  .facet-label {
    .custom-checkbox {
      top: 0;
    }
  }

  .color,
  .custom-checkbox input[type=checkbox]+span.color {
    width: 32px;
    height: 32px;
    margin: 0 9px 0 0;
  }
}

#search_filters_brands,
#search_filters_suppliers {
  .facet {
    padding-top: 0;

    .facet-label {
      margin-bottom: 0.3125rem;
    }
  }
}

.pagination {
  width: 100%;
  display: flex;
  align-items: baseline;
  margin-bottom: 30px;
  border-top: 1px solid #f1f1f0;

  >div:first-child {
    font-size: 13px;
    padding: 0;
  }

  .page-list {
    padding: 0.375rem;
    margin-bottom: 0;
    font-size: 13px;
    padding-top: 15px;

    li {
      display: inline;

    }
  }

  a {
    color: $gray;
    font-weight: 400;

    &:not(.previous):not(.next) {
      display: inline-block;
      width: 20px;
      text-align: center;
      border: 1px solid #f4f4f4;

      &:hover,
      &:focus {
        text-decoration: none;
        color: $brand-primary;
      }
    }
  }

  .previous {
    float: revert;
    margin-right: 10px;
  }

  .next {
    float: right;
    margin-left: 10px;
  }

  .next,
  .previous {
    i {
      vertical-align: -5px;
      font-size: 18px;
    }

    &:hover {
      color: $brand-primary;
    }
  }

  .disabled {
    color: $gray;
  }

  .current a {
    color: $brand-primary;
    text-decoration: none;
  }
}

.active_filters {
  background: #f5f5f5;
  padding: $small-space $large-space 0;
  margin-bottom: $medium-space;

  .active-filter-title {
    display: inline;
    margin-right: $small-space;
    font-weight: 400;
  }

  ul {
    display: inline;
  }

  .filter-block {
    @include box-shadow;
    color: $gray-darker;
    margin-right: $small-space;
    margin-bottom: $small-space;
    background: white;
    padding: 0.625rem;
    display: inline-block;
    font-size: $font-size-xs;
    transition: all .3s ease;

    &:hover {
      cursor: pointer;
      box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
    }

    .close {
      color: $gray-darker;
      font-size: $font-size-lg;
      opacity: 1;
      margin-top: 0.1875rem;
      margin-left: 0.3125rem;
      transition: all 0.3s ease;

      &:hover {
        color: #232615;
      }
    }
  }
}

.block-categories {
  padding: 0;

  ul {
    li {
      .h6 {
        font-weight: 400;

        font-size: 20px;
        padding-bottom: 12px;
        border-bottom: 1px solid $gray-line;
        margin-bottom: 2px;
        color: $gray-black;
        display: block;
      }
    }
  }

  .category-sub-menu {
    .category-sub-link {
      font-size: $font-size-sm;
    }

    li {
      position: relative;

      &[data-depth="1"] {
        padding: 10px 0 0 10px;
      }

      >a {
        &:hover {
          color: $brand-primary;
        }
      }
    }

    li[data-depth="0"] {
      &:first-child {
        >a {
          border-top: 1px solid $gray-line;
        }
      }
    }

    li[data-depth="0"] {

      >a {
        font-weight: 400;
        border-bottom: 1px solid $gray-line;
        width: 100%;
        display: inline-block;
        padding: 10px 0 10px;
        color: $gray-black;

        &:hover {
          color: $brand-primary;
        }
      }

      li:not([data-depth="0"]):not([data-depth="1"]) {
        padding-left: 0.3125rem;

        &::before {
          content: "-";
          margin-right: 0.3125rem;
        }
      }


    }
  }

  a {
    color: $gray-darker;
  }

  .collapse-icons {
    position: absolute;
    right: -18px;
    top: 9px;
    padding: 0;
    cursor: pointer;

    i {
      font-size: 20px;
    }

    &[aria-expanded="true"] {
      .add {
        display: none;
      }

      .remove {
        display: block;
      }
    }

    .add,
    .remove {
      &:hover {
        color: $brand-primary;
        cursor: pointer;
      }
    }

    .remove {
      display: none;
    }
  }

  .arrows {

    .arrow-right,
    .arrow-down {
      font-size: $font-size-sm;
      cursor: pointer;
      margin-left: 2px;

      &:hover {
        color: $brand-primary;
      }
    }

    .arrow-down {
      display: none;
    }

    &[aria-expanded="true"] {
      .arrow-right {
        display: none;
      }

      .arrow-down {
        display: inline-block;
      }
    }
  }
}

.facets-title {
  color: $gray-darker;
}

.products-selection {
  .filter-button {
    .btn-secondary {
      padding: 0.75rem 0.5rem 0.6875rem;
    }
  }
}

.advertising-block {
  margin-bottom: 1.563rem;

  img {
    width: 100%;
  }
}


#js-product-list-header.fadeIn {
  opacity: 0;
  animation-fill-mode: forwards;
  animation-delay: .3s;
}

.js-product-list-header {
  padding-left: 26px;
}

.category-top-menu {
  li[data-depth="0"] {
    &:first-child {
      margin-top: 20px;
    }

    &:last-child {
      margin-bottom: 40px;
    }
  }
}

/*** Responsive part ***/
@media screen and (min-width: 1881px) {
  .block-category {
    #category-description {
      h2 {
        font-size: 30px;
        line-height: 64px;
        margin-bottom: 13px;
      }

      h3 {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 31px;
      }

      .category-subcategory {
        a {
          font-size: 18px;
          line-height: 27px;
          padding: 7px 29px;

          &:not(:last-child) {
            margin-right: 20px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1880px) {
  .block-category {
    #category-description {
      h2 {
        font-size: calc(20px + (30 - 20) * ((100vw - 992px) / (1880 - 992)));
        line-height: calc(20px + (64 - 20) * ((100vw - 992px) / (1880 - 992)));
        margin-bottom: calc(8px + (13 - 8) * ((100vw - 992px) / (1880 - 992)));
      }

      h3 {
        font-size: calc(14px + (20 - 14) * ((100vw - 992px) / (1880 - 992)));
        line-height: calc(14px + (24 - 14) * ((100vw - 992px) / (1880 - 992)));
        margin-bottom: calc(12px + (31 - 12) * ((100vw - 992px) / (1880 - 992)));
      }

      .category-subcategory {
        a {
          font-size: calc(13px + (18 - 13) * ((100vw - 992px) / (1880 - 992)));
          line-height: calc(13px + (27 - 13) * ((100vw - 992px) / (1880 - 992)));
          padding: calc(3px + (7 - 3) * ((100vw - 992px) / (1880 - 992))) calc(10px + (29 - 10) * ((100vw - 992px) / (1880 - 992)));

          &:not(:last-child) {
            margin-right: calc(10px + (20 - 10) * ((100vw - 992px) / (1880 - 992)));
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  #manufacturer #products {
    padding-left: 0;
  }

  #products {
    padding-left: 10px;
    padding-top: 15px;
  }
}

@media screen and (max-width: 991px) {
  .block-category {
    min-height: 13.75rem;

    .category-image {
      display: none;
    }

    #category-description {
      width: 100%;

      h2 {
        font-size: calc(20px + (30 - 20) * ((100vw - 320px) / (991 - 320)));
        line-height: calc(20px + (64 - 20) * ((100vw - 320px) / (991 - 320)));
        margin-bottom: calc(8px + (13 - 8) * ((100vw - 320px) / (991 - 320)));
      }

      h3 {
        font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (991 - 320)));
        line-height: calc(14px + (24 - 14) * ((100vw - 320px) / (991 - 320)));
        margin-bottom: calc(12px + (31 - 12) * ((100vw - 320px) / (991 - 320)));
      }

      .category-subcategory {
        a {
          font-size: calc(13px + (18 - 13) * ((100vw - 320px) / (991 - 320)));
          line-height: calc(13px + (27 - 13) * ((100vw - 320px) / (991 - 320)));
          padding: calc(3px + (7 - 3) * ((100vw - 320px) / (991 - 320))) calc(10px + (29 - 10) * ((100vw - 320px) / (991 - 320)));

          &:not(:last-child) {
            margin-right: calc(10px + (20 - 10) * ((100vw - 320px) / (991 - 320)));
          }
        }
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .products-sort-order .select-title:after {
    top: 0;
    right: -15px;
  }
}

@media screen and (min-width: 768px) {
  #search_filters {
    .facet-label {
      text-align: left;
      margin-bottom: 5px;
    }
  }

  #category {
    #products {
      padding: 15px 0 0 30px;
    }
  }
}

@media screen and (max-width: 767px) {
  #category {
    .block-category.card.card-body {
      background-position: left !important;

      #category-description h2 {
        font-size: 25px;
      }
    }

    #left-column {
      width: 100%;

      #search_filter_controls {
        text-align: center;

        .js-search-filters-clear-all {
          padding: 7px 10px;
          background-color: #cacaca;
          margin: 0;
          color: #232615;

          &:before {
            background: #525252;
          }

          &:hover,
          &:active,
          &:focus {
            color: #fff;
          }
        }

        button {
          background-color: $brand-primary;
          transition: all .2s linear;
          padding: 5px 10px;

          &:hover {
            background: $brand-primary;
          }
        }

        margin: 1rem 0;
      }

      #search_filters {
        margin-bottom: 0;
        box-shadow: none;
        padding: 0;
        border-top: 1px solid $gray-lighter;

        .facet {
          .title {
            .collapse-icons .remove {
              display: none;
            }
          }

          .title[aria-expanded="true"] {
            .collapse-icons {
              .add {
                display: none;
              }

              .remove {
                display: block;
              }
            }
          }

          padding-top: 0;
          border-bottom: 1px solid $gray-lighter;

          .facet-title {
            color: $gray-black;

            transition: all 0.2s linear;

            &:hover {
              color: $brand-primary;
              cursor: pointer;
            }
          }

          .h6 {
            margin-bottom: 0;
            padding: 0.625rem;
            display: inline-block;
            border: none;
          }

          .navbar-toggler {
            float: right;
            display: inline-block;
            padding: 0.625rem 0.625rem 0 0;

            &:hover {
              cursor: pointer;
            }
          }



          .facet-label {
            a {
              margin-top: 0;
            }
          }

          ul {
            margin-bottom: 0;

            li {
              border-top: 1px solid $gray-lighter;
              padding: 0.625rem;
            }
          }
        }
      }
    }

    #search_filter_toggler {
      width: 100%;
      color: #fff;
      background-color: $brand-primary;
      transition: all .2s linear;
      box-shadow: 4px 4px 4px rgba(0, 0, 0, .14);

      &:hover {
        background: $brand-hover;
        box-shadow: none;
      }
    }
  }

  .products-sort-order {
    .dropdown-menu {
      left: 0;
    }

    .select-title {
      margin-left: 0;
    }

  }

  .products-selection {
    display: flex;
    flex-direction: column-reverse;

    .sort-by-row {
      float: none;
      width: 100%;
      margin: 0;
      justify-content: space-between;
    }

    h1 {
      padding-top: 0;
      text-align: center;
      margin-bottom: 1rem;
    }

    .showing {
      padding: 16px 0;
      text-align: center;
      font-size: 14px;
    }
  }

  #bonban {
    display: none;
  }

  .category-cover {
    display: none;
  }

  .products-selection {
    .filter-button {
      .btn-secondary {
        padding: 5px 10px;
      }
    }
  }



  .card-block {
    .sort-by-row {
      padding: 0;
    }
  }

  .card {
    box-shadow: none;
    background-color: inherit;
    border: none;
  }
}

@media only screen and (max-width: 575px) {
  .products-selection {
    .filter-button {
      padding: 0;
    }
  }

  #category {
    #left-column {
      #search_filters_wrapper {
        margin-left: -15px;
        margin-right: -15px;

        .btn-secondary {
          i {
            vertical-align: -6px;
          }
        }
      }
    }
  }
}