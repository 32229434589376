.label-menu-sale,
.label-menu-hot,
.label-menu-new {
  display: block;
  position: absolute;
  padding: 0 8px 0 6px;
  left: -10px;
  top: -1px;
  color: white;
  background: #fd6b6b;

  font-size: 12px;
  line-height: 16px;
  font-weight: 300;

  &:after {
    top: 0px;
    content: "";
    display: inline-block;
    position: absolute;
    border: 8px solid #fd6b6b;
    border-right-color: transparent;
  }
}

.label-menu-hot {
  background: $brand-warning;

  &:after {
    border: 8px solid $brand-warning;
    border-right-color: transparent;
  }
}
.label-menu-new {
  background: #13a2fe;

  &:after {
    border: 8px solid #13a2fe;
    border-right-color: transparent;
  }
}
#index {
  //#header.notice-margin {
  //  top: 34px;
  //}
  //#header {
  //  background: transparent;
  //  position: fixed;
  //  width: 100%;
  //  top: 0;
  //}
}
#header {
  z-index: 1040;
  .bon-nav-bar.menu {
    height: 100%;
  }
  #top-menu .material-icons {
    line-height: 0.8;
  }
  #menu-icon {
    padding-left: 10px;
    .bonicon-lines7 {
      font-size: 22px;
      color: #fff;
    }
  }
  .top-menu {
    margin-bottom: 0;
    z-index: 25;

    &[data-depth="0"] {
      display: flex;
      flex-wrap: wrap;
    }

    [data-depth="1"] {
      display: flex;
      flex-wrap: wrap;
      // padding: 12px 15px 15px 0;
      overflow: hidden;
      flex: 1 0 55%;
    }

    &[data-depth="2"] {
      padding-top: 7px;
      @media (min-width: 1881px) {
        padding-top: 13.5px;
      }
      @media (min-width: 1281px) and (max-width: 1880px) {
        padding-top: calc(4px + (13.5 - 4) * ((100vw - 1281px) / (1880 - 1281)));
      }
    }

    a[data-depth="2"] {
      color: #777;

      &:hover {
        color: $brand-primary;
      }
    }
  }
}

.top-menu {
  .material-icons.current {
    &:after {
      content: "keyboard_arrow_down";
    }
  }
  li.category {
    @media (max-width: 991px) {
      position: relative;
      .navbar-toggler {
        position: absolute;
        right: 0;
        top: 8px;
        z-index: 6;
        padding: 5px 15px;
        cursor: pointer;
      }
    }
  }
  a.dropdown-submenu {
    color: $gray-black;

    font-weight: 400;
    font-size: 15px;
    margin-bottom: 12px;

    &:hover {
      color: $brand-primary;
    }
  }

  a[data-depth="0"] {
    color: $gray-black;

    &:hover {
      color: white!important;
      #header & {
        //color: #f9d6cf;
        text-decoration: none;
        .material-icons {
          //color: #f9d6cf;
        }
      }
    }
  }

  .collapse {
    display: inherit;
  }

  .sub-menu {
    border: none;
    width: 100%;
    // margin-left: calc(250px / 2);
    // width: calc(100% - 250px);
    z-index: 18;

    ul[data-depth="1"]>li {
      float: left;
    }

    a:hover {
      color: $brand-hover;
    }
  }

  .popover {
    font-family: Montserrat, serif;
    max-width: inherit;
    border-radius: 0;
  }
}

.popover.bs-tether-element-attached-top {
  margin-top: 0;
}

#_desktop_top_menu {
  margin-bottom: 0;
  width: 100%;
  padding: 0;

  ul[data-depth="0"]>li>a {
    color: white;

  }

  .sub-menu {
    &.collapse {
      pointer-events: none;
      opacity: 0;
      z-index: 0;
      transform: translateY(90px);
      visibility: hidden;
      @media (min-width: 1281px) {
        display: flex;
      }
    }

    // border: 1px solid #ebebeb;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .16);

    ul[data-depth="1"]>li {
      width: 33%;

      >a {
        color: $gray-black;
        margin: 0;
        display: flex;
        position: relative;
        align-items: center;
        width: auto;
        padding-left: 4px;

        &:after {
          content: "";
          width: 12.5%;
          height: 1px;
          transition: all .3s ease;
          background: rgba(58, 58, 58, 0.3);
          display: block;
          margin-left: 4.5%;
        }

        &:hover {
          color: $brand-hover;
        }
      }
    }

    ul[data-depth="2"]>li {
      &:not(:last-child) {

        @media (min-width: 1881px) {
          margin-bottom: 12.5px;
        }

        @media (min-width: 1281px) and (max-width: 1880px) {
          margin-bottom: calc(4px + (12.5 - 4) * ((100vw - 1281px) / (1880 - 1281)));
        }
      }
      font-size: 13px;

      >a {
        color: $gray;

        &:hover {
          color: $brand-primary;
        }
      }
    }

    ul[data-depth="2"]>li a {
      padding: 0;
      display: inline;
    }

    .top-menu {
      li {
        a {
          position: relative;
          color: $gray-black;
          padding: 0;
          width: max-content;

          &:before {
            display: none;
          }

          &:hover {
            color: $brand-hover;
          }
        }
        .label-menu-sale,
        .label-menu-new,
        .label-menu-hot{
          display: block;
          position: absolute;
          padding: 1px 8px 2px 8px;
          left: 106%;
          top: -71%;
          color: #fff;
          border-radius: 2px;

          //font-size: 11px;
          font-size: 12px;
          line-height: 14px;
          font-weight: 300;
          &::after{
            content: none;
            display: inline-block;
            position: absolute;
            top: 93%;
            left: 0;
          }
        }
        .label-menu-sale{
          background-color: #fd6b6b;
          &::after{
            border: 4px solid #fd6b6b;
            border-right-color: transparent;
            border-bottom-color: transparent;
          }
        }
        .label-menu-new{
          background-color: #13a2fe;
          &::after{
            border: 4px solid #13a2fe;
            border-right-color: transparent;
            border-bottom-color: transparent;
          }
        }
        .label-menu-hot{
          background-color: $brand-warning;
          &::after{
            border: 4px solid $brand-warning;
            border-right-color: transparent;
            border-bottom-color: transparent;
          }
        }
      }
    }
  }

  >.top-menu {
    >li.nav-arrows {
      >a {
        &:after {
          width: calc(100% - 24px);
        }
      }
    }

    >li {
      padding: 0 15px;

      >a {
        position: relative;
        padding: 0;
        font-size: 16px;
        font-weight: normal;
      }
    }

    >li.sfHover {
      >a {
        &:after {
          transform: scaleX(1);
          width: calc(100% - 24px);
        }
      }
    }
  }

  .top-menu {
    &[data-depth="0"] {
      li {
        &:hover {
          .sub-menu {
            opacity: 1;
            pointer-events: auto;
            transition: all .3s linear;
            z-index: 10;
            transform: translateY(77px);
            visibility: inherit;
          }
        }
      }
    }

    .nav-arrows {
      &>a {
        display: block;
        &::before{
          content: "";
          display: block;
          width: 100%;
          height: 60px;
          position: absolute;
        }
      }

      i {
        font-size: 20px;
        color: white;
        transition: all 0.3s ease;
        display: inline-block;
        vertical-align: -4px;
      }
    }
  }
}

#mobile_top_menu_wrapper {
  padding: 15px;
  background: rgba(58, 58, 58, 0.9);
  width: 80%;
  transform: translateX(-150%);
  height: 100vh;
  overflow: auto;
  position: absolute;
  left: 0;
  z-index: 1055;
  padding-top: 5px;

  &.active {
    transform: translateX(0);
  }

  #top-menu {
    margin-bottom: 0;
  }
  .top-menu {
    flex-direction: column;
  }

  #_mobile_language_selector,
  #_mobile_currency_selector {
    >div {
      padding: 15px 0 0 0;
    }
  }

  .js-top-menu-bottom {
    border-bottom: 1px solid #626262;
    border-top: 1px solid #414141;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    padding-top: 5px;

    select {
      width: 100%;
      background-color: #6a6a6a;
      border: 1px solid #939191;
      color: #fff;

      option:first-child {
        border-top: none;
      }
    }

    a {
      color: $gray;
    }

    .language-selector-wrapper {
      width: 100%;
      padding: 0.625rem;

      .language-selector {
        display: inline;
      }
    }

    #_mobile_currency_selector,
    #_mobile_language_selector {
      width: 50%;
      padding: 0 10px;
    }

    .currency-selector {
      padding: 0.625rem;
    }
  }

  .top-menu {
    color: $gray-darker;

    .collapse-icons[aria-expanded="true"] {
      .add {
        display: none;
      }

      .remove {
        display: block;
      }
    }

    .collapse-icons .remove {
      display: none;
    }


    a[data-depth="0"] {
      padding: 12px 0px;
      position: relative;
      border-bottom: 1px solid #626262;
      box-shadow: inset 0 -2px 2px -2px #414141;
      color: #fff;
      font-size: 16px;
      text-transform: uppercase;


      .label-menu-hot,
      .label-menu-sale {
        top: -7px;
        left: -5px;
      }
    }

    .collapse {
      display: none;

      &.show {
        display: block;
      }
    }

    .sub-menu {

      &.collapse {
        display: none;

        &.show {
          display: block;
        }
      }

      box-shadow: none;
      z-index: inherit;
      display: block;
      position: static;
      overflow: hidden;
      margin-left: 0;
      width: 100%;
      min-width: 100%;
      background: transparent;
      border: none;



      ul[data-depth="1"] {
        margin: 0;

        >li {
          float: none;
          margin: 0;

          a {
            text-transform: none;
            transition: all 0.2s linear;
            color: #ffffff;

            &:hover {
              color: #ffffff;
            }
          }
        }
      }

      ul {
        padding: 0;
      }

      li>a {
        padding: 5px 15px;
        font-weight: 400;
      }

      ul[data-depth="2"] {
        padding-bottom: 10px;

        li a {
          padding-left: 30px;
          font-size: 14px;
        }
      }

      ul[data-depth="3"] li a {
        padding-left: 2.5rem;
      }

      ul[data-depth="4"] li a {
        padding-left: 3.75rem;
      }
    }
  }
}
.bon-link-overlay {
  position: absolute;
  background-color: #393939;
  z-index: 0;
  height: 100%;
  transition: .3s linear,opacity;
  opacity: 0;
  border-radius: 6px;
}
.bon-link-overlay.active {
  opacity: 1;
}
@media screen and (min-width: 1881px) {
  .top-menu .sub-menu .sub-menu_wrepper {
    padding: 30px 24px;
  }
}
@media (min-width: 991px) and (max-width: 1880px) {
  .top-menu .sub-menu .sub-menu_wrepper {
    padding: calc(5px + (30 - 5) * ((100vw - 991px) / (1880 - 991))) calc(-1px + (24 - -1) * ((100vw - 991px) / (1880 - 991)));
  }
}
@media (max-width: 1450px) {
  #header .header-top .position-static .bon-nav-bar > #_desktop_search_widget:not(.modal) {
    display: none;
  }
}
@media (max-width: 1280px) {
  #_desktop_top_menu .sub-menu ul[data-depth="2"] > li:not(:last-child) {
    margin-bottom: 8px;
  }
}

@media (max-width: 991px) {
  #mobile_top_menu_wrapper .top-menu a[data-depth="0"] .current, .bon-link-overlay {
    display: none;
  }
  #header .top-menu {
    display: block;
  }
  .label-menu-sale,
  .label-menu-hot,
  .label-menu-new {
    left: auto;
    right: 10%;
  }
}
@media only screen and (max-width: 767px) {
  #top-menu .material-icons.current {
    display: none;
  }
}