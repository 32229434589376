/**
 * Title: Puppets Flaticon Pack
 * Author: Puppets
 * Source: http://www.flaticon.com/packs/puppets
 * License:  CC BY 3.0 (http://creativecommons.org/licenses/by/3.0/)
 */
  
@font-face {
  font-family: "fl-puppets";
  src: url(../fonts/4a99ea4387102b37eb9cff63ad7b4153.eot);
  src: url(../fonts/4a99ea4387102b37eb9cff63ad7b4153.eot#iefix)
      format("embedded-opentype"),
    url(../fonts/9b7f9c87434ee8446f7a8edb8631f43b.woff) format("woff"),
    url(../fonts/7e002d4169e0bf5acae8f026c9877505.ttf) format("truetype"),
    url(../fonts/ca3ac2759630d531a92a6c215986551e.svg) format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

.fl-puppets-ico, 
[class^="fl-puppets-"]:before, [class*=" fl-puppets-"]:before,
[class^="fl-puppets-"]:after, [class*=" fl-puppets-"]:after {   
	font-family: 'fl-puppets';
    font-size: inherit;
	font-weight: 400;
	font-style: normal;
}

.fl-puppets-audio-bars:before {
	content: "\e000";
}
.fl-puppets-balance7:before {
	content: "\e001";
}
.fl-puppets-bank17:before {
	content: "\e002";
}
.fl-puppets-basket36:before {
	content: "\e003";
}
.fl-puppets-bills5:before {
	content: "\e004";
}
.fl-puppets-bitcoin103:before {
	content: "\e005";
}
.fl-puppets-board2:before {
	content: "\e006";
}
.fl-puppets-book-bag:before {
	content: "\e007";
}
.fl-puppets-buy12:before {
	content: "\e008";
}
.fl-puppets-calendar184:before {
	content: "\e009";
}
.fl-puppets-calendar3:before {
	content: "\e00a";
}
.fl-puppets-chart5:before {
	content: "\e00b";
}
.fl-puppets-chart6:before {
	content: "\e00c";
}
.fl-puppets-chat-bubble:before {
	content: "\e00d";
}
.fl-puppets-circular-clock1:before {
	content: "\e00e";
}
.fl-puppets-circular-clock:before {
	content: "\e00f";
}
.fl-puppets-clipboard1:before {
	content: "\e010";
}
.fl-puppets-cloudy52:before {
	content: "\e011";
}
.fl-puppets-coffee-cup1:before {
	content: "\e012";
}
.fl-puppets-computer203:before {
	content: "\e013";
}
.fl-puppets-computer206:before {
	content: "\e014";
}
.fl-puppets-computer-monitor:before {
	content: "\e015";
}
.fl-puppets-counting6:before {
	content: "\e016";
}
.fl-puppets-currency14:before {
	content: "\e017";
}
.fl-puppets-currency15:before {
	content: "\e018";
}
.fl-puppets-dart19:before {
	content: "\e019";
}
.fl-puppets-diamond:before {
	content: "\e01a";
}
.fl-puppets-disc33:before {
	content: "\e01b";
}
.fl-puppets-document205:before {
	content: "\e01c";
}
.fl-puppets-document206:before {
	content: "\e01d";
}
.fl-puppets-documents1:before {
	content: "\e01e";
}
.fl-puppets-dollar-symbol1:before {
	content: "\e01f";
}
.fl-puppets-drink91:before {
	content: "\e020";
}
.fl-puppets-electronics1:before {
	content: "\e021";
}
.fl-puppets-equalizer:before {
	content: "\e022";
}
.fl-puppets-euro-coin:before {
	content: "\e023";
}
.fl-puppets-gallery1:before {
	content: "\e024";
}
.fl-puppets-geography6:before {
	content: "\e025";
}
.fl-puppets-gold-ingots:before {
	content: "\e026";
}
.fl-puppets-identification11:before {
	content: "\e027";
}
.fl-puppets-letter2:before {
	content: "\e028";
}
.fl-puppets-lifeguard6:before {
	content: "\e029";
}
.fl-puppets-light102:before {
	content: "\e02a";
}
.fl-puppets-location1:before {
	content: "\e02b";
}
.fl-puppets-loud1:before {
	content: "\e02c";
}
.fl-puppets-magnifier58:before {
	content: "\e02d";
}
.fl-puppets-map-point1:before {
	content: "\e02e";
}
.fl-puppets-market1:before {
	content: "\e02f";
}
.fl-puppets-maths26:before {
	content: "\e030";
}
.fl-puppets-maths27:before {
	content: "\e031";
}
.fl-puppets-money163:before {
	content: "\e032";
}
.fl-puppets-money-card1:before {
	content: "\e033";
}
.fl-puppets-money-card:before {
	content: "\e034";
}
.fl-puppets-money-currency:before {
	content: "\e035";
}
.fl-puppets-negative:before {
	content: "\e036";
}
.fl-puppets-newspaper18:before {
	content: "\e037";
}
.fl-puppets-notebook83:before {
	content: "\e038";
}
.fl-puppets-nut1:before {
	content: "\e039";
}
.fl-puppets-padlock75:before {
	content: "\e03a";
}
.fl-puppets-paperclip1:before {
	content: "\e03b";
}
.fl-puppets-paper-plane:before {
	content: "\e03c";
}
.fl-puppets-pencil1:before {
	content: "\e03d";
}
.fl-puppets-percentage1:before {
	content: "\e03e";
}
.fl-puppets-phone11:before {
	content: "\e03f";
}
.fl-puppets-photo-camera2:before {
	content: "\e040";
}
.fl-puppets-physics1:before {
	content: "\e041";
}
.fl-puppets-picture42:before {
	content: "\e042";
}
.fl-puppets-pound:before {
	content: "\e043";
}
.fl-puppets-power2:before {
	content: "\e044";
}
.fl-puppets-present27:before {
	content: "\e045";
}
.fl-puppets-print52:before {
	content: "\e046";
}
.fl-puppets-profiles-avatar:before {
	content: "\e047";
}
.fl-puppets-purse9:before {
	content: "\e048";
}
.fl-puppets-read7:before {
	content: "\e049";
}
.fl-puppets-rubbish12:before {
	content: "\e04a";
}
.fl-puppets-savings3:before {
	content: "\e04b";
}
.fl-puppets-schedule4:before {
	content: "\e04c";
}
.fl-puppets-science28:before {
	content: "\e04d";
}
.fl-puppets-sheet5:before {
	content: "\e04e";
}
.fl-puppets-shopping1:before {
	content: "\e04f";
}
.fl-puppets-shopping2:before {
	content: "\e050";
}
.fl-puppets-shop:before {
	content: "\e051";
}
.fl-puppets-stormy5:before {
	content: "\e052";
}
.fl-puppets-telephone113:before {
	content: "\e053";
}
.fl-puppets-telephone114:before {
	content: "\e054";
}
.fl-puppets-telephone115:before {
	content: "\e055";
}
.fl-puppets-telephone116:before {
	content: "\e056";
}
.fl-puppets-telephone117:before {
	content: "\e057";
}
.fl-puppets-telesales:before {
	content: "\e058";
}
.fl-puppets-test3:before {
	content: "\e059";
}
.fl-puppets-text166:before {
	content: "\e05a";
}
.fl-puppets-text168:before {
	content: "\e05b";
}
.fl-puppets-think4:before {
	content: "\e05c";
}
.fl-puppets-think5:before {
	content: "\e05d";
}
.fl-puppets-thumb56:before {
	content: "\e05e";
}
.fl-puppets-tie7:before {
	content: "\e05f";
}
.fl-puppets-time:before {
	content: "\e060";
}
.fl-puppets-users30:before {
	content: "\e061";
}
.fl-puppets-withdrawing:before {
	content: "\e062";
}
.fl-puppets-woman154:before {
	content: "\e063";
}
