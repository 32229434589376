/* -----------------------------------------------------------------------------------------------------------------
												 ANIMATE STYLE
------------------------------------------------------------------------------------------------------------------- */
.revealOnScroll {
  opacity: 0;
}

.revealOnScroll.animated {
  opacity: 1;
}

.animated {
  -moz-animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  z-index: 1;
}
.animated:hover {
  z-index: 5;
}

.animated.infinite {
  -moz-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite; }

.zoomIn {
  -moz-animation-name: zoomIn;
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn; }

.fadeInUp {
  -moz-animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp; }
.fadeIn {
  -moz-animation-name: fadeIn;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}
.fadeInForHeader {
  -moz-animation-name: fadeIn;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
  animation-delay: .3s;
}

// .flipInX {
//   -moz-animation-name: flipInX;
//   -webkit-animation-name: flipInX;
//   animation-name: flipInX; }

// .flipInY {
//   -moz-animation-name: flipInX;
//   -webkit-animation-name: flipInX;
//   animation-name: flipInX; }

// .flip {
//   -moz-animation-name: flip;
//   -webkit-animation-name: flip;
//   animation-name: flip; }

// .zoomInDown {
//   -moz-animation-name: zoomInDown;
//   -webkit-animation-name: zoomInDown;
//   animation-name: zoomInDown; }

// .tada {
//   -moz-animation-name: tada;
//   -webkit-animation-name: tada;
//   animation-name: tada; }

// .rotateIn {
//   -moz-animation-name: rotateIn;
//   -webkit-animation-name: rotateIn;
//   animation-name: rotateIn; }

// .swing {
//   -moz-animation-name: swing;
//   -webkit-animation-name: swing;
//   animation-name: swing; }

// .wobble {
//   -moz-animation-name: wobble;
//   -webkit-animation-name: wobble;
//   animation-name: wobble; }

// @-moz-keyframes flash {
//   0% {
//     opacity: 1; }

//   25% {
//     opacity: 0; }

//   50% {
//     opacity: 1; }

//   75% {
//     opacity: 0; }

//   100% {
//     opacity: 1; } }
// @-webkit-keyframes flash {
//   0% {
//     opacity: 1; }

//   25% {
//     opacity: 0; }

//   50% {
//     opacity: 1; }

//   75% {
//     opacity: 0; }

//   100% {
//     opacity: 1; } }
// @keyframes flash {
//   0% {
//     opacity: 1; }

//   25% {
//     opacity: 0; }

//   50% {
//     opacity: 1; }

//   75% {
//     opacity: 0; }

//   100% {
//     opacity: 1; } }
// @-moz-keyframes shake {
//   0% {
//     -moz-transform: translateX(0);
//     transform: translateX(0); }

//   10% {
//     -moz-transform: translateX(-10px);
//     transform: translateX(-10px); }

//   20% {
//     -moz-transform: translateX(10px);
//     transform: translateX(10px); }

//   30% {
//     -moz-transform: translateX(-10px);
//     transform: translateX(-10px); }

//   40% {
//     -moz-transform: translateX(10px);
//     transform: translateX(10px); }

//   50% {
//     -moz-transform: translateX(-10px);
//     transform: translateX(-10px); }

//   60% {
//     -moz-transform: translateX(10px);
//     transform: translateX(10px); }

//   70% {
//     -moz-transform: translateX(-10px);
//     transform: translateX(-10px); }

//   80% {
//     -moz-transform: translateX(10px);
//     transform: translateX(10px); }

//   90% {
//     -moz-transform: translateX(-10px);
//     transform: translateX(-10px); }

//   100% {
//     -moz-transform: translateX(0);
//     transform: translateX(0); } }
// @-webkit-keyframes shake {
//   0% {
//     -webkit-transform: translateX(0);
//     transform: translateX(0); }

//   10% {
//     -webkit-transform: translateX(-10px);
//     transform: translateX(-10px); }

//   20% {
//     -webkit-transform: translateX(10px);
//     transform: translateX(10px); }

//   30% {
//     -webkit-transform: translateX(-10px);
//     transform: translateX(-10px); }

//   40% {
//     -webkit-transform: translateX(10px);
//     transform: translateX(10px); }

//   50% {
//     -webkit-transform: translateX(-10px);
//     transform: translateX(-10px); }

//   60% {
//     -webkit-transform: translateX(10px);
//     transform: translateX(10px); }

//   70% {
//     -webkit-transform: translateX(-10px);
//     transform: translateX(-10px); }

//   80% {
//     -webkit-transform: translateX(10px);
//     transform: translateX(10px); }

//   90% {
//     -webkit-transform: translateX(-10px);
//     transform: translateX(-10px); }

//   100% {
//     -webkit-transform: translateX(0);
//     transform: translateX(0); } }
// @keyframes shake {
//   0% {
//     -moz-transform: translateX(0);
//     -ms-transform: translateX(0);
//     -o-transform: translateX(0);
//     -webkit-transform: translateX(0);
//     transform: translateX(0); }

//   10% {
//     -moz-transform: translateX(-10px);
//     -ms-transform: translateX(-10px);
//     -o-transform: translateX(-10px);
//     -webkit-transform: translateX(-10px);
//     transform: translateX(-10px); }

//   20% {
//     -moz-transform: translateX(10px);
//     -ms-transform: translateX(10px);
//     -o-transform: translateX(10px);
//     -webkit-transform: translateX(10px);
//     transform: translateX(10px); }

//   30% {
//     -moz-transform: translateX(-10px);
//     -ms-transform: translateX(-10px);
//     -o-transform: translateX(-10px);
//     -webkit-transform: translateX(-10px);
//     transform: translateX(-10px); }

//   40% {
//     -moz-transform: translateX(10px);
//     -ms-transform: translateX(10px);
//     -o-transform: translateX(10px);
//     -webkit-transform: translateX(10px);
//     transform: translateX(10px); }

//   50% {
//     -moz-transform: translateX(-10px);
//     -ms-transform: translateX(-10px);
//     -o-transform: translateX(-10px);
//     -webkit-transform: translateX(-10px);
//     transform: translateX(-10px); }

//   60% {
//     -moz-transform: translateX(10px);
//     -ms-transform: translateX(10px);
//     -o-transform: translateX(10px);
//     -webkit-transform: translateX(10px);
//     transform: translateX(10px); }

//   70% {
//     -moz-transform: translateX(-10px);
//     -ms-transform: translateX(-10px);
//     -o-transform: translateX(-10px);
//     -webkit-transform: translateX(-10px);
//     transform: translateX(-10px); }

//   80% {
//     -moz-transform: translateX(10px);
//     -ms-transform: translateX(10px);
//     -o-transform: translateX(10px);
//     -webkit-transform: translateX(10px);
//     transform: translateX(10px); }

//   90% {
//     -moz-transform: translateX(-10px);
//     -ms-transform: translateX(-10px);
//     -o-transform: translateX(-10px);
//     -webkit-transform: translateX(-10px);
//     transform: translateX(-10px); }

//   100% {
//     -moz-transform: translateX(0);
//     -ms-transform: translateX(0);
//     -o-transform: translateX(0);
//     -webkit-transform: translateX(0);
//     transform: translateX(0); } }
// @-moz-keyframes tada {
//   0% {
//     -moz-transform: scale(1, 1);
//     transform: scale(1, 1); }

//   10% {
//     -moz-transform: scale(0.9) rotate(-3deg);
//     transform: scale(0.9) rotate(-3deg); }

//   20% {
//     -moz-transform: scale(0.9) rotate(-3deg);
//     transform: scale(0.9) rotate(-3deg); }

//   30% {
//     -moz-transform: scale(1.1) rotate(3deg);
//     transform: scale(1.1) rotate(3deg); }

//   40% {
//     -moz-transform: scale(1.1) rotate(-3deg);
//     transform: scale(1.1) rotate(-3deg); }

//   50% {
//     -moz-transform: scale(1.1) rotate(3deg);
//     transform: scale(1.1) rotate(3deg); }

//   60% {
//     -moz-transform: scale(1.1) rotate(-3deg);
//     transform: scale(1.1) rotate(-3deg); }

//   70% {
//     -moz-transform: scale(1.1) rotate(3deg);
//     transform: scale(1.1) rotate(3deg); }

//   80% {
//     -moz-transform: scale(1.1) rotate(-3deg);
//     transform: scale(1.1) rotate(-3deg); }

//   90% {
//     -moz-transform: scale(1.1) rotate(3deg);
//     transform: scale(1.1) rotate(3deg); }

//   100% {
//     -moz-transform: scale(1) rotate(0);
//     transform: scale(1) rotate(0); } }
// @-webkit-keyframes tada {
//   0% {
//     -webkit-transform: scale(1, 1);
//     transform: scale(1, 1); }

//   10% {
//     -webkit-transform: scale(0.9) rotate(-3deg);
//     transform: scale(0.9) rotate(-3deg); }

//   20% {
//     -webkit-transform: scale(0.9) rotate(-3deg);
//     transform: scale(0.9) rotate(-3deg); }

//   30% {
//     -webkit-transform: scale(1.1) rotate(3deg);
//     transform: scale(1.1) rotate(3deg); }

//   40% {
//     -webkit-transform: scale(1.1) rotate(-3deg);
//     transform: scale(1.1) rotate(-3deg); }

//   50% {
//     -webkit-transform: scale(1.1) rotate(3deg);
//     transform: scale(1.1) rotate(3deg); }

//   60% {
//     -webkit-transform: scale(1.1) rotate(-3deg);
//     transform: scale(1.1) rotate(-3deg); }

//   70% {
//     -webkit-transform: scale(1.1) rotate(3deg);
//     transform: scale(1.1) rotate(3deg); }

//   80% {
//     -webkit-transform: scale(1.1) rotate(-3deg);
//     transform: scale(1.1) rotate(-3deg); }

//   90% {
//     -webkit-transform: scale(1.1) rotate(3deg);
//     transform: scale(1.1) rotate(3deg); }

//   100% {
//     -webkit-transform: scale(1) rotate(0);
//     transform: scale(1) rotate(0); } }
// @keyframes tada {
//   0% {
//     -moz-transform: scale(1, 1);
//     -ms-transform: scale(1, 1);
//     -o-transform: scale(1, 1);
//     -webkit-transform: scale(1, 1);
//     transform: scale(1, 1); }

//   10% {
//     -moz-transform: scale(0.9) rotate(-3deg);
//     -ms-transform: scale(0.9) rotate(-3deg);
//     -o-transform: scale(0.9) rotate(-3deg);
//     -webkit-transform: scale(0.9) rotate(-3deg);
//     transform: scale(0.9) rotate(-3deg); }

//   20% {
//     -moz-transform: scale(0.9) rotate(-3deg);
//     -ms-transform: scale(0.9) rotate(-3deg);
//     -o-transform: scale(0.9) rotate(-3deg);
//     -webkit-transform: scale(0.9) rotate(-3deg);
//     transform: scale(0.9) rotate(-3deg); }

//   30% {
//     -moz-transform: scale(1.1) rotate(3deg);
//     -ms-transform: scale(1.1) rotate(3deg);
//     -o-transform: scale(1.1) rotate(3deg);
//     -webkit-transform: scale(1.1) rotate(3deg);
//     transform: scale(1.1) rotate(3deg); }

//   40% {
//     -moz-transform: scale(1.1) rotate(-3deg);
//     -ms-transform: scale(1.1) rotate(-3deg);
//     -o-transform: scale(1.1) rotate(-3deg);
//     -webkit-transform: scale(1.1) rotate(-3deg);
//     transform: scale(1.1) rotate(-3deg); }

//   50% {
//     -moz-transform: scale(1.1) rotate(3deg);
//     -ms-transform: scale(1.1) rotate(3deg);
//     -o-transform: scale(1.1) rotate(3deg);
//     -webkit-transform: scale(1.1) rotate(3deg);
//     transform: scale(1.1) rotate(3deg); }

//   60% {
//     -moz-transform: scale(1.1) rotate(-3deg);
//     -ms-transform: scale(1.1) rotate(-3deg);
//     -o-transform: scale(1.1) rotate(-3deg);
//     -webkit-transform: scale(1.1) rotate(-3deg);
//     transform: scale(1.1) rotate(-3deg); }

//   70% {
//     -moz-transform: scale(1.1) rotate(3deg);
//     -ms-transform: scale(1.1) rotate(3deg);
//     -o-transform: scale(1.1) rotate(3deg);
//     -webkit-transform: scale(1.1) rotate(3deg);
//     transform: scale(1.1) rotate(3deg); }

//   80% {
//     -moz-transform: scale(1.1) rotate(-3deg);
//     -ms-transform: scale(1.1) rotate(-3deg);
//     -o-transform: scale(1.1) rotate(-3deg);
//     -webkit-transform: scale(1.1) rotate(-3deg);
//     transform: scale(1.1) rotate(-3deg); }

//   90% {
//     -moz-transform: scale(1.1) rotate(3deg);
//     -ms-transform: scale(1.1) rotate(3deg);
//     -o-transform: scale(1.1) rotate(3deg);
//     -webkit-transform: scale(1.1) rotate(3deg);
//     transform: scale(1.1) rotate(3deg); }

//   100% {
//     -moz-transform: scale(1) rotate(0);
//     -ms-transform: scale(1) rotate(0);
//     -o-transform: scale(1) rotate(0);
//     -webkit-transform: scale(1) rotate(0);
//     transform: scale(1) rotate(0); } }
// @-moz-keyframes swing {
//   20%, 40%, 60%, 80%, 100% {
//     -moz-transform-origin: top center;
//     transform-origin: top center; }

//   20% {
//     -moz-transform: rotate(15deg);
//     transform: rotate(15deg); }

//   40% {
//     -moz-transform: rotate(-10deg);
//     transform: rotate(-10deg); }

//   60% {
//     -moz-transform: rotate(5deg);
//     transform: rotate(5deg); }

//   80% {
//     -moz-transform: rotate(-5deg);
//     transform: rotate(-5deg); }

//   100% {
//     -moz-transform: rotate(0deg);
//     transform: rotate(0deg); } }
// @-webkit-keyframes swing {
//   20%, 40%, 60%, 80%, 100% {
//     -webkit-transform-origin: top center;
//     transform-origin: top center; }

//   20% {
//     -webkit-transform: rotate(15deg);
//     transform: rotate(15deg); }

//   40% {
//     -webkit-transform: rotate(-10deg);
//     transform: rotate(-10deg); }

//   60% {
//     -webkit-transform: rotate(5deg);
//     transform: rotate(5deg); }

//   80% {
//     -webkit-transform: rotate(-5deg);
//     transform: rotate(-5deg); }

//   100% {
//     -webkit-transform: rotate(0deg);
//     transform: rotate(0deg); } }
// @keyframes swing {
//   20%, 40%, 60%, 80%, 100% {
//     -moz-transform-origin: top center;
//     -ms-transform-origin: top center;
//     -o-transform-origin: top center;
//     -webkit-transform-origin: top center;
//     transform-origin: top center; }

//   20% {
//     -moz-transform: rotate(15deg);
//     -ms-transform: rotate(15deg);
//     -o-transform: rotate(15deg);
//     -webkit-transform: rotate(15deg);
//     transform: rotate(15deg); }

//   40% {
//     -moz-transform: rotate(-10deg);
//     -ms-transform: rotate(-10deg);
//     -o-transform: rotate(-10deg);
//     -webkit-transform: rotate(-10deg);
//     transform: rotate(-10deg); }

//   60% {
//     -moz-transform: rotate(5deg);
//     -ms-transform: rotate(5deg);
//     -o-transform: rotate(5deg);
//     -webkit-transform: rotate(5deg);
//     transform: rotate(5deg); }

//   80% {
//     -moz-transform: rotate(-5deg);
//     -ms-transform: rotate(-5deg);
//     -o-transform: rotate(-5deg);
//     -webkit-transform: rotate(-5deg);
//     transform: rotate(-5deg); }

//   100% {
//     -moz-transform: rotate(0deg);
//     -ms-transform: rotate(0deg);
//     -o-transform: rotate(0deg);
//     -webkit-transform: rotate(0deg);
//     transform: rotate(0deg); } }
// @-moz-keyframes wobble {
//   0% {
//     -moz-transform: translateX(0%);
//     transform: translateX(0%); }

//   15% {
//     -moz-transform: translateX(-25%) rotate(-5deg);
//     transform: translateX(-25%) rotate(-5deg); }

//   30% {
//     -moz-transform: translateX(20%) rotate(3deg);
//     transform: translateX(20%) rotate(3deg); }

//   45% {
//     -moz-transform: translateX(-15%) rotate(-3deg);
//     transform: translateX(-15%) rotate(-3deg); }

//   60% {
//     -moz-transform: translateX(10%) rotate(2deg);
//     transform: translateX(10%) rotate(2deg); }

//   75% {
//     -moz-transform: translateX(-5%) rotate(-1deg);
//     transform: translateX(-5%) rotate(-1deg); }

//   100% {
//     -moz-transform: translateX(0%);
//     transform: translateX(0%); } }
// @-webkit-keyframes wobble {
//   0% {
//     -webkit-transform: translateX(0%);
//     transform: translateX(0%); }

//   15% {
//     -webkit-transform: translateX(-25%) rotate(-5deg);
//     transform: translateX(-25%) rotate(-5deg); }

//   30% {
//     -webkit-transform: translateX(20%) rotate(3deg);
//     transform: translateX(20%) rotate(3deg); }

//   45% {
//     -webkit-transform: translateX(-15%) rotate(-3deg);
//     transform: translateX(-15%) rotate(-3deg); }

//   60% {
//     -webkit-transform: translateX(10%) rotate(2deg);
//     transform: translateX(10%) rotate(2deg); }

//   75% {
//     -webkit-transform: translateX(-5%) rotate(-1deg);
//     transform: translateX(-5%) rotate(-1deg); }

//   100% {
//     -webkit-transform: translateX(0%);
//     transform: translateX(0%); } }
// @keyframes wobble {
//   0% {
//     -moz-transform: translateX(0%);
//     -ms-transform: translateX(0%);
//     -o-transform: translateX(0%);
//     -webkit-transform: translateX(0%);
//     transform: translateX(0%); }

//   15% {
//     -moz-transform: translateX(-25%) rotate(-5deg);
//     -ms-transform: translateX(-25%) rotate(-5deg);
//     -o-transform: translateX(-25%) rotate(-5deg);
//     -webkit-transform: translateX(-25%) rotate(-5deg);
//     transform: translateX(-25%) rotate(-5deg); }

//   30% {
//     -moz-transform: translateX(20%) rotate(3deg);
//     -ms-transform: translateX(20%) rotate(3deg);
//     -o-transform: translateX(20%) rotate(3deg);
//     -webkit-transform: translateX(20%) rotate(3deg);
//     transform: translateX(20%) rotate(3deg); }

//   45% {
//     -moz-transform: translateX(-15%) rotate(-3deg);
//     -ms-transform: translateX(-15%) rotate(-3deg);
//     -o-transform: translateX(-15%) rotate(-3deg);
//     -webkit-transform: translateX(-15%) rotate(-3deg);
//     transform: translateX(-15%) rotate(-3deg); }

//   60% {
//     -moz-transform: translateX(10%) rotate(2deg);
//     -ms-transform: translateX(10%) rotate(2deg);
//     -o-transform: translateX(10%) rotate(2deg);
//     -webkit-transform: translateX(10%) rotate(2deg);
//     transform: translateX(10%) rotate(2deg); }

//   75% {
//     -moz-transform: translateX(-5%) rotate(-1deg);
//     -ms-transform: translateX(-5%) rotate(-1deg);
//     -o-transform: translateX(-5%) rotate(-1deg);
//     -webkit-transform: translateX(-5%) rotate(-1deg);
//     transform: translateX(-5%) rotate(-1deg); }

//   100% {
//     -moz-transform: translateX(0%);
//     -ms-transform: translateX(0%);
//     -o-transform: translateX(0%);
//     -webkit-transform: translateX(0%);
//     transform: translateX(0%); } }
// @-moz-keyframes pulse {
//   0% {
//     -moz-transform: scale(1, 1);
//     transform: scale(1, 1); }

//   50% {
//     -moz-transform: scale(1.1, 1.1);
//     transform: scale(1.1, 1.1); }

//   100% {
//     -moz-transform: scale(1, 1);
//     transform: scale(1, 1); } }
// @-webkit-keyframes pulse {
//   0% {
//     -webkit-transform: scale(1, 1);
//     transform: scale(1, 1); }

//   50% {
//     -webkit-transform: scale(1.1, 1.1);
//     transform: scale(1.1, 1.1); }

//   100% {
//     -webkit-transform: scale(1, 1);
//     transform: scale(1, 1); } }
// @keyframes pulse {
//   0% {
//     -moz-transform: scale(1, 1);
//     -ms-transform: scale(1, 1);
//     -o-transform: scale(1, 1);
//     -webkit-transform: scale(1, 1);
//     transform: scale(1, 1); }

//   50% {
//     -moz-transform: scale(1.1, 1.1);
//     -ms-transform: scale(1.1, 1.1);
//     -o-transform: scale(1.1, 1.1);
//     -webkit-transform: scale(1.1, 1.1);
//     transform: scale(1.1, 1.1); }

//   100% {
//     -moz-transform: scale(1, 1);
//     -ms-transform: scale(1, 1);
//     -o-transform: scale(1, 1);
//     -webkit-transform: scale(1, 1);
//     transform: scale(1, 1); } }
// @-moz-keyframes wiggle {
//   0% {
//     -moz-transform: skewX(9deg);
//     transform: skewX(9deg); }

//   10% {
//     -moz-transform: skewX(-8deg);
//     transform: skewX(-8deg); }

//   20% {
//     -moz-transform: skewX(7deg);
//     transform: skewX(7deg); }

//   30% {
//     -moz-transform: skewX(-6deg);
//     transform: skewX(-6deg); }

//   40% {
//     -moz-transform: skewX(5deg);
//     transform: skewX(5deg); }

//   50% {
//     -moz-transform: skewX(-4deg);
//     transform: skewX(-4deg); }

//   60% {
//     -moz-transform: skewX(3deg);
//     transform: skewX(3deg); }

//   70% {
//     -moz-transform: skewX(-2deg);
//     transform: skewX(-2deg); }

//   80% {
//     -moz-transform: skewX(1deg);
//     transform: skewX(1deg); }

//   90% {
//     -moz-transform: skewX(0deg);
//     transform: skewX(0deg); }

//   100% {
//     -moz-transform: skewX(0deg);
//     transform: skewX(0deg); } }
// @-webkit-keyframes wiggle {
//   0% {
//     -webkit-transform: skewX(9deg);
//     transform: skewX(9deg); }

//   10% {
//     -webkit-transform: skewX(-8deg);
//     transform: skewX(-8deg); }

//   20% {
//     -webkit-transform: skewX(7deg);
//     transform: skewX(7deg); }

//   30% {
//     -webkit-transform: skewX(-6deg);
//     transform: skewX(-6deg); }

//   40% {
//     -webkit-transform: skewX(5deg);
//     transform: skewX(5deg); }

//   50% {
//     -webkit-transform: skewX(-4deg);
//     transform: skewX(-4deg); }

//   60% {
//     -webkit-transform: skewX(3deg);
//     transform: skewX(3deg); }

//   70% {
//     -webkit-transform: skewX(-2deg);
//     transform: skewX(-2deg); }

//   80% {
//     -webkit-transform: skewX(1deg);
//     transform: skewX(1deg); }

//   90% {
//     -webkit-transform: skewX(0deg);
//     transform: skewX(0deg); }

//   100% {
//     -webkit-transform: skewX(0deg);
//     transform: skewX(0deg); } }
// @keyframes wiggle {
//   0% {
//     -moz-transform: skewX(9deg);
//     -ms-transform: skewX(9deg);
//     -o-transform: skewX(9deg);
//     -webkit-transform: skewX(9deg);
//     transform: skewX(9deg); }

//   10% {
//     -moz-transform: skewX(-8deg);
//     -ms-transform: skewX(-8deg);
//     -o-transform: skewX(-8deg);
//     -webkit-transform: skewX(-8deg);
//     transform: skewX(-8deg); }

//   20% {
//     -moz-transform: skewX(7deg);
//     -ms-transform: skewX(7deg);
//     -o-transform: skewX(7deg);
//     -webkit-transform: skewX(7deg);
//     transform: skewX(7deg); }

//   30% {
//     -moz-transform: skewX(-6deg);
//     -ms-transform: skewX(-6deg);
//     -o-transform: skewX(-6deg);
//     -webkit-transform: skewX(-6deg);
//     transform: skewX(-6deg); }

//   40% {
//     -moz-transform: skewX(5deg);
//     -ms-transform: skewX(5deg);
//     -o-transform: skewX(5deg);
//     -webkit-transform: skewX(5deg);
//     transform: skewX(5deg); }

//   50% {
//     -moz-transform: skewX(-4deg);
//     -ms-transform: skewX(-4deg);
//     -o-transform: skewX(-4deg);
//     -webkit-transform: skewX(-4deg);
//     transform: skewX(-4deg); }

//   60% {
//     -moz-transform: skewX(3deg);
//     -ms-transform: skewX(3deg);
//     -o-transform: skewX(3deg);
//     -webkit-transform: skewX(3deg);
//     transform: skewX(3deg); }

//   70% {
//     -moz-transform: skewX(-2deg);
//     -ms-transform: skewX(-2deg);
//     -o-transform: skewX(-2deg);
//     -webkit-transform: skewX(-2deg);
//     transform: skewX(-2deg); }

//   80% {
//     -moz-transform: skewX(1deg);
//     -ms-transform: skewX(1deg);
//     -o-transform: skewX(1deg);
//     -webkit-transform: skewX(1deg);
//     transform: skewX(1deg); }

//   90% {
//     -moz-transform: skewX(0deg);
//     -ms-transform: skewX(0deg);
//     -o-transform: skewX(0deg);
//     -webkit-transform: skewX(0deg);
//     transform: skewX(0deg); }

//   100% {
//     -moz-transform: skewX(0deg);
//     -ms-transform: skewX(0deg);
//     -o-transform: skewX(0deg);
//     -webkit-transform: skewX(0deg);
//     transform: skewX(0deg); } }
// @-moz-keyframes rubberBand {
//   0% {
//     -moz-transform: scale3d(1, 1, 1);
//     transform: scale3d(1, 1, 1); }

//   30% {
//     -moz-transform: scale3d(1.25, 0.75, 1);
//     transform: scale3d(1.25, 0.75, 1); }

//   40% {
//     -moz-transform: scale3d(0.75, 1.25, 1);
//     transform: scale3d(0.75, 1.25, 1); }

//   50% {
//     -moz-transform: scale3d(1.15, 0.85, 1);
//     transform: scale3d(1.15, 0.85, 1); }

//   65% {
//     -moz-transform: scale3d(0.95, 1.05, 1);
//     transform: scale3d(0.95, 1.05, 1); }

//   75% {
//     -moz-transform: scale3d(1.05, 0.95, 1);
//     transform: scale3d(1.05, 0.95, 1); }

//   100% {
//     -moz-transform: scale3d(1, 1, 1);
//     transform: scale3d(1, 1, 1); } }
// @-webkit-keyframes rubberBand {
//   0% {
//     -webkit-transform: scale3d(1, 1, 1);
//     transform: scale3d(1, 1, 1); }

//   30% {
//     -webkit-transform: scale3d(1.25, 0.75, 1);
//     transform: scale3d(1.25, 0.75, 1); }

//   40% {
//     -webkit-transform: scale3d(0.75, 1.25, 1);
//     transform: scale3d(0.75, 1.25, 1); }

//   50% {
//     -webkit-transform: scale3d(1.15, 0.85, 1);
//     transform: scale3d(1.15, 0.85, 1); }

//   65% {
//     -webkit-transform: scale3d(0.95, 1.05, 1);
//     transform: scale3d(0.95, 1.05, 1); }

//   75% {
//     -webkit-transform: scale3d(1.05, 0.95, 1);
//     transform: scale3d(1.05, 0.95, 1); }

//   100% {
//     -webkit-transform: scale3d(1, 1, 1);
//     transform: scale3d(1, 1, 1); } }
// @keyframes rubberBand {
//   0% {
//     -moz-transform: scale3d(1, 1, 1);
//     -webkit-transform: scale3d(1, 1, 1);
//     transform: scale3d(1, 1, 1); }

//   30% {
//     -moz-transform: scale3d(1.25, 0.75, 1);
//     -webkit-transform: scale3d(1.25, 0.75, 1);
//     transform: scale3d(1.25, 0.75, 1); }

//   40% {
//     -moz-transform: scale3d(0.75, 1.25, 1);
//     -webkit-transform: scale3d(0.75, 1.25, 1);
//     transform: scale3d(0.75, 1.25, 1); }

//   50% {
//     -moz-transform: scale3d(1.15, 0.85, 1);
//     -webkit-transform: scale3d(1.15, 0.85, 1);
//     transform: scale3d(1.15, 0.85, 1); }

//   65% {
//     -moz-transform: scale3d(0.95, 1.05, 1);
//     -webkit-transform: scale3d(0.95, 1.05, 1);
//     transform: scale3d(0.95, 1.05, 1); }

//   75% {
//     -moz-transform: scale3d(1.05, 0.95, 1);
//     -webkit-transform: scale3d(1.05, 0.95, 1);
//     transform: scale3d(1.05, 0.95, 1); }

//   100% {
//     -moz-transform: scale3d(1, 1, 1);
//     -webkit-transform: scale3d(1, 1, 1);
//     transform: scale3d(1, 1, 1); } }
// @-moz-keyframes bounceIn {
//   0% {
//     opacity: 0;
//     -moz-transform: scale(0.3, 0.3);
//     transform: scale(0.3, 0.3); }

//   50% {
//     opacity: 1;
//     -moz-transform: scale(1.05, 1.05);
//     transform: scale(1.05, 1.05); }

//   70% {
//     -moz-transform: scale(0.9, 0.9);
//     transform: scale(0.9, 0.9); }

//   100% {
//     -moz-transform: scale(1, 1);
//     transform: scale(1, 1); } }
// @-webkit-keyframes bounceIn {
//   0% {
//     opacity: 0;
//     -webkit-transform: scale(0.3, 0.3);
//     transform: scale(0.3, 0.3); }

//   50% {
//     opacity: 1;
//     -webkit-transform: scale(1.05, 1.05);
//     transform: scale(1.05, 1.05); }

//   70% {
//     -webkit-transform: scale(0.9, 0.9);
//     transform: scale(0.9, 0.9); }

//   100% {
//     -webkit-transform: scale(1, 1);
//     transform: scale(1, 1); } }
// @keyframes bounceIn {
//   0% {
//     opacity: 0;
//     -moz-transform: scale(0.3, 0.3);
//     -ms-transform: scale(0.3, 0.3);
//     -o-transform: scale(0.3, 0.3);
//     -webkit-transform: scale(0.3, 0.3);
//     transform: scale(0.3, 0.3); }

//   50% {
//     opacity: 1;
//     -moz-transform: scale(1.05, 1.05);
//     -ms-transform: scale(1.05, 1.05);
//     -o-transform: scale(1.05, 1.05);
//     -webkit-transform: scale(1.05, 1.05);
//     transform: scale(1.05, 1.05); }

//   70% {
//     -moz-transform: scale(0.9, 0.9);
//     -ms-transform: scale(0.9, 0.9);
//     -o-transform: scale(0.9, 0.9);
//     -webkit-transform: scale(0.9, 0.9);
//     transform: scale(0.9, 0.9); }

//   100% {
//     -moz-transform: scale(1, 1);
//     -ms-transform: scale(1, 1);
//     -o-transform: scale(1, 1);
//     -webkit-transform: scale(1, 1);
//     transform: scale(1, 1); } }
// @-moz-keyframes bounceInUp {
//   0% {
//     opacity: 0;
//     -moz-transform: translateY(2000px);
//     transform: translateY(2000px); }

//   60% {
//     opacity: 1;
//     -moz-transform: translateY(-30px);
//     transform: translateY(-30px); }

//   80% {
//     -moz-transform: translateY(10px);
//     transform: translateY(10px); }

//   100% {
//     -moz-transform: translateY(0);
//     transform: translateY(0); } }
// @-webkit-keyframes bounceInUp {
//   0% {
//     opacity: 0;
//     -webkit-transform: translateY(2000px);
//     transform: translateY(2000px); }

//   60% {
//     opacity: 1;
//     -webkit-transform: translateY(-30px);
//     transform: translateY(-30px); }

//   80% {
//     -webkit-transform: translateY(10px);
//     transform: translateY(10px); }

//   100% {
//     -webkit-transform: translateY(0);
//     transform: translateY(0); } }
// @keyframes bounceInUp {
//   0% {
//     opacity: 0;
//     -moz-transform: translateY(2000px);
//     -ms-transform: translateY(2000px);
//     -o-transform: translateY(2000px);
//     -webkit-transform: translateY(2000px);
//     transform: translateY(2000px); }

//   60% {
//     opacity: 1;
//     -moz-transform: translateY(-30px);
//     -ms-transform: translateY(-30px);
//     -o-transform: translateY(-30px);
//     -webkit-transform: translateY(-30px);
//     transform: translateY(-30px); }

//   80% {
//     -moz-transform: translateY(10px);
//     -ms-transform: translateY(10px);
//     -o-transform: translateY(10px);
//     -webkit-transform: translateY(10px);
//     transform: translateY(10px); }

//   100% {
//     -moz-transform: translateY(0);
//     -ms-transform: translateY(0);
//     -o-transform: translateY(0);
//     -webkit-transform: translateY(0);
//     transform: translateY(0); } }
// @-moz-keyframes bounceInDown {
//   0% {
//     opacity: 0;
//     -moz-transform: translateY(-2000px);
//     transform: translateY(-2000px); }

//   60% {
//     opacity: 1;
//     -moz-transform: translateY(30px);
//     transform: translateY(30px); }

//   80% {
//     -moz-transform: translateY(-10px);
//     transform: translateY(-10px); }

//   100% {
//     -moz-transform: translateY(0);
//     transform: translateY(0); } }
// @-webkit-keyframes bounceInDown {
//   0% {
//     opacity: 0;
//     -webkit-transform: translateY(-2000px);
//     transform: translateY(-2000px); }

//   60% {
//     opacity: 1;
//     -webkit-transform: translateY(30px);
//     transform: translateY(30px); }

//   80% {
//     -webkit-transform: translateY(-10px);
//     transform: translateY(-10px); }

//   100% {
//     -webkit-transform: translateY(0);
//     transform: translateY(0); } }
// @keyframes bounceInDown {
//   0% {
//     opacity: 0;
//     -moz-transform: translateY(-2000px);
//     -ms-transform: translateY(-2000px);
//     -o-transform: translateY(-2000px);
//     -webkit-transform: translateY(-2000px);
//     transform: translateY(-2000px); }

//   60% {
//     opacity: 1;
//     -moz-transform: translateY(30px);
//     -ms-transform: translateY(30px);
//     -o-transform: translateY(30px);
//     -webkit-transform: translateY(30px);
//     transform: translateY(30px); }

//   80% {
//     -moz-transform: translateY(-10px);
//     -ms-transform: translateY(-10px);
//     -o-transform: translateY(-10px);
//     -webkit-transform: translateY(-10px);
//     transform: translateY(-10px); }

//   100% {
//     -moz-transform: translateY(0);
//     -ms-transform: translateY(0);
//     -o-transform: translateY(0);
//     -webkit-transform: translateY(0);
//     transform: translateY(0); } }
// @-moz-keyframes bounceInLeft {
//   0% {
//     opacity: 0;
//     -moz-transform: translateX(-2000px);
//     transform: translateX(-2000px); }

//   60% {
//     opacity: 1;
//     -moz-transform: translateX(30px);
//     transform: translateX(30px); }

//   80% {
//     -moz-transform: translateX(-10px);
//     transform: translateX(-10px); }

//   100% {
//     -moz-transform: translateX(0);
//     transform: translateX(0); } }
// @-webkit-keyframes bounceInLeft {
//   0% {
//     opacity: 0;
//     -webkit-transform: translateX(-2000px);
//     transform: translateX(-2000px); }

//   60% {
//     opacity: 1;
//     -webkit-transform: translateX(30px);
//     transform: translateX(30px); }

//   80% {
//     -webkit-transform: translateX(-10px);
//     transform: translateX(-10px); }

//   100% {
//     -webkit-transform: translateX(0);
//     transform: translateX(0); } }
// @keyframes bounceInLeft {
//   0% {
//     opacity: 0;
//     -moz-transform: translateX(-2000px);
//     -ms-transform: translateX(-2000px);
//     -o-transform: translateX(-2000px);
//     -webkit-transform: translateX(-2000px);
//     transform: translateX(-2000px); }

//   60% {
//     opacity: 1;
//     -moz-transform: translateX(30px);
//     -ms-transform: translateX(30px);
//     -o-transform: translateX(30px);
//     -webkit-transform: translateX(30px);
//     transform: translateX(30px); }

//   80% {
//     -moz-transform: translateX(-10px);
//     -ms-transform: translateX(-10px);
//     -o-transform: translateX(-10px);
//     -webkit-transform: translateX(-10px);
//     transform: translateX(-10px); }

//   100% {
//     -moz-transform: translateX(0);
//     -ms-transform: translateX(0);
//     -o-transform: translateX(0);
//     -webkit-transform: translateX(0);
//     transform: translateX(0); } }
// @-moz-keyframes bounceInRight {
//   0% {
//     opacity: 0;
//     -moz-transform: translateX(2000px);
//     transform: translateX(2000px); }

//   60% {
//     opacity: 1;
//     -moz-transform: translateX(-30px);
//     transform: translateX(-30px); }

//   80% {
//     -moz-transform: translateX(10px);
//     transform: translateX(10px); }

//   100% {
//     -moz-transform: translateX(0);
//     transform: translateX(0); } }
// @-webkit-keyframes bounceInRight {
//   0% {
//     opacity: 0;
//     -webkit-transform: translateX(2000px);
//     transform: translateX(2000px); }

//   60% {
//     opacity: 1;
//     -webkit-transform: translateX(-30px);
//     transform: translateX(-30px); }

//   80% {
//     -webkit-transform: translateX(10px);
//     transform: translateX(10px); }

//   100% {
//     -webkit-transform: translateX(0);
//     transform: translateX(0); } }
// @keyframes bounceInRight {
//   0% {
//     opacity: 0;
//     -moz-transform: translateX(2000px);
//     -ms-transform: translateX(2000px);
//     -o-transform: translateX(2000px);
//     -webkit-transform: translateX(2000px);
//     transform: translateX(2000px); }

//   60% {
//     opacity: 1;
//     -moz-transform: translateX(-30px);
//     -ms-transform: translateX(-30px);
//     -o-transform: translateX(-30px);
//     -webkit-transform: translateX(-30px);
//     transform: translateX(-30px); }

//   80% {
//     -moz-transform: translateX(10px);
//     -ms-transform: translateX(10px);
//     -o-transform: translateX(10px);
//     -webkit-transform: translateX(10px);
//     transform: translateX(10px); }

//   100% {
//     -moz-transform: translateX(0);
//     -ms-transform: translateX(0);
//     -o-transform: translateX(0);
//     -webkit-transform: translateX(0);
//     transform: translateX(0); } }
// @-moz-keyframes bounceOut {
//   0% {
//     -moz-transform: scale(1, 1);
//     transform: scale(1, 1); }

//   25% {
//     -moz-transform: scale(0.95, 0.95);
//     transform: scale(0.95, 0.95); }

//   50% {
//     opacity: 1;
//     -moz-transform: scale(1.1, 1.1);
//     transform: scale(1.1, 1.1); }

//   100% {
//     opacity: 0;
//     -moz-transform: scale(0.3, 0.3);
//     transform: scale(0.3, 0.3); } }
// @-webkit-keyframes bounceOut {
//   0% {
//     -webkit-transform: scale(1, 1);
//     transform: scale(1, 1); }

//   25% {
//     -webkit-transform: scale(0.95, 0.95);
//     transform: scale(0.95, 0.95); }

//   50% {
//     opacity: 1;
//     -webkit-transform: scale(1.1, 1.1);
//     transform: scale(1.1, 1.1); }

//   100% {
//     opacity: 0;
//     -webkit-transform: scale(0.3, 0.3);
//     transform: scale(0.3, 0.3); } }
// @keyframes bounceOut {
//   0% {
//     -moz-transform: scale(1, 1);
//     -ms-transform: scale(1, 1);
//     -o-transform: scale(1, 1);
//     -webkit-transform: scale(1, 1);
//     transform: scale(1, 1); }

//   25% {
//     -moz-transform: scale(0.95, 0.95);
//     -ms-transform: scale(0.95, 0.95);
//     -o-transform: scale(0.95, 0.95);
//     -webkit-transform: scale(0.95, 0.95);
//     transform: scale(0.95, 0.95); }

//   50% {
//     opacity: 1;
//     -moz-transform: scale(1.1, 1.1);
//     -ms-transform: scale(1.1, 1.1);
//     -o-transform: scale(1.1, 1.1);
//     -webkit-transform: scale(1.1, 1.1);
//     transform: scale(1.1, 1.1); }

//   100% {
//     opacity: 0;
//     -moz-transform: scale(0.3, 0.3);
//     -ms-transform: scale(0.3, 0.3);
//     -o-transform: scale(0.3, 0.3);
//     -webkit-transform: scale(0.3, 0.3);
//     transform: scale(0.3, 0.3); } }
// @-moz-keyframes bounceOutUp {
//   0% {
//     -moz-transform: translateY(0);
//     transform: translateY(0); }

//   20% {
//     opacity: 1;
//     -moz-transform: translateY(20px);
//     transform: translateY(20px); }

//   100% {
//     opacity: 0;
//     -moz-transform: translateY(-2000px);
//     transform: translateY(-2000px); } }
// @-webkit-keyframes bounceOutUp {
//   0% {
//     -webkit-transform: translateY(0);
//     transform: translateY(0); }

//   20% {
//     opacity: 1;
//     -webkit-transform: translateY(20px);
//     transform: translateY(20px); }

//   100% {
//     opacity: 0;
//     -webkit-transform: translateY(-2000px);
//     transform: translateY(-2000px); } }
// @keyframes bounceOutUp {
//   0% {
//     -moz-transform: translateY(0);
//     -ms-transform: translateY(0);
//     -o-transform: translateY(0);
//     -webkit-transform: translateY(0);
//     transform: translateY(0); }

//   20% {
//     opacity: 1;
//     -moz-transform: translateY(20px);
//     -ms-transform: translateY(20px);
//     -o-transform: translateY(20px);
//     -webkit-transform: translateY(20px);
//     transform: translateY(20px); }

//   100% {
//     opacity: 0;
//     -moz-transform: translateY(-2000px);
//     -ms-transform: translateY(-2000px);
//     -o-transform: translateY(-2000px);
//     -webkit-transform: translateY(-2000px);
//     transform: translateY(-2000px); } }
// @-moz-keyframes bounceOutDown {
//   0% {
//     -moz-transform: translateY(0);
//     transform: translateY(0); }

//   20% {
//     opacity: 1;
//     -moz-transform: translateY(-20px);
//     transform: translateY(-20px); }

//   100% {
//     opacity: 0;
//     -moz-transform: translateY(2000px);
//     transform: translateY(2000px); } }
// @-webkit-keyframes bounceOutDown {
//   0% {
//     -webkit-transform: translateY(0);
//     transform: translateY(0); }

//   20% {
//     opacity: 1;
//     -webkit-transform: translateY(-20px);
//     transform: translateY(-20px); }

//   100% {
//     opacity: 0;
//     -webkit-transform: translateY(2000px);
//     transform: translateY(2000px); } }
// @keyframes bounceOutDown {
//   0% {
//     -moz-transform: translateY(0);
//     -ms-transform: translateY(0);
//     -o-transform: translateY(0);
//     -webkit-transform: translateY(0);
//     transform: translateY(0); }

//   20% {
//     opacity: 1;
//     -moz-transform: translateY(-20px);
//     -ms-transform: translateY(-20px);
//     -o-transform: translateY(-20px);
//     -webkit-transform: translateY(-20px);
//     transform: translateY(-20px); }

//   100% {
//     opacity: 0;
//     -moz-transform: translateY(2000px);
//     -ms-transform: translateY(2000px);
//     -o-transform: translateY(2000px);
//     -webkit-transform: translateY(2000px);
//     transform: translateY(2000px); } }
// @-moz-keyframes bounceOutLeft {
//   0% {
//     -moz-transform: translateX(0);
//     transform: translateX(0); }

//   20% {
//     opacity: 1;
//     -moz-transform: translateX(20px);
//     transform: translateX(20px); }

//   100% {
//     opacity: 0;
//     -moz-transform: translateX(-2000px);
//     transform: translateX(-2000px); } }
// @-webkit-keyframes bounceOutLeft {
//   0% {
//     -webkit-transform: translateX(0);
//     transform: translateX(0); }

//   20% {
//     opacity: 1;
//     -webkit-transform: translateX(20px);
//     transform: translateX(20px); }

//   100% {
//     opacity: 0;
//     -webkit-transform: translateX(-2000px);
//     transform: translateX(-2000px); } }
// @keyframes bounceOutLeft {
//   0% {
//     -moz-transform: translateX(0);
//     -ms-transform: translateX(0);
//     -o-transform: translateX(0);
//     -webkit-transform: translateX(0);
//     transform: translateX(0); }

//   20% {
//     opacity: 1;
//     -moz-transform: translateX(20px);
//     -ms-transform: translateX(20px);
//     -o-transform: translateX(20px);
//     -webkit-transform: translateX(20px);
//     transform: translateX(20px); }

//   100% {
//     opacity: 0;
//     -moz-transform: translateX(-2000px);
//     -ms-transform: translateX(-2000px);
//     -o-transform: translateX(-2000px);
//     -webkit-transform: translateX(-2000px);
//     transform: translateX(-2000px); } }
// @-moz-keyframes bounceOutRight {
//   0% {
//     -moz-transform: translateX(0);
//     transform: translateX(0); }

//   20% {
//     opacity: 1;
//     -moz-transform: translateX(-20px);
//     transform: translateX(-20px); }

//   100% {
//     opacity: 0;
//     -moz-transform: translateX(2000px);
//     transform: translateX(2000px); } }
// @-webkit-keyframes bounceOutRight {
//   0% {
//     -webkit-transform: translateX(0);
//     transform: translateX(0); }

//   20% {
//     opacity: 1;
//     -webkit-transform: translateX(-20px);
//     transform: translateX(-20px); }

//   100% {
//     opacity: 0;
//     -webkit-transform: translateX(2000px);
//     transform: translateX(2000px); } }
// @keyframes bounceOutRight {
//   0% {
//     -moz-transform: translateX(0);
//     -ms-transform: translateX(0);
//     -o-transform: translateX(0);
//     -webkit-transform: translateX(0);
//     transform: translateX(0); }

//   20% {
//     opacity: 1;
//     -moz-transform: translateX(-20px);
//     -ms-transform: translateX(-20px);
//     -o-transform: translateX(-20px);
//     -webkit-transform: translateX(-20px);
//     transform: translateX(-20px); }

//   100% {
//     opacity: 0;
//     -moz-transform: translateX(2000px);
//     -ms-transform: translateX(2000px);
//     -o-transform: translateX(2000px);
//     -webkit-transform: translateX(2000px);
//     transform: translateX(2000px); } }
// @-moz-keyframes bounce {
//   0% {
//     -moz-transform: translateY(0);
//     transform: translateY(0); }

//   20% {
//     -moz-transform: translateY(0);
//     transform: translateY(0); }

//   40% {
//     -moz-transform: translateY(-30px);
//     transform: translateY(-30px); }

//   50% {
//     -moz-transform: translateY(0);
//     transform: translateY(0); }

//   60% {
//     -moz-transform: translateY(-15px);
//     transform: translateY(-15px); }

//   80% {
//     -moz-transform: translateY(0);
//     transform: translateY(0); }

//   100% {
//     -moz-transform: translateY(0);
//     transform: translateY(0); } }
// @-webkit-keyframes bounce {
//   0% {
//     -webkit-transform: translateY(0);
//     transform: translateY(0); }

//   20% {
//     -webkit-transform: translateY(0);
//     transform: translateY(0); }

//   40% {
//     -webkit-transform: translateY(-30px);
//     transform: translateY(-30px); }

//   50% {
//     -webkit-transform: translateY(0);
//     transform: translateY(0); }

//   60% {
//     -webkit-transform: translateY(-15px);
//     transform: translateY(-15px); }

//   80% {
//     -webkit-transform: translateY(0);
//     transform: translateY(0); }

//   100% {
//     -webkit-transform: translateY(0);
//     transform: translateY(0); } }
// @keyframes bounce {
//   0% {
//     -moz-transform: translateY(0);
//     -ms-transform: translateY(0);
//     -o-transform: translateY(0);
//     -webkit-transform: translateY(0);
//     transform: translateY(0); }

//   20% {
//     -moz-transform: translateY(0);
//     -ms-transform: translateY(0);
//     -o-transform: translateY(0);
//     -webkit-transform: translateY(0);
//     transform: translateY(0); }

//   40% {
//     -moz-transform: translateY(-30px);
//     -ms-transform: translateY(-30px);
//     -o-transform: translateY(-30px);
//     -webkit-transform: translateY(-30px);
//     transform: translateY(-30px); }

//   50% {
//     -moz-transform: translateY(0);
//     -ms-transform: translateY(0);
//     -o-transform: translateY(0);
//     -webkit-transform: translateY(0);
//     transform: translateY(0); }

//   60% {
//     -moz-transform: translateY(-15px);
//     -ms-transform: translateY(-15px);
//     -o-transform: translateY(-15px);
//     -webkit-transform: translateY(-15px);
//     transform: translateY(-15px); }

//   80% {
//     -moz-transform: translateY(0);
//     -ms-transform: translateY(0);
//     -o-transform: translateY(0);
//     -webkit-transform: translateY(0);
//     transform: translateY(0); }

//   100% {
//     -moz-transform: translateY(0);
//     -ms-transform: translateY(0);
//     -o-transform: translateY(0);
//     -webkit-transform: translateY(0);
//     transform: translateY(0); } }
@-moz-keyframes fadeIn {
  0% {
    opacity: 0; }

  100% {
    opacity: 1; } }
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }

  100% {
    opacity: 1; } }
@keyframes fadeIn {
  0% {
    opacity: 0; }

  100% {
    opacity: 1; } }
@-moz-keyframes fadeInUp {
  0% {
    -moz-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0; }

  100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }
@-webkit-keyframes fadeInUp {
  0% {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0; }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }
@keyframes fadeInUp {
  0% {
    -moz-transform: translateY(20px);
    -ms-transform: translateY(20px);
    -o-transform: translateY(20px);
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    opacity: 0; }

  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }
@-moz-keyframes fadeInDown {
  0% {
    -moz-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0; }

  100% {
    -moz-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }
@-webkit-keyframes fadeInDown {
  0% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0; }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }
@keyframes fadeInDown {
  0% {
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0; }

  100% {
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }
// @-moz-keyframes fadeInLeft {
//   0% {
//     -moz-transform: translateX(-20px);
//     transform: translateX(-20px);
//     opacity: 0; }

//   100% {
//     -moz-transform: translateX(0);
//     transform: translateX(0);
//     opacity: 1; } }
// @-webkit-keyframes fadeInLeft {
//   0% {
//     -webkit-transform: translateX(-20px);
//     transform: translateX(-20px);
//     opacity: 0; }

//   100% {
//     -webkit-transform: translateX(0);
//     transform: translateX(0);
//     opacity: 1; } }
// @keyframes fadeInLeft {
//   0% {
//     -moz-transform: translateX(-20px);
//     -ms-transform: translateX(-20px);
//     -o-transform: translateX(-20px);
//     -webkit-transform: translateX(-20px);
//     transform: translateX(-20px);
//     opacity: 0; }

//   100% {
//     -moz-transform: translateX(0);
//     -ms-transform: translateX(0);
//     -o-transform: translateX(0);
//     -webkit-transform: translateX(0);
//     transform: translateX(0);
//     opacity: 1; } }
// @-moz-keyframes fadeInRight {
//   0% {
//     -moz-transform: translateX(20px);
//     transform: translateX(20px);
//     opacity: 0; }

//   100% {
//     -moz-transform: translateX(0);
//     transform: translateX(0);
//     opacity: 1; } }
// @-webkit-keyframes fadeInRight {
//   0% {
//     -webkit-transform: translateX(20px);
//     transform: translateX(20px);
//     opacity: 0; }

//   100% {
//     -webkit-transform: translateX(0);
//     transform: translateX(0);
//     opacity: 1; } }
// @keyframes fadeInRight {
//   0% {
//     -moz-transform: translateX(20px);
//     -ms-transform: translateX(20px);
//     -o-transform: translateX(20px);
//     -webkit-transform: translateX(20px);
//     transform: translateX(20px);
//     opacity: 0; }

//   100% {
//     -moz-transform: translateX(0);
//     -ms-transform: translateX(0);
//     -o-transform: translateX(0);
//     -webkit-transform: translateX(0);
//     transform: translateX(0);
//     opacity: 1; } }
// @-moz-keyframes fadeInUpBig {
//   0% {
//     -moz-transform: translateY(2000px);
//     transform: translateY(2000px);
//     opacity: 0; }

//   100% {
//     -moz-transform: translateY(0);
//     transform: translateY(0);
//     opacity: 1; } }
// @-webkit-keyframes fadeInUpBig {
//   0% {
//     -webkit-transform: translateY(2000px);
//     transform: translateY(2000px);
//     opacity: 0; }

//   100% {
//     -webkit-transform: translateY(0);
//     transform: translateY(0);
//     opacity: 1; } }
// @keyframes fadeInUpBig {
//   0% {
//     -moz-transform: translateY(2000px);
//     -ms-transform: translateY(2000px);
//     -o-transform: translateY(2000px);
//     -webkit-transform: translateY(2000px);
//     transform: translateY(2000px);
//     opacity: 0; }

//   100% {
//     -moz-transform: translateY(0);
//     -ms-transform: translateY(0);
//     -o-transform: translateY(0);
//     -webkit-transform: translateY(0);
//     transform: translateY(0);
//     opacity: 1; } }
// @-moz-keyframes fadeInDownBig {
//   0% {
//     opacity: 0;
//     -moz-transform: translateY(-2000px);
//     transform: translateY(-2000px); }

//   100% {
//     opacity: 1;
//     -moz-transform: translateY(0);
//     transform: translateY(0); } }
// @-webkit-keyframes fadeInDownBig {
//   0% {
//     opacity: 0;
//     -webkit-transform: translateY(-2000px);
//     transform: translateY(-2000px); }

//   100% {
//     opacity: 1;
//     -webkit-transform: translateY(0);
//     transform: translateY(0); } }
// @keyframes fadeInDownBig {
//   0% {
//     opacity: 0;
//     -moz-transform: translateY(-2000px);
//     -ms-transform: translateY(-2000px);
//     -o-transform: translateY(-2000px);
//     -webkit-transform: translateY(-2000px);
//     transform: translateY(-2000px); }

//   100% {
//     opacity: 1;
//     -moz-transform: translateY(0);
//     -ms-transform: translateY(0);
//     -o-transform: translateY(0);
//     -webkit-transform: translateY(0);
//     transform: translateY(0); } }
// @-moz-keyframes fadeInLeftBig {
//   0% {
//     opacity: 0;
//     -moz-transform: translateX(-2000px);
//     transform: translateX(-2000px); }

//   100% {
//     opacity: 1;
//     -moz-transform: translateX(0);
//     transform: translateX(0); } }
// @-webkit-keyframes fadeInLeftBig {
//   0% {
//     opacity: 0;
//     -webkit-transform: translateX(-2000px);
//     transform: translateX(-2000px); }

//   100% {
//     opacity: 1;
//     -webkit-transform: translateX(0);
//     transform: translateX(0); } }
// @keyframes fadeInLeftBig {
//   0% {
//     opacity: 0;
//     -moz-transform: translateX(-2000px);
//     -ms-transform: translateX(-2000px);
//     -o-transform: translateX(-2000px);
//     -webkit-transform: translateX(-2000px);
//     transform: translateX(-2000px); }

//   100% {
//     opacity: 1;
//     -moz-transform: translateX(0);
//     -ms-transform: translateX(0);
//     -o-transform: translateX(0);
//     -webkit-transform: translateX(0);
//     transform: translateX(0); } }
// @-moz-keyframes fadeInRightBig {
//   0% {
//     opacity: 0;
//     -moz-transform: translateX(2000px);
//     transform: translateX(2000px); }

//   100% {
//     opacity: 1;
//     -moz-transform: translateX(0);
//     transform: translateX(0); } }
// @-webkit-keyframes fadeInRightBig {
//   0% {
//     opacity: 0;
//     -webkit-transform: translateX(2000px);
//     transform: translateX(2000px); }

//   100% {
//     opacity: 1;
//     -webkit-transform: translateX(0);
//     transform: translateX(0); } }
// @keyframes fadeInRightBig {
//   0% {
//     opacity: 0;
//     -moz-transform: translateX(2000px);
//     -ms-transform: translateX(2000px);
//     -o-transform: translateX(2000px);
//     -webkit-transform: translateX(2000px);
//     transform: translateX(2000px); }

//   100% {
//     opacity: 1;
//     -moz-transform: translateX(0);
//     -ms-transform: translateX(0);
//     -o-transform: translateX(0);
//     -webkit-transform: translateX(0);
//     transform: translateX(0); } }
@-moz-keyframes fadeOut {
  0% {
    opacity: 1; }

  100% {
    opacity: 0; } }
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }

  100% {
    opacity: 0; } }
@keyframes fadeOut {
  0% {
    opacity: 1; }

  100% {
    opacity: 0; } }
// @-moz-keyframes fadeOutUp {
//   0% {
//     -moz-transform: translateY(0);
//     transform: translateY(0);
//     opacity: 1; }

//   100% {
//     -moz-transform: translateY(-20px);
//     transform: translateY(-20px);
//     opacity: 0; } }
// @-webkit-keyframes fadeOutUp {
//   0% {
//     -webkit-transform: translateY(0);
//     transform: translateY(0);
//     opacity: 1; }

//   100% {
//     -webkit-transform: translateY(-20px);
//     transform: translateY(-20px);
//     opacity: 0; } }
// @keyframes fadeOutUp {
//   0% {
//     -moz-transform: translateY(0);
//     -ms-transform: translateY(0);
//     -o-transform: translateY(0);
//     -webkit-transform: translateY(0);
//     transform: translateY(0);
//     opacity: 1; }

//   100% {
//     -moz-transform: translateY(-20px);
//     -ms-transform: translateY(-20px);
//     -o-transform: translateY(-20px);
//     -webkit-transform: translateY(-20px);
//     transform: translateY(-20px);
//     opacity: 0; } }
// @-moz-keyframes fadeOutDown {
//   0% {
//     -moz-transform: translateY(0);
//     transform: translateY(0);
//     opacity: 1; }

//   100% {
//     -moz-transform: translateY(20px);
//     transform: translateY(20px);
//     opacity: 0; } }
// @-webkit-keyframes fadeOutDown {
//   0% {
//     -webkit-transform: translateY(0);
//     transform: translateY(0);
//     opacity: 1; }

//   100% {
//     -webkit-transform: translateY(20px);
//     transform: translateY(20px);
//     opacity: 0; } }
// @keyframes fadeOutDown {
//   0% {
//     -moz-transform: translateY(0);
//     -ms-transform: translateY(0);
//     -o-transform: translateY(0);
//     -webkit-transform: translateY(0);
//     transform: translateY(0);
//     opacity: 1; }

//   100% {
//     -moz-transform: translateY(20px);
//     -ms-transform: translateY(20px);
//     -o-transform: translateY(20px);
//     -webkit-transform: translateY(20px);
//     transform: translateY(20px);
//     opacity: 0; } }
// @-moz-keyframes fadeOutLeft {
//   0% {
//     -moz-transform: translateX(0);
//     transform: translateX(0);
//     opacity: 1; }

//   100% {
//     -moz-transform: translateX(-20px);
//     transform: translateX(-20px);
//     opacity: 0; } }
// @-webkit-keyframes fadeOutLeft {
//   0% {
//     -webkit-transform: translateX(0);
//     transform: translateX(0);
//     opacity: 1; }

//   100% {
//     -webkit-transform: translateX(-20px);
//     transform: translateX(-20px);
//     opacity: 0; } }
// @keyframes fadeOutLeft {
//   0% {
//     -moz-transform: translateX(0);
//     -ms-transform: translateX(0);
//     -o-transform: translateX(0);
//     -webkit-transform: translateX(0);
//     transform: translateX(0);
//     opacity: 1; }

//   100% {
//     -moz-transform: translateX(-20px);
//     -ms-transform: translateX(-20px);
//     -o-transform: translateX(-20px);
//     -webkit-transform: translateX(-20px);
//     transform: translateX(-20px);
//     opacity: 0; } }
// @-moz-keyframes fadeOutRight {
//   0% {
//     -moz-transform: translateX(0);
//     transform: translateX(0);
//     opacity: 1; }

//   100% {
//     -moz-transform: translateX(20px);
//     transform: translateX(20px);
//     opacity: 0; } }
// @-webkit-keyframes fadeOutRight {
//   0% {
//     -webkit-transform: translateX(0);
//     transform: translateX(0);
//     opacity: 1; }

//   100% {
//     -webkit-transform: translateX(20px);
//     transform: translateX(20px);
//     opacity: 0; } }
// @keyframes fadeOutRight {
//   0% {
//     -moz-transform: translateX(0);
//     -ms-transform: translateX(0);
//     -o-transform: translateX(0);
//     -webkit-transform: translateX(0);
//     transform: translateX(0);
//     opacity: 1; }

//   100% {
//     -moz-transform: translateX(20px);
//     -ms-transform: translateX(20px);
//     -o-transform: translateX(20px);
//     -webkit-transform: translateX(20px);
//     transform: translateX(20px);
//     opacity: 0; } }
// @-moz-keyframes fadeOutUpBig {
//   0% {
//     -moz-transform: translateY(0);
//     transform: translateY(0);
//     opacity: 1; }

//   100% {
//     -moz-transform: translateY(-2000px);
//     transform: translateY(-2000px);
//     opacity: 0; } }
// @-webkit-keyframes fadeOutUpBig {
//   0% {
//     -webkit-transform: translateY(0);
//     transform: translateY(0);
//     opacity: 1; }

//   100% {
//     -webkit-transform: translateY(-2000px);
//     transform: translateY(-2000px);
//     opacity: 0; } }
// @keyframes fadeOutUpBig {
//   0% {
//     -moz-transform: translateY(0);
//     -ms-transform: translateY(0);
//     -o-transform: translateY(0);
//     -webkit-transform: translateY(0);
//     transform: translateY(0);
//     opacity: 1; }

//   100% {
//     -moz-transform: translateY(-2000px);
//     -ms-transform: translateY(-2000px);
//     -o-transform: translateY(-2000px);
//     -webkit-transform: translateY(-2000px);
//     transform: translateY(-2000px);
//     opacity: 0; } }
// @-moz-keyframes fadeOutDownBig {
//   0% {
//     opacity: 1;
//     -moz-transform: translateY(0);
//     transform: translateY(0); }

//   100% {
//     opacity: 0;
//     -moz-transform: translateY(2000px);
//     transform: translateY(2000px); } }
// @-webkit-keyframes fadeOutDownBig {
//   0% {
//     opacity: 1;
//     -webkit-transform: translateY(0);
//     transform: translateY(0); }

//   100% {
//     opacity: 0;
//     -webkit-transform: translateY(2000px);
//     transform: translateY(2000px); } }
// @keyframes fadeOutDownBig {
//   0% {
//     opacity: 1;
//     -moz-transform: translateY(0);
//     -ms-transform: translateY(0);
//     -o-transform: translateY(0);
//     -webkit-transform: translateY(0);
//     transform: translateY(0); }

//   100% {
//     opacity: 0;
//     -moz-transform: translateY(2000px);
//     -ms-transform: translateY(2000px);
//     -o-transform: translateY(2000px);
//     -webkit-transform: translateY(2000px);
//     transform: translateY(2000px); } }
// @-moz-keyframes fadeOutLeftBig {
//   0% {
//     opacity: 1;
//     -moz-transform: translateX(0);
//     transform: translateX(0); }

//   100% {
//     opacity: 0;
//     -moz-transform: translateX(-2000px);
//     transform: translateX(-2000px); } }
// @-webkit-keyframes fadeOutLeftBig {
//   0% {
//     opacity: 1;
//     -webkit-transform: translateX(0);
//     transform: translateX(0); }

//   100% {
//     opacity: 0;
//     -webkit-transform: translateX(-2000px);
//     transform: translateX(-2000px); } }
// @keyframes fadeOutLeftBig {
//   0% {
//     opacity: 1;
//     -moz-transform: translateX(0);
//     -ms-transform: translateX(0);
//     -o-transform: translateX(0);
//     -webkit-transform: translateX(0);
//     transform: translateX(0); }

//   100% {
//     opacity: 0;
//     -moz-transform: translateX(-2000px);
//     -ms-transform: translateX(-2000px);
//     -o-transform: translateX(-2000px);
//     -webkit-transform: translateX(-2000px);
//     transform: translateX(-2000px); } }
// @-moz-keyframes fadeOutRightBig {
//   0% {
//     opacity: 1;
//     -moz-transform: translateX(0);
//     transform: translateX(0); }

//   100% {
//     opacity: 0;
//     -moz-transform: translateX(2000px);
//     transform: translateX(2000px); } }
// @-webkit-keyframes fadeOutRightBig {
//   0% {
//     opacity: 1;
//     -webkit-transform: translateX(0);
//     transform: translateX(0); }

//   100% {
//     opacity: 0;
//     -webkit-transform: translateX(2000px);
//     transform: translateX(2000px); } }
// @keyframes fadeOutRightBig {
//   0% {
//     opacity: 1;
//     -moz-transform: translateX(0);
//     -ms-transform: translateX(0);
//     -o-transform: translateX(0);
//     -webkit-transform: translateX(0);
//     transform: translateX(0); }

//   100% {
//     opacity: 0;
//     -moz-transform: translateX(2000px);
//     -ms-transform: translateX(2000px);
//     -o-transform: translateX(2000px);
//     -webkit-transform: translateX(2000px);
//     transform: translateX(2000px); } }
// @-moz-keyframes flipInX {
//   0% {
//     -moz-transform: perspective(400px) rotateX(90deg);
//     transform: perspective(400px) rotateX(90deg);
//     opacity: 0; }

//   40% {
//     -moz-transform: perspective(400px) rotateX(-10deg);
//     transform: perspective(400px) rotateX(-10deg); }

//   70% {
//     -moz-transform: perspective(400px) rotateX(10deg);
//     transform: perspective(400px) rotateX(10deg); }

//   100% {
//     -moz-transform: perspective(400px) rotateX(0deg);
//     transform: perspective(400px) rotateX(0deg);
//     opacity: 1; } }
// @-webkit-keyframes flipInX {
//   0% {
//     -webkit-transform: perspective(400px) rotateX(90deg);
//     transform: perspective(400px) rotateX(90deg);
//     opacity: 0; }

//   40% {
//     -webkit-transform: perspective(400px) rotateX(-10deg);
//     transform: perspective(400px) rotateX(-10deg); }

//   70% {
//     -webkit-transform: perspective(400px) rotateX(10deg);
//     transform: perspective(400px) rotateX(10deg); }

//   100% {
//     -webkit-transform: perspective(400px) rotateX(0deg);
//     transform: perspective(400px) rotateX(0deg);
//     opacity: 1; } }
// @keyframes flipInX {
//   0% {
//     -moz-transform: perspective(400px) rotateX(90deg);
//     -ms-transform: perspective(400px) rotateX(90deg);
//     -o-transform: perspective(400px) rotateX(90deg);
//     -webkit-transform: perspective(400px) rotateX(90deg);
//     transform: perspective(400px) rotateX(90deg);
//     opacity: 0; }

//   40% {
//     -moz-transform: perspective(400px) rotateX(-10deg);
//     -ms-transform: perspective(400px) rotateX(-10deg);
//     -o-transform: perspective(400px) rotateX(-10deg);
//     -webkit-transform: perspective(400px) rotateX(-10deg);
//     transform: perspective(400px) rotateX(-10deg); }

//   70% {
//     -moz-transform: perspective(400px) rotateX(10deg);
//     -ms-transform: perspective(400px) rotateX(10deg);
//     -o-transform: perspective(400px) rotateX(10deg);
//     -webkit-transform: perspective(400px) rotateX(10deg);
//     transform: perspective(400px) rotateX(10deg); }

//   100% {
//     -moz-transform: perspective(400px) rotateX(0deg);
//     -ms-transform: perspective(400px) rotateX(0deg);
//     -o-transform: perspective(400px) rotateX(0deg);
//     -webkit-transform: perspective(400px) rotateX(0deg);
//     transform: perspective(400px) rotateX(0deg);
//     opacity: 1; } }
// @-moz-keyframes flipInY {
//   0% {
//     -moz-transform: perspective(400px) rotateY(90deg);
//     transform: perspective(400px) rotateY(90deg);
//     opacity: 0; }

//   40% {
//     -moz-transform: perspective(400px) rotateY(-10deg);
//     transform: perspective(400px) rotateY(-10deg); }

//   70% {
//     -moz-transform: perspective(400px) rotateY(10deg);
//     transform: perspective(400px) rotateY(10deg); }

//   100% {
//     -moz-transform: perspective(400px) rotateY(0deg);
//     transform: perspective(400px) rotateY(0deg);
//     opacity: 1; } }
// @-webkit-keyframes flipInY {
//   0% {
//     -webkit-transform: perspective(400px) rotateY(90deg);
//     transform: perspective(400px) rotateY(90deg);
//     opacity: 0; }

//   40% {
//     -webkit-transform: perspective(400px) rotateY(-10deg);
//     transform: perspective(400px) rotateY(-10deg); }

//   70% {
//     -webkit-transform: perspective(400px) rotateY(10deg);
//     transform: perspective(400px) rotateY(10deg); }

//   100% {
//     -webkit-transform: perspective(400px) rotateY(0deg);
//     transform: perspective(400px) rotateY(0deg);
//     opacity: 1; } }
// @keyframes flipInY {
//   0% {
//     -moz-transform: perspective(400px) rotateY(90deg);
//     -ms-transform: perspective(400px) rotateY(90deg);
//     -o-transform: perspective(400px) rotateY(90deg);
//     -webkit-transform: perspective(400px) rotateY(90deg);
//     transform: perspective(400px) rotateY(90deg);
//     opacity: 0; }

//   40% {
//     -moz-transform: perspective(400px) rotateY(-10deg);
//     -ms-transform: perspective(400px) rotateY(-10deg);
//     -o-transform: perspective(400px) rotateY(-10deg);
//     -webkit-transform: perspective(400px) rotateY(-10deg);
//     transform: perspective(400px) rotateY(-10deg); }

//   70% {
//     -moz-transform: perspective(400px) rotateY(10deg);
//     -ms-transform: perspective(400px) rotateY(10deg);
//     -o-transform: perspective(400px) rotateY(10deg);
//     -webkit-transform: perspective(400px) rotateY(10deg);
//     transform: perspective(400px) rotateY(10deg); }

//   100% {
//     -moz-transform: perspective(400px) rotateY(0deg);
//     -ms-transform: perspective(400px) rotateY(0deg);
//     -o-transform: perspective(400px) rotateY(0deg);
//     -webkit-transform: perspective(400px) rotateY(0deg);
//     transform: perspective(400px) rotateY(0deg);
//     opacity: 1; } }
// @-moz-keyframes flipOutX {
//   0% {
//     -moz-transform: perspective(400px) rotateX(0deg);
//     transform: perspective(400px) rotateX(0deg);
//     opacity: 1; }

//   100% {
//     -moz-transform: perspective(400px) rotateX(90deg);
//     transform: perspective(400px) rotateX(90deg);
//     opacity: 0; } }
// @-webkit-keyframes flipOutX {
//   0% {
//     -webkit-transform: perspective(400px) rotateX(0deg);
//     transform: perspective(400px) rotateX(0deg);
//     opacity: 1; }

//   100% {
//     -webkit-transform: perspective(400px) rotateX(90deg);
//     transform: perspective(400px) rotateX(90deg);
//     opacity: 0; } }
// @keyframes flipOutX {
//   0% {
//     -moz-transform: perspective(400px) rotateX(0deg);
//     -ms-transform: perspective(400px) rotateX(0deg);
//     -o-transform: perspective(400px) rotateX(0deg);
//     -webkit-transform: perspective(400px) rotateX(0deg);
//     transform: perspective(400px) rotateX(0deg);
//     opacity: 1; }

//   100% {
//     -moz-transform: perspective(400px) rotateX(90deg);
//     -ms-transform: perspective(400px) rotateX(90deg);
//     -o-transform: perspective(400px) rotateX(90deg);
//     -webkit-transform: perspective(400px) rotateX(90deg);
//     transform: perspective(400px) rotateX(90deg);
//     opacity: 0; } }
// @-moz-keyframes flipOutY {
//   0% {
//     -moz-transform: perspective(400px) rotateY(0deg);
//     transform: perspective(400px) rotateY(0deg);
//     opacity: 1; }

//   100% {
//     -moz-transform: perspective(400px) rotateY(90deg);
//     transform: perspective(400px) rotateY(90deg);
//     opacity: 0; } }
// @-webkit-keyframes flipOutY {
//   0% {
//     -webkit-transform: perspective(400px) rotateY(0deg);
//     transform: perspective(400px) rotateY(0deg);
//     opacity: 1; }

//   100% {
//     -webkit-transform: perspective(400px) rotateY(90deg);
//     transform: perspective(400px) rotateY(90deg);
//     opacity: 0; } }
// @keyframes flipOutY {
//   0% {
//     -moz-transform: perspective(400px) rotateY(0deg);
//     -ms-transform: perspective(400px) rotateY(0deg);
//     -o-transform: perspective(400px) rotateY(0deg);
//     -webkit-transform: perspective(400px) rotateY(0deg);
//     transform: perspective(400px) rotateY(0deg);
//     opacity: 1; }

//   100% {
//     -moz-transform: perspective(400px) rotateY(90deg);
//     -ms-transform: perspective(400px) rotateY(90deg);
//     -o-transform: perspective(400px) rotateY(90deg);
//     -webkit-transform: perspective(400px) rotateY(90deg);
//     transform: perspective(400px) rotateY(90deg);
//     opacity: 0; } }
// @-moz-keyframes flip {
//   0% {
//     -moz-transform: perspective(400px) rotateY(0);
//     transform: perspective(400px) rotateY(0);
//     -moz-animation-timing-function: ease-out;
//     animation-timing-function: ease-out; }

//   40% {
//     -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg);
//     transform: perspective(400px) translateZ(150px) rotateY(170deg);
//     -moz-animation-timing-function: ease-out;
//     animation-timing-function: ease-out; }

//   50% {
//     -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
//     transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
//     -moz-animation-timing-function: ease-in;
//     animation-timing-function: ease-in; }

//   80% {
//     -moz-transform: perspective(400px) rotateY(360deg) scale(0.95);
//     transform: perspective(400px) rotateY(360deg) scale(0.95);
//     -moz-animation-timing-function: ease-in;
//     animation-timing-function: ease-in; }

//   100% {
//     -moz-transform: perspective(400px) scale(1);
//     transform: perspective(400px) scale(1);
//     -moz-animation-timing-function: ease-in;
//     animation-timing-function: ease-in; } }
// @-webkit-keyframes flip {
//   0% {
//     -webkit-transform: perspective(400px) rotateY(0);
//     transform: perspective(400px) rotateY(0);
//     -webkit-animation-timing-function: ease-out;
//     animation-timing-function: ease-out; }

//   40% {
//     -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
//     transform: perspective(400px) translateZ(150px) rotateY(170deg);
//     -webkit-animation-timing-function: ease-out;
//     animation-timing-function: ease-out; }

//   50% {
//     -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
//     transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
//     -webkit-animation-timing-function: ease-in;
//     animation-timing-function: ease-in; }

//   80% {
//     -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
//     transform: perspective(400px) rotateY(360deg) scale(0.95);
//     -webkit-animation-timing-function: ease-in;
//     animation-timing-function: ease-in; }

//   100% {
//     -webkit-transform: perspective(400px) scale(1);
//     transform: perspective(400px) scale(1);
//     -webkit-animation-timing-function: ease-in;
//     animation-timing-function: ease-in; } }
// @keyframes flip {
//   0% {
//     -moz-transform: perspective(400px) rotateY(0);
//     -ms-transform: perspective(400px) rotateY(0);
//     -o-transform: perspective(400px) rotateY(0);
//     -webkit-transform: perspective(400px) rotateY(0);
//     transform: perspective(400px) rotateY(0);
//     -moz-animation-timing-function: ease-out;
//     -webkit-animation-timing-function: ease-out;
//     animation-timing-function: ease-out; }

//   40% {
//     -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg);
//     -ms-transform: perspective(400px) translateZ(150px) rotateY(170deg);
//     -o-transform: perspective(400px) translateZ(150px) rotateY(170deg);
//     -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
//     transform: perspective(400px) translateZ(150px) rotateY(170deg);
//     -moz-animation-timing-function: ease-out;
//     -webkit-animation-timing-function: ease-out;
//     animation-timing-function: ease-out; }

//   50% {
//     -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
//     -ms-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
//     -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
//     -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
//     transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
//     -moz-animation-timing-function: ease-in;
//     -webkit-animation-timing-function: ease-in;
//     animation-timing-function: ease-in; }

//   80% {
//     -moz-transform: perspective(400px) rotateY(360deg) scale(0.95);
//     -ms-transform: perspective(400px) rotateY(360deg) scale(0.95);
//     -o-transform: perspective(400px) rotateY(360deg) scale(0.95);
//     -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
//     transform: perspective(400px) rotateY(360deg) scale(0.95);
//     -moz-animation-timing-function: ease-in;
//     -webkit-animation-timing-function: ease-in;
//     animation-timing-function: ease-in; }

//   100% {
//     -moz-transform: perspective(400px) scale(1);
//     -ms-transform: perspective(400px) scale(1);
//     -o-transform: perspective(400px) scale(1);
//     -webkit-transform: perspective(400px) scale(1);
//     transform: perspective(400px) scale(1);
//     -moz-animation-timing-function: ease-in;
//     -webkit-animation-timing-function: ease-in;
//     animation-timing-function: ease-in; } }
// @-moz-keyframes lightSpeedIn {
//   0% {
//     -moz-transform: translateX(100%) skewX(-30deg);
//     transform: translateX(100%) skewX(-30deg);
//     opacity: 0; }

//   60% {
//     -moz-transform: translateX(-20%) skewX(30deg);
//     transform: translateX(-20%) skewX(30deg);
//     opacity: 1; }

//   80% {
//     -moz-transform: translateX(0%) skewX(-15deg);
//     transform: translateX(0%) skewX(-15deg);
//     opacity: 1; }

//   100% {
//     -moz-transform: translateX(0%) skewX(0deg);
//     transform: translateX(0%) skewX(0deg);
//     opacity: 1; } }
// @-webkit-keyframes lightSpeedIn {
//   0% {
//     -webkit-transform: translateX(100%) skewX(-30deg);
//     transform: translateX(100%) skewX(-30deg);
//     opacity: 0; }

//   60% {
//     -webkit-transform: translateX(-20%) skewX(30deg);
//     transform: translateX(-20%) skewX(30deg);
//     opacity: 1; }

//   80% {
//     -webkit-transform: translateX(0%) skewX(-15deg);
//     transform: translateX(0%) skewX(-15deg);
//     opacity: 1; }

//   100% {
//     -webkit-transform: translateX(0%) skewX(0deg);
//     transform: translateX(0%) skewX(0deg);
//     opacity: 1; } }
// @keyframes lightSpeedIn {
//   0% {
//     -moz-transform: translateX(100%) skewX(-30deg);
//     -ms-transform: translateX(100%) skewX(-30deg);
//     -o-transform: translateX(100%) skewX(-30deg);
//     -webkit-transform: translateX(100%) skewX(-30deg);
//     transform: translateX(100%) skewX(-30deg);
//     opacity: 0; }

//   60% {
//     -moz-transform: translateX(-20%) skewX(30deg);
//     -ms-transform: translateX(-20%) skewX(30deg);
//     -o-transform: translateX(-20%) skewX(30deg);
//     -webkit-transform: translateX(-20%) skewX(30deg);
//     transform: translateX(-20%) skewX(30deg);
//     opacity: 1; }

//   80% {
//     -moz-transform: translateX(0%) skewX(-15deg);
//     -ms-transform: translateX(0%) skewX(-15deg);
//     -o-transform: translateX(0%) skewX(-15deg);
//     -webkit-transform: translateX(0%) skewX(-15deg);
//     transform: translateX(0%) skewX(-15deg);
//     opacity: 1; }

//   100% {
//     -moz-transform: translateX(0%) skewX(0deg);
//     -ms-transform: translateX(0%) skewX(0deg);
//     -o-transform: translateX(0%) skewX(0deg);
//     -webkit-transform: translateX(0%) skewX(0deg);
//     transform: translateX(0%) skewX(0deg);
//     opacity: 1; } }
// @-moz-keyframes lightSpeedOut {
//   0% {
//     -moz-transform: translateX(0%) skewX(0deg);
//     transform: translateX(0%) skewX(0deg);
//     opacity: 1; }

//   100% {
//     -moz-transform: translateX(100%) skewX(-30deg);
//     transform: translateX(100%) skewX(-30deg);
//     opacity: 0; } }
// @-webkit-keyframes lightSpeedOut {
//   0% {
//     -webkit-transform: translateX(0%) skewX(0deg);
//     transform: translateX(0%) skewX(0deg);
//     opacity: 1; }

//   100% {
//     -webkit-transform: translateX(100%) skewX(-30deg);
//     transform: translateX(100%) skewX(-30deg);
//     opacity: 0; } }
// @keyframes lightSpeedOut {
//   0% {
//     -moz-transform: translateX(0%) skewX(0deg);
//     -ms-transform: translateX(0%) skewX(0deg);
//     -o-transform: translateX(0%) skewX(0deg);
//     -webkit-transform: translateX(0%) skewX(0deg);
//     transform: translateX(0%) skewX(0deg);
//     opacity: 1; }

//   100% {
//     -moz-transform: translateX(100%) skewX(-30deg);
//     -ms-transform: translateX(100%) skewX(-30deg);
//     -o-transform: translateX(100%) skewX(-30deg);
//     -webkit-transform: translateX(100%) skewX(-30deg);
//     transform: translateX(100%) skewX(-30deg);
//     opacity: 0; } }
// @-moz-keyframes rollIn {
//   0% {
//     opacity: 0;
//     -moz-transform: translateX(-100%) rotate(-120deg);
//     transform: translateX(-100%) rotate(-120deg); }

//   100% {
//     opacity: 1;
//     -moz-transform: translateX(0px) rotate(0deg);
//     transform: translateX(0px) rotate(0deg); } }
// @-webkit-keyframes rollIn {
//   0% {
//     opacity: 0;
//     -webkit-transform: translateX(-100%) rotate(-120deg);
//     transform: translateX(-100%) rotate(-120deg); }

//   100% {
//     opacity: 1;
//     -webkit-transform: translateX(0px) rotate(0deg);
//     transform: translateX(0px) rotate(0deg); } }
// @keyframes rollIn {
//   0% {
//     opacity: 0;
//     -moz-transform: translateX(-100%) rotate(-120deg);
//     -ms-transform: translateX(-100%) rotate(-120deg);
//     -o-transform: translateX(-100%) rotate(-120deg);
//     -webkit-transform: translateX(-100%) rotate(-120deg);
//     transform: translateX(-100%) rotate(-120deg); }

//   100% {
//     opacity: 1;
//     -moz-transform: translateX(0px) rotate(0deg);
//     -ms-transform: translateX(0px) rotate(0deg);
//     -o-transform: translateX(0px) rotate(0deg);
//     -webkit-transform: translateX(0px) rotate(0deg);
//     transform: translateX(0px) rotate(0deg); } }
// @-moz-keyframes rollOut {
//   0% {
//     opacity: 1;
//     -moz-transform: translateX(0px) rotate(0deg);
//     transform: translateX(0px) rotate(0deg); }

//   100% {
//     opacity: 0;
//     -moz-transform: translateX(-100%) rotate(-120deg);
//     transform: translateX(-100%) rotate(-120deg); } }
// @-webkit-keyframes rollOut {
//   0% {
//     opacity: 1;
//     -webkit-transform: translateX(0px) rotate(0deg);
//     transform: translateX(0px) rotate(0deg); }

//   100% {
//     opacity: 0;
//     -webkit-transform: translateX(-100%) rotate(-120deg);
//     transform: translateX(-100%) rotate(-120deg); } }
// @keyframes rollOut {
//   0% {
//     opacity: 1;
//     -moz-transform: translateX(0px) rotate(0deg);
//     -ms-transform: translateX(0px) rotate(0deg);
//     -o-transform: translateX(0px) rotate(0deg);
//     -webkit-transform: translateX(0px) rotate(0deg);
//     transform: translateX(0px) rotate(0deg); }

//   100% {
//     opacity: 0;
//     -moz-transform: translateX(-100%) rotate(-120deg);
//     -ms-transform: translateX(-100%) rotate(-120deg);
//     -o-transform: translateX(-100%) rotate(-120deg);
//     -webkit-transform: translateX(-100%) rotate(-120deg);
//     transform: translateX(-100%) rotate(-120deg); } }
// @-moz-keyframes rotateIn {
//   0% {
//     -moz-transform-origin: center center;
//     transform-origin: center center;
//     -moz-transform: rotate(-200deg);
//     transform: rotate(-200deg);
//     opacity: 0; }

//   100% {
//     -moz-transform-origin: center center;
//     transform-origin: center center;
//     -moz-transform: rotate(0);
//     transform: rotate(0);
//     opacity: 1; } }
// @-webkit-keyframes rotateIn {
//   0% {
//     -webkit-transform-origin: center center;
//     transform-origin: center center;
//     -webkit-transform: rotate(-200deg);
//     transform: rotate(-200deg);
//     opacity: 0; }

//   100% {
//     -webkit-transform-origin: center center;
//     transform-origin: center center;
//     -webkit-transform: rotate(0);
//     transform: rotate(0);
//     opacity: 1; } }
// @keyframes rotateIn {
//   0% {
//     -moz-transform-origin: center center;
//     -ms-transform-origin: center center;
//     -o-transform-origin: center center;
//     -webkit-transform-origin: center center;
//     transform-origin: center center;
//     -moz-transform: rotate(-200deg);
//     -ms-transform: rotate(-200deg);
//     -o-transform: rotate(-200deg);
//     -webkit-transform: rotate(-200deg);
//     transform: rotate(-200deg);
//     opacity: 0; }

//   100% {
//     -moz-transform-origin: center center;
//     -ms-transform-origin: center center;
//     -o-transform-origin: center center;
//     -webkit-transform-origin: center center;
//     transform-origin: center center;
//     -moz-transform: rotate(0);
//     -ms-transform: rotate(0);
//     -o-transform: rotate(0);
//     -webkit-transform: rotate(0);
//     transform: rotate(0);
//     opacity: 1; } }
// @-moz-keyframes rotateInDownLeft {
//   0% {
//     -moz-transform-origin: left bottom;
//     transform-origin: left bottom;
//     -moz-transform: rotate(-90deg);
//     transform: rotate(-90deg);
//     opacity: 0; }

//   100% {
//     -moz-transform-origin: left bottom;
//     transform-origin: left bottom;
//     -moz-transform: rotate(0);
//     transform: rotate(0);
//     opacity: 1; } }
// @-webkit-keyframes rotateInDownLeft {
//   0% {
//     -webkit-transform-origin: left bottom;
//     transform-origin: left bottom;
//     -webkit-transform: rotate(-90deg);
//     transform: rotate(-90deg);
//     opacity: 0; }

//   100% {
//     -webkit-transform-origin: left bottom;
//     transform-origin: left bottom;
//     -webkit-transform: rotate(0);
//     transform: rotate(0);
//     opacity: 1; } }
// @keyframes rotateInDownLeft {
//   0% {
//     -moz-transform-origin: left bottom;
//     -ms-transform-origin: left bottom;
//     -o-transform-origin: left bottom;
//     -webkit-transform-origin: left bottom;
//     transform-origin: left bottom;
//     -moz-transform: rotate(-90deg);
//     -ms-transform: rotate(-90deg);
//     -o-transform: rotate(-90deg);
//     -webkit-transform: rotate(-90deg);
//     transform: rotate(-90deg);
//     opacity: 0; }

//   100% {
//     -moz-transform-origin: left bottom;
//     -ms-transform-origin: left bottom;
//     -o-transform-origin: left bottom;
//     -webkit-transform-origin: left bottom;
//     transform-origin: left bottom;
//     -moz-transform: rotate(0);
//     -ms-transform: rotate(0);
//     -o-transform: rotate(0);
//     -webkit-transform: rotate(0);
//     transform: rotate(0);
//     opacity: 1; } }
// @-moz-keyframes rotateInDownRight {
//   0% {
//     -moz-transform-origin: right bottom;
//     transform-origin: right bottom;
//     -moz-transform: rotate(90deg);
//     transform: rotate(90deg);
//     opacity: 0; }

//   100% {
//     -moz-transform-origin: right bottom;
//     transform-origin: right bottom;
//     -moz-transform: rotate(0);
//     transform: rotate(0);
//     opacity: 1; } }
// @-webkit-keyframes rotateInDownRight {
//   0% {
//     -webkit-transform-origin: right bottom;
//     transform-origin: right bottom;
//     -webkit-transform: rotate(90deg);
//     transform: rotate(90deg);
//     opacity: 0; }

//   100% {
//     -webkit-transform-origin: right bottom;
//     transform-origin: right bottom;
//     -webkit-transform: rotate(0);
//     transform: rotate(0);
//     opacity: 1; } }
// @keyframes rotateInDownRight {
//   0% {
//     -moz-transform-origin: right bottom;
//     -ms-transform-origin: right bottom;
//     -o-transform-origin: right bottom;
//     -webkit-transform-origin: right bottom;
//     transform-origin: right bottom;
//     -moz-transform: rotate(90deg);
//     -ms-transform: rotate(90deg);
//     -o-transform: rotate(90deg);
//     -webkit-transform: rotate(90deg);
//     transform: rotate(90deg);
//     opacity: 0; }

//   100% {
//     -moz-transform-origin: right bottom;
//     -ms-transform-origin: right bottom;
//     -o-transform-origin: right bottom;
//     -webkit-transform-origin: right bottom;
//     transform-origin: right bottom;
//     -moz-transform: rotate(0);
//     -ms-transform: rotate(0);
//     -o-transform: rotate(0);
//     -webkit-transform: rotate(0);
//     transform: rotate(0);
//     opacity: 1; } }
// @-moz-keyframes rotateInUpLeft {
//   0% {
//     -moz-transform-origin: left bottom;
//     transform-origin: left bottom;
//     -moz-transform: rotate(90deg);
//     transform: rotate(90deg);
//     opacity: 0; }

//   100% {
//     -moz-transform-origin: left bottom;
//     transform-origin: left bottom;
//     -moz-transform: rotate(0);
//     transform: rotate(0);
//     opacity: 1; } }
// @-webkit-keyframes rotateInUpLeft {
//   0% {
//     -webkit-transform-origin: left bottom;
//     transform-origin: left bottom;
//     -webkit-transform: rotate(90deg);
//     transform: rotate(90deg);
//     opacity: 0; }

//   100% {
//     -webkit-transform-origin: left bottom;
//     transform-origin: left bottom;
//     -webkit-transform: rotate(0);
//     transform: rotate(0);
//     opacity: 1; } }
// @keyframes rotateInUpLeft {
//   0% {
//     -moz-transform-origin: left bottom;
//     -ms-transform-origin: left bottom;
//     -o-transform-origin: left bottom;
//     -webkit-transform-origin: left bottom;
//     transform-origin: left bottom;
//     -moz-transform: rotate(90deg);
//     -ms-transform: rotate(90deg);
//     -o-transform: rotate(90deg);
//     -webkit-transform: rotate(90deg);
//     transform: rotate(90deg);
//     opacity: 0; }

//   100% {
//     -moz-transform-origin: left bottom;
//     -ms-transform-origin: left bottom;
//     -o-transform-origin: left bottom;
//     -webkit-transform-origin: left bottom;
//     transform-origin: left bottom;
//     -moz-transform: rotate(0);
//     -ms-transform: rotate(0);
//     -o-transform: rotate(0);
//     -webkit-transform: rotate(0);
//     transform: rotate(0);
//     opacity: 1; } }
// @-moz-keyframes rotateInUpRight {
//   0% {
//     -moz-transform-origin: right bottom;
//     transform-origin: right bottom;
//     -moz-transform: rotate(-90deg);
//     transform: rotate(-90deg);
//     opacity: 0; }

//   100% {
//     -moz-transform-origin: right bottom;
//     transform-origin: right bottom;
//     -moz-transform: rotate(0);
//     transform: rotate(0);
//     opacity: 1; } }
// @-webkit-keyframes rotateInUpRight {
//   0% {
//     -webkit-transform-origin: right bottom;
//     transform-origin: right bottom;
//     -webkit-transform: rotate(-90deg);
//     transform: rotate(-90deg);
//     opacity: 0; }

//   100% {
//     -webkit-transform-origin: right bottom;
//     transform-origin: right bottom;
//     -webkit-transform: rotate(0);
//     transform: rotate(0);
//     opacity: 1; } }
// @keyframes rotateInUpRight {
//   0% {
//     -moz-transform-origin: right bottom;
//     -ms-transform-origin: right bottom;
//     -o-transform-origin: right bottom;
//     -webkit-transform-origin: right bottom;
//     transform-origin: right bottom;
//     -moz-transform: rotate(-90deg);
//     -ms-transform: rotate(-90deg);
//     -o-transform: rotate(-90deg);
//     -webkit-transform: rotate(-90deg);
//     transform: rotate(-90deg);
//     opacity: 0; }

//   100% {
//     -moz-transform-origin: right bottom;
//     -ms-transform-origin: right bottom;
//     -o-transform-origin: right bottom;
//     -webkit-transform-origin: right bottom;
//     transform-origin: right bottom;
//     -moz-transform: rotate(0);
//     -ms-transform: rotate(0);
//     -o-transform: rotate(0);
//     -webkit-transform: rotate(0);
//     transform: rotate(0);
//     opacity: 1; } }
// @-moz-keyframes rotateOut {
//   0% {
//     -moz-transform-origin: center center;
//     transform-origin: center center;
//     -moz-transform: rotate(0);
//     transform: rotate(0);
//     opacity: 1; }

//   100% {
//     -moz-transform-origin: center center;
//     transform-origin: center center;
//     -moz-transform: rotate(200deg);
//     transform: rotate(200deg);
//     opacity: 0; } }
// @-webkit-keyframes rotateOut {
//   0% {
//     -webkit-transform-origin: center center;
//     transform-origin: center center;
//     -webkit-transform: rotate(0);
//     transform: rotate(0);
//     opacity: 1; }

//   100% {
//     -webkit-transform-origin: center center;
//     transform-origin: center center;
//     -webkit-transform: rotate(200deg);
//     transform: rotate(200deg);
//     opacity: 0; } }
// @keyframes rotateOut {
//   0% {
//     -moz-transform-origin: center center;
//     -ms-transform-origin: center center;
//     -o-transform-origin: center center;
//     -webkit-transform-origin: center center;
//     transform-origin: center center;
//     -moz-transform: rotate(0);
//     -ms-transform: rotate(0);
//     -o-transform: rotate(0);
//     -webkit-transform: rotate(0);
//     transform: rotate(0);
//     opacity: 1; }

//   100% {
//     -moz-transform-origin: center center;
//     -ms-transform-origin: center center;
//     -o-transform-origin: center center;
//     -webkit-transform-origin: center center;
//     transform-origin: center center;
//     -moz-transform: rotate(200deg);
//     -ms-transform: rotate(200deg);
//     -o-transform: rotate(200deg);
//     -webkit-transform: rotate(200deg);
//     transform: rotate(200deg);
//     opacity: 0; } }
// @-moz-keyframes rotateOutDownLeft {
//   0% {
//     -moz-transform-origin: left bottom;
//     transform-origin: left bottom;
//     -moz-transform: rotate(0);
//     transform: rotate(0);
//     opacity: 1; }

//   100% {
//     -moz-transform-origin: left bottom;
//     transform-origin: left bottom;
//     -moz-transform: rotate(90deg);
//     transform: rotate(90deg);
//     opacity: 0; } }
// @-webkit-keyframes rotateOutDownLeft {
//   0% {
//     -webkit-transform-origin: left bottom;
//     transform-origin: left bottom;
//     -webkit-transform: rotate(0);
//     transform: rotate(0);
//     opacity: 1; }

//   100% {
//     -webkit-transform-origin: left bottom;
//     transform-origin: left bottom;
//     -webkit-transform: rotate(90deg);
//     transform: rotate(90deg);
//     opacity: 0; } }
// @keyframes rotateOutDownLeft {
//   0% {
//     -moz-transform-origin: left bottom;
//     -ms-transform-origin: left bottom;
//     -o-transform-origin: left bottom;
//     -webkit-transform-origin: left bottom;
//     transform-origin: left bottom;
//     -moz-transform: rotate(0);
//     -ms-transform: rotate(0);
//     -o-transform: rotate(0);
//     -webkit-transform: rotate(0);
//     transform: rotate(0);
//     opacity: 1; }

//   100% {
//     -moz-transform-origin: left bottom;
//     -ms-transform-origin: left bottom;
//     -o-transform-origin: left bottom;
//     -webkit-transform-origin: left bottom;
//     transform-origin: left bottom;
//     -moz-transform: rotate(90deg);
//     -ms-transform: rotate(90deg);
//     -o-transform: rotate(90deg);
//     -webkit-transform: rotate(90deg);
//     transform: rotate(90deg);
//     opacity: 0; } }
// @-moz-keyframes rotateOutDownRight {
//   0% {
//     -moz-transform-origin: right bottom;
//     transform-origin: right bottom;
//     -moz-transform: rotate(0);
//     transform: rotate(0);
//     opacity: 1; }

//   100% {
//     -moz-transform-origin: right bottom;
//     transform-origin: right bottom;
//     -moz-transform: rotate(-90deg);
//     transform: rotate(-90deg);
//     opacity: 0; } }
// @-webkit-keyframes rotateOutDownRight {
//   0% {
//     -webkit-transform-origin: right bottom;
//     transform-origin: right bottom;
//     -webkit-transform: rotate(0);
//     transform: rotate(0);
//     opacity: 1; }

//   100% {
//     -webkit-transform-origin: right bottom;
//     transform-origin: right bottom;
//     -webkit-transform: rotate(-90deg);
//     transform: rotate(-90deg);
//     opacity: 0; } }
// @keyframes rotateOutDownRight {
//   0% {
//     -moz-transform-origin: right bottom;
//     -ms-transform-origin: right bottom;
//     -o-transform-origin: right bottom;
//     -webkit-transform-origin: right bottom;
//     transform-origin: right bottom;
//     -moz-transform: rotate(0);
//     -ms-transform: rotate(0);
//     -o-transform: rotate(0);
//     -webkit-transform: rotate(0);
//     transform: rotate(0);
//     opacity: 1; }

//   100% {
//     -moz-transform-origin: right bottom;
//     -ms-transform-origin: right bottom;
//     -o-transform-origin: right bottom;
//     -webkit-transform-origin: right bottom;
//     transform-origin: right bottom;
//     -moz-transform: rotate(-90deg);
//     -ms-transform: rotate(-90deg);
//     -o-transform: rotate(-90deg);
//     -webkit-transform: rotate(-90deg);
//     transform: rotate(-90deg);
//     opacity: 0; } }
// @-moz-keyframes rotateOutUpLeft {
//   0% {
//     -moz-transform-origin: left bottom;
//     transform-origin: left bottom;
//     -moz-transform: rotate(0);
//     transform: rotate(0);
//     opacity: 1; }

//   100% {
//     -moz-transform-origin: left bottom;
//     transform-origin: left bottom;
//     -moz-transform: rotate(-90deg);
//     transform: rotate(-90deg);
//     opacity: 0; } }
// @-webkit-keyframes rotateOutUpLeft {
//   0% {
//     -webkit-transform-origin: left bottom;
//     transform-origin: left bottom;
//     -webkit-transform: rotate(0);
//     transform: rotate(0);
//     opacity: 1; }

//   100% {
//     -webkit-transform-origin: left bottom;
//     transform-origin: left bottom;
//     -webkit-transform: rotate(-90deg);
//     transform: rotate(-90deg);
//     opacity: 0; } }
// @keyframes rotateOutUpLeft {
//   0% {
//     -moz-transform-origin: left bottom;
//     -ms-transform-origin: left bottom;
//     -o-transform-origin: left bottom;
//     -webkit-transform-origin: left bottom;
//     transform-origin: left bottom;
//     -moz-transform: rotate(0);
//     -ms-transform: rotate(0);
//     -o-transform: rotate(0);
//     -webkit-transform: rotate(0);
//     transform: rotate(0);
//     opacity: 1; }

//   100% {
//     -moz-transform-origin: left bottom;
//     -ms-transform-origin: left bottom;
//     -o-transform-origin: left bottom;
//     -webkit-transform-origin: left bottom;
//     transform-origin: left bottom;
//     -moz-transform: rotate(-90deg);
//     -ms-transform: rotate(-90deg);
//     -o-transform: rotate(-90deg);
//     -webkit-transform: rotate(-90deg);
//     transform: rotate(-90deg);
//     opacity: 0; } }
// @-moz-keyframes rotateOutUpRight {
//   0% {
//     -moz-transform-origin: right bottom;
//     transform-origin: right bottom;
//     -moz-transform: rotate(0);
//     transform: rotate(0);
//     opacity: 1; }

//   100% {
//     -moz-transform-origin: right bottom;
//     transform-origin: right bottom;
//     -moz-transform: rotate(90deg);
//     transform: rotate(90deg);
//     opacity: 0; } }
// @-webkit-keyframes rotateOutUpRight {
//   0% {
//     -webkit-transform-origin: right bottom;
//     transform-origin: right bottom;
//     -webkit-transform: rotate(0);
//     transform: rotate(0);
//     opacity: 1; }

//   100% {
//     -webkit-transform-origin: right bottom;
//     transform-origin: right bottom;
//     -webkit-transform: rotate(90deg);
//     transform: rotate(90deg);
//     opacity: 0; } }
// @keyframes rotateOutUpRight {
//   0% {
//     -moz-transform-origin: right bottom;
//     -ms-transform-origin: right bottom;
//     -o-transform-origin: right bottom;
//     -webkit-transform-origin: right bottom;
//     transform-origin: right bottom;
//     -moz-transform: rotate(0);
//     -ms-transform: rotate(0);
//     -o-transform: rotate(0);
//     -webkit-transform: rotate(0);
//     transform: rotate(0);
//     opacity: 1; }

//   100% {
//     -moz-transform-origin: right bottom;
//     -ms-transform-origin: right bottom;
//     -o-transform-origin: right bottom;
//     -webkit-transform-origin: right bottom;
//     transform-origin: right bottom;
//     -moz-transform: rotate(90deg);
//     -ms-transform: rotate(90deg);
//     -o-transform: rotate(90deg);
//     -webkit-transform: rotate(90deg);
//     transform: rotate(90deg);
//     opacity: 0; } }
// @-moz-keyframes hinge {
//   0% {
//     -moz-transform: rotate(0);
//     transform: rotate(0);
//     -moz-transform-origin: top left;
//     transform-origin: top left;
//     -moz-animation-timing-function: ease-in-out;
//     animation-timing-function: ease-in-out; }

//   20%, 60% {
//     -moz-transform: rotate(80deg);
//     transform: rotate(80deg);
//     -moz-transform-origin: top left;
//     transform-origin: top left;
//     -moz-animation-timing-function: ease-in-out;
//     animation-timing-function: ease-in-out; }

//   40% {
//     -moz-transform: rotate(60deg);
//     transform: rotate(60deg);
//     -moz-transform-origin: top left;
//     transform-origin: top left;
//     -moz-animation-timing-function: ease-in-out;
//     animation-timing-function: ease-in-out; }

//   80% {
//     -moz-transform: rotate(60deg) translateY(0);
//     transform: rotate(60deg) translateY(0);
//     opacity: 1;
//     -moz-transform-origin: top left;
//     transform-origin: top left;
//     -moz-animation-timing-function: ease-in-out;
//     animation-timing-function: ease-in-out; }

//   100% {
//     -moz-transform: translateY(700px);
//     transform: translateY(700px);
//     opacity: 0; } }
// @-webkit-keyframes hinge {
//   0% {
//     -webkit-transform: rotate(0);
//     transform: rotate(0);
//     -webkit-transform-origin: top left;
//     transform-origin: top left;
//     -webkit-animation-timing-function: ease-in-out;
//     animation-timing-function: ease-in-out; }

//   20%, 60% {
//     -webkit-transform: rotate(80deg);
//     transform: rotate(80deg);
//     -webkit-transform-origin: top left;
//     transform-origin: top left;
//     -webkit-animation-timing-function: ease-in-out;
//     animation-timing-function: ease-in-out; }

//   40% {
//     -webkit-transform: rotate(60deg);
//     transform: rotate(60deg);
//     -webkit-transform-origin: top left;
//     transform-origin: top left;
//     -webkit-animation-timing-function: ease-in-out;
//     animation-timing-function: ease-in-out; }

//   80% {
//     -webkit-transform: rotate(60deg) translateY(0);
//     transform: rotate(60deg) translateY(0);
//     opacity: 1;
//     -webkit-transform-origin: top left;
//     transform-origin: top left;
//     -webkit-animation-timing-function: ease-in-out;
//     animation-timing-function: ease-in-out; }

//   100% {
//     -webkit-transform: translateY(700px);
//     transform: translateY(700px);
//     opacity: 0; } }
// @keyframes hinge {
//   0% {
//     -moz-transform: rotate(0);
//     -ms-transform: rotate(0);
//     -o-transform: rotate(0);
//     -webkit-transform: rotate(0);
//     transform: rotate(0);
//     -moz-transform-origin: top left;
//     -ms-transform-origin: top left;
//     -o-transform-origin: top left;
//     -webkit-transform-origin: top left;
//     transform-origin: top left;
//     -moz-animation-timing-function: ease-in-out;
//     -webkit-animation-timing-function: ease-in-out;
//     animation-timing-function: ease-in-out; }

//   20%, 60% {
//     -moz-transform: rotate(80deg);
//     -ms-transform: rotate(80deg);
//     -o-transform: rotate(80deg);
//     -webkit-transform: rotate(80deg);
//     transform: rotate(80deg);
//     -moz-transform-origin: top left;
//     -ms-transform-origin: top left;
//     -o-transform-origin: top left;
//     -webkit-transform-origin: top left;
//     transform-origin: top left;
//     -moz-animation-timing-function: ease-in-out;
//     -webkit-animation-timing-function: ease-in-out;
//     animation-timing-function: ease-in-out; }

//   40% {
//     -moz-transform: rotate(60deg);
//     -ms-transform: rotate(60deg);
//     -o-transform: rotate(60deg);
//     -webkit-transform: rotate(60deg);
//     transform: rotate(60deg);
//     -moz-transform-origin: top left;
//     -ms-transform-origin: top left;
//     -o-transform-origin: top left;
//     -webkit-transform-origin: top left;
//     transform-origin: top left;
//     -moz-animation-timing-function: ease-in-out;
//     -webkit-animation-timing-function: ease-in-out;
//     animation-timing-function: ease-in-out; }

//   80% {
//     -moz-transform: rotate(60deg) translateY(0);
//     -ms-transform: rotate(60deg) translateY(0);
//     -o-transform: rotate(60deg) translateY(0);
//     -webkit-transform: rotate(60deg) translateY(0);
//     transform: rotate(60deg) translateY(0);
//     opacity: 1;
//     -moz-transform-origin: top left;
//     -ms-transform-origin: top left;
//     -o-transform-origin: top left;
//     -webkit-transform-origin: top left;
//     transform-origin: top left;
//     -moz-animation-timing-function: ease-in-out;
//     -webkit-animation-timing-function: ease-in-out;
//     animation-timing-function: ease-in-out; }

//   100% {
//     -moz-transform: translateY(700px);
//     -ms-transform: translateY(700px);
//     -o-transform: translateY(700px);
//     -webkit-transform: translateY(700px);
//     transform: translateY(700px);
//     opacity: 0; } }
 @-moz-keyframes zoomIn {
   0% {
     opacity: 0;
     -moz-transform: scale3d(0.3, 0.3, 0.3);
     transform: scale3d(0.3, 0.3, 0.3); }

   50% {
     opacity: 1; } }
 @-webkit-keyframes zoomIn {
   0% {
     opacity: 0;
     -webkit-transform: scale3d(0.3, 0.3, 0.3);
     transform: scale3d(0.3, 0.3, 0.3); }

   50% {
     opacity: 1; } }
 @keyframes zoomIn {
   0% {
     opacity: 0;
     -moz-transform: scale3d(0.3, 0.3, 0.3);
     -webkit-transform: scale3d(0.3, 0.3, 0.3);
     transform: scale3d(0.3, 0.3, 0.3); }

   50% {
     opacity: 1; } }
// @-moz-keyframes zoomInDown {
//   0% {
//     opacity: 0;
//     -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
//     transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
//     -moz-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
//     animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

//   60% {
//     opacity: 1;
//     -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
//     transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
//     -moz-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
//     animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }
// @-webkit-keyframes zoomInDown {
//   0% {
//     opacity: 0;
//     -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
//     transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
//     -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
//     animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

//   60% {
//     opacity: 1;
//     -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
//     transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
//     -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
//     animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }
// @keyframes zoomInDown {
//   0% {
//     opacity: 0;
//     -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
//     -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
//     -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
//     -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
//     transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
//     -moz-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
//     -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
//     animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

//   60% {
//     opacity: 1;
//     -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
//     -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
//     -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
//     -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
//     transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
//     -moz-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
//     -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
//     animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }
// @-moz-keyframes zoomInLeft {
//   0% {
//     opacity: 0;
//     -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
//     transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
//     -moz-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
//     animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

//   60% {
//     opacity: 1;
//     -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
//     transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
//     -moz-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
//     animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }
// @-webkit-keyframes zoomInLeft {
//   0% {
//     opacity: 0;
//     -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
//     transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
//     -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
//     animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

//   60% {
//     opacity: 1;
//     -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
//     transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
//     -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
//     animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }
// @keyframes zoomInLeft {
//   0% {
//     opacity: 0;
//     -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
//     -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
//     -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
//     -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
//     transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
//     -moz-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
//     -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
//     animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

//   60% {
//     opacity: 1;
//     -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
//     -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
//     -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
//     -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
//     transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
//     -moz-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
//     -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
//     animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }
// @-moz-keyframes zoomInRight {
//   0% {
//     opacity: 0;
//     -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
//     transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
//     -moz-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
//     animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

//   60% {
//     opacity: 1;
//     -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
//     transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
//     -moz-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
//     animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }
// @-webkit-keyframes zoomInRight {
//   0% {
//     opacity: 0;
//     -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
//     transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
//     -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
//     animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

//   60% {
//     opacity: 1;
//     -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
//     transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
//     -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
//     animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }
// @keyframes zoomInRight {
//   0% {
//     opacity: 0;
//     -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
//     -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
//     -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
//     -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
//     transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
//     -moz-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
//     -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
//     animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

//   60% {
//     opacity: 1;
//     -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
//     -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
//     -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
//     -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
//     transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
//     -moz-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
//     -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
//     animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }
// @-moz-keyframes zoomInUp {
//   0% {
//     opacity: 0;
//     -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
//     transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
//     -moz-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
//     animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

//   60% {
//     opacity: 1;
//     -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
//     transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
//     -moz-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
//     animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }
// @-webkit-keyframes zoomInUp {
//   0% {
//     opacity: 0;
//     -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
//     transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
//     -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
//     animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

//   60% {
//     opacity: 1;
//     -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
//     transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
//     -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
//     animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }
// @keyframes zoomInUp {
//   0% {
//     opacity: 0;
//     -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
//     -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
//     -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
//     -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
//     transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
//     -moz-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
//     -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
//     animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

//   60% {
//     opacity: 1;
//     -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
//     -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
//     -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
//     -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
//     transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
//     -moz-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
//     -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
//     animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }
// @-moz-keyframes zoomOut {
//   0% {
//     opacity: 1; }

//   50% {
//     opacity: 0;
//     -moz-transform: scale3d(0.3, 0.3, 0.3);
//     transform: scale3d(0.3, 0.3, 0.3); }

//   100% {
//     opacity: 0; } }
// @-webkit-keyframes zoomOut {
//   0% {
//     opacity: 1; }

//   50% {
//     opacity: 0;
//     -webkit-transform: scale3d(0.3, 0.3, 0.3);
//     transform: scale3d(0.3, 0.3, 0.3); }

//   100% {
//     opacity: 0; } }
// @keyframes zoomOut {
//   0% {
//     opacity: 1; }

//   50% {
//     opacity: 0;
//     -moz-transform: scale3d(0.3, 0.3, 0.3);
//     -webkit-transform: scale3d(0.3, 0.3, 0.3);
//     transform: scale3d(0.3, 0.3, 0.3); }

//   100% {
//     opacity: 0; } }
// @-moz-keyframes zoomOutDown {
//   40% {
//     opacity: 1;
//     -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
//     transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
//     -moz-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
//     animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

//   100% {
//     opacity: 0;
//     -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
//     transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
//     -moz-transform-origin: center bottom 50%;
//     transform-origin: center bottom 50%;
//     -moz-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
//     animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }
// @-webkit-keyframes zoomOutDown {
//   40% {
//     opacity: 1;
//     -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
//     transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
//     -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
//     animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

//   100% {
//     opacity: 0;
//     -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
//     transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
//     -webkit-transform-origin: center bottom 50%;
//     transform-origin: center bottom 50%;
//     -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
//     animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }
// @keyframes zoomOutDown {
//   40% {
//     opacity: 1;
//     -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
//     -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
//     -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
//     -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
//     transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
//     -moz-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
//     -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
//     animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

//   100% {
//     opacity: 0;
//     -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
//     -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
//     -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
//     -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
//     transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
//     -moz-transform-origin: center bottom 50%;
//     -ms-transform-origin: center bottom 50%;
//     -o-transform-origin: center bottom 50%;
//     -webkit-transform-origin: center bottom 50%;
//     transform-origin: center bottom 50%;
//     -moz-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
//     -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
//     animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }
// @-moz-keyframes zoomOutLeft {
//   40% {
//     opacity: 1;
//     -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
//     transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }

//   100% {
//     opacity: 0;
//     -moz-transform: scale(0.1) translate3d(-2000px, 0, 0);
//     transform: scale(0.1) translate3d(-2000px, 0, 0);
//     -moz-transform-origin: left center 50%;
//     transform-origin: left center 50%; } }
// @-webkit-keyframes zoomOutLeft {
//   40% {
//     opacity: 1;
//     -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
//     transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }

//   100% {
//     opacity: 0;
//     -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
//     transform: scale(0.1) translate3d(-2000px, 0, 0);
//     -webkit-transform-origin: left center 50%;
//     transform-origin: left center 50%; } }
// @keyframes zoomOutLeft {
//   40% {
//     opacity: 1;
//     -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
//     -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
//     -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
//     -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
//     transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }

//   100% {
//     opacity: 0;
//     -moz-transform: scale(0.1) translate3d(-2000px, 0, 0);
//     -ms-transform: scale(0.1) translate3d(-2000px, 0, 0);
//     -o-transform: scale(0.1) translate3d(-2000px, 0, 0);
//     -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
//     transform: scale(0.1) translate3d(-2000px, 0, 0);
//     -moz-transform-origin: left center 50%;
//     -ms-transform-origin: left center 50%;
//     -o-transform-origin: left center 50%;
//     -webkit-transform-origin: left center 50%;
//     transform-origin: left center 50%; } }
// @-moz-keyframes zoomOutRight {
//   40% {
//     opacity: 1;
//     -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
//     transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }

//   100% {
//     opacity: 0;
//     -moz-transform: scale(0.1) translate3d(2000px, 0, 0);
//     transform: scale(0.1) translate3d(2000px, 0, 0);
//     -moz-transform-origin: right center 50%;
//     transform-origin: right center 50%; } }
// @-webkit-keyframes zoomOutRight {
//   40% {
//     opacity: 1;
//     -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
//     transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }

//   100% {
//     opacity: 0;
//     -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
//     transform: scale(0.1) translate3d(2000px, 0, 0);
//     -webkit-transform-origin: right center 50%;
//     transform-origin: right center 50%; } }
// @keyframes zoomOutRight {
//   40% {
//     opacity: 1;
//     -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
//     -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
//     -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
//     -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
//     transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }

//   100% {
//     opacity: 0;
//     -moz-transform: scale(0.1) translate3d(2000px, 0, 0);
//     -ms-transform: scale(0.1) translate3d(2000px, 0, 0);
//     -o-transform: scale(0.1) translate3d(2000px, 0, 0);
//     -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
//     transform: scale(0.1) translate3d(2000px, 0, 0);
//     -moz-transform-origin: right center 50%;
//     -ms-transform-origin: right center 50%;
//     -o-transform-origin: right center 50%;
//     -webkit-transform-origin: right center 50%;
//     transform-origin: right center 50%; } }
// @-moz-keyframes zoomOutUp {
//   40% {
//     opacity: 1;
//     -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
//     transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
//     -moz-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
//     animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

//   100% {
//     opacity: 0;
//     -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
//     transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
//     -moz-transform-origin: center bottom 50%;
//     transform-origin: center bottom 50%;
//     -moz-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
//     animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }
// @-webkit-keyframes zoomOutUp {
//   40% {
//     opacity: 1;
//     -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
//     transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
//     -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
//     animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

//   100% {
//     opacity: 0;
//     -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
//     transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
//     -webkit-transform-origin: center bottom 50%;
//     transform-origin: center bottom 50%;
//     -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
//     animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }
// @keyframes zoomOutUp {
//   40% {
//     opacity: 1;
//     -moz-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
//     -ms-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
//     -o-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
//     -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
//     transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
//     -moz-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
//     -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
//     animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

//   100% {
//     opacity: 0;
//     -moz-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
//     -ms-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
//     -o-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
//     -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
//     transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
//     -moz-transform-origin: center bottom 50%;
//     -ms-transform-origin: center bottom 50%;
//     -o-transform-origin: center bottom 50%;
//     -webkit-transform-origin: center bottom 50%;
//     transform-origin: center bottom 50%;
//     -moz-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
//     -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
//     animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }