/**
 * Title: Outicons Flaticon Pack
 * Author: Anton Saputro
 * Source: http://www.flaticon.com/packs/outicons
 * License:  CC BY 3.0 (http://creativecommons.org/licenses/by/3.0/)
 */

@font-face {
  font-family: fl-outicons;
  src: url(../fonts/a02f4e2b6e7b33080914f45a99861c81.eot);
  src: url(../fonts/a02f4e2b6e7b33080914f45a99861c81.eot#iefix)
      format("embedded-opentype"),
    url(../fonts/21333ec6c95b38fd46f38a30ef2a0c4e.woff) format("woff"),
    url(../fonts/b06a606eff10f46399f303187b3ad569.ttf) format("truetype"),
    url(../fonts/a50c47aff93c2a5f0d8e9fd33cd5ab07.svg) format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

.fl-outicons-ico, 
[class^="fl-outicons-"]:before, [class*=" fl-outicons-"]:before,
[class^="fl-outicons-"]:after, [class*=" fl-outicons-"]:after {   
	font-family: fl-outicons;
    font-size: inherit;
	font-weight: 400;
	font-style: normal;
}

.fl-outicons-add31:before {
	content: "\e000";
}
.fl-outicons-airplane116:before {
	content: "\e001";
}
.fl-outicons-announcer1:before {
	content: "\e002";
}
.fl-outicons-attach16:before {
	content: "\e003";
}
.fl-outicons-backward5:before {
	content: "\e004";
}
.fl-outicons-badge12:before {
	content: "\e005";
}
.fl-outicons-bell76:before {
	content: "\e006";
}
.fl-outicons-book270:before {
	content: "\e007";
}
.fl-outicons-bookmark54:before {
	content: "\e008";
}
.fl-outicons-building106:before {
	content: "\e009";
}
.fl-outicons-calendar194:before {
	content: "\e00a";
}
.fl-outicons-cancel29:before {
	content: "\e00b";
}
.fl-outicons-check74:before {
	content: "\e00c";
}
.fl-outicons-chevron1:before {
	content: "\e00d";
}
.fl-outicons-chevron2:before {
	content: "\e00e";
}
.fl-outicons-chevron3:before {
	content: "\e00f";
}
.fl-outicons-chevron:before {
	content: "\e010";
}
.fl-outicons-clock169:before {
	content: "\e011";
}
.fl-outicons-cloud18:before {
	content: "\e012";
}
.fl-outicons-cloud367:before {
	content: "\e013";
}
.fl-outicons-copy1:before {
	content: "\e014";
}
.fl-outicons-cut38:before {
	content: "\e015";
}
.fl-outicons-diskette17:before {
	content: "\e016";
}
.fl-outicons-dislike18:before {
	content: "\e017";
}
.fl-outicons-down-arrow24:before {
	content: "\e018";
}
.fl-outicons-download194:before {
	content: "\e019";
}
.fl-outicons-expand10:before {
	content: "\e01a";
}
.fl-outicons-export:before {
	content: "\e01b";
}
.fl-outicons-eye130:before {
	content: "\e01c";
}
.fl-outicons-facebook7:before {
	content: "\e01d";
}
.fl-outicons-file128:before {
	content: "\e01e";
}
.fl-outicons-file129:before {
	content: "\e01f";
}
.fl-outicons-filter:before {
	content: "\e020";
}
.fl-outicons-font14:before {
	content: "\e021";
}
.fl-outicons-forbidden17:before {
	content: "\e022";
}
.fl-outicons-forward27:before {
	content: "\e023";
}
.fl-outicons-gear40:before {
	content: "\e024";
}
.fl-outicons-google4:before {
	content: "\e025";
}
.fl-outicons-grid2:before {
	content: "\e026";
}
.fl-outicons-headphones46:before {
	content: "\e027";
}
.fl-outicons-heart373:before {
	content: "\e028";
}
.fl-outicons-house204:before {
	content: "\e029";
}
.fl-outicons-information83:before {
	content: "\e02a";
}
.fl-outicons-laptop4:before {
	content: "\e02b";
}
.fl-outicons-left-arrow25:before {
	content: "\e02c";
}
.fl-outicons-like83:before {
	content: "\e02d";
}
.fl-outicons-lines7:before {
	content: "\e02e";
}
.fl-outicons-list100:before {
	content: "\e02f";
}
.fl-outicons-lock8:before {
	content: "\e030";
}
.fl-outicons-magnifying-glass33:before {
	content: "\e031";
}
.fl-outicons-magnifying-glass34:before {
	content: "\e032";
}
.fl-outicons-magnifying-glass35:before {
	content: "\e033";
}
.fl-outicons-mail2:before {
	content: "\e034";
}
.fl-outicons-microphone10:before {
	content: "\e035";
}
.fl-outicons-minimize1:before {
	content: "\e036";
}
.fl-outicons-minus6:before {
	content: "\e037";
}
.fl-outicons-move1:before {
	content: "\e038";
}
.fl-outicons-news:before {
	content: "\e039";
}
.fl-outicons-notepad20:before {
	content: "\e03a";
}
.fl-outicons-padlock86:before {
	content: "\e03b";
}
.fl-outicons-pause51:before {
	content: "\e03c";
}
.fl-outicons-pencil8:before {
	content: "\e03d";
}
.fl-outicons-pencil9:before {
	content: "\e03e";
}
.fl-outicons-phone14:before {
	content: "\e03f";
}
.fl-outicons-photo-camera5:before {
	content: "\e040";
}
.fl-outicons-picture54:before {
	content: "\e041";
}
.fl-outicons-pin12:before {
	content: "\e042";
}
.fl-outicons-play127:before {
	content: "\e043";
}
.fl-outicons-pointer31:before {
	content: "\e044";
}
.fl-outicons-power115:before {
	content: "\e045";
}
.fl-outicons-print:before {
	content: "\e046";
}
.fl-outicons-question5:before {
	content: "\e047";
}
.fl-outicons-refresh8:before {
	content: "\e048";
}
.fl-outicons-right-arrow30:before {
	content: "\e049";
}
.fl-outicons-sad70:before {
	content: "\e04a";
}
.fl-outicons-sales2:before {
	content: "\e04b";
}
.fl-outicons-screen88:before {
	content: "\e04c";
}
.fl-outicons-share53:before {
	content: "\e04d";
}
.fl-outicons-share55:before {
	content: "\e04e";
}
.fl-outicons-shopping-cart13:before {
	content: "\e04f";
}
.fl-outicons-smartphone65:before {
	content: "\e050";
}
.fl-outicons-smiley3:before {
	content: "\e051";
}
.fl-outicons-sort2:before {
	content: "\e052";
}
.fl-outicons-speech-balloon2:before {
	content: "\e053";
}
.fl-outicons-speech-bubble34:before {
	content: "\e054";
}
.fl-outicons-star6:before {
	content: "\e055";
}
.fl-outicons-star7:before {
	content: "\e056";
}
.fl-outicons-support1:before {
	content: "\e057";
}
.fl-outicons-tablet4:before {
	content: "\e058";
}
.fl-outicons-tags:before {
	content: "\e059";
}
.fl-outicons-trash1:before {
	content: "\e05a";
}
.fl-outicons-treasure:before {
	content: "\e05b";
}
.fl-outicons-truck72:before {
	content: "\e05c";
}
.fl-outicons-twitter4:before {
	content: "\e05d";
}
.fl-outicons-up-arrow46:before {
	content: "\e05e";
}
.fl-outicons-upload142:before {
	content: "\e05f";
}
.fl-outicons-user189:before {
	content: "\e060";
}
.fl-outicons-warning5:before {
	content: "\e061";
}
.fl-outicons-white-flag1:before {
	content: "\e062";
}
.fl-outicons-wifi110:before {
	content: "\e063";
}
