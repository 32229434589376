/**
 * Title: Free chaos Flaticon Pack
 * Author: Balraj Chana
 * Source: http://www.flaticon.com/packs/free-chaos
 * License:  CC BY 3.0 (http://creativecommons.org/licenses/by/3.0/) 
 */

@font-face {
	font-family: "fl-free-chaos";
	src: url(../fonts/864cc9182667e90b0023691e1c31dad9.eot);
	src: url(../fonts/864cc9182667e90b0023691e1c31dad9.eot#iefix)
		format("embedded-opentype"),
		url(../fonts/42679f4db53ebaad6ef65934e0bc5493.woff) format("woff"),
		url(../fonts/7de951695ae08d7e6f061f3c62e629a9.ttf) format("truetype"),
		url(../fonts/2b405a3359585bb17911d0eca8a3ec50.svg) format("svg");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

.fl-free-chaos-ico,
[class^="fl-free-chaos-"]:before, [class*=" fl-free-chaos-"]:before,
[class^="fl-free-chaos-"]:after, [class*=" fl-free-chaos-"]:after {   
	font-family: fl-free-chaos;
    font-size: inherit;
	font-weight: 400;
	font-style: normal;
}

.fl-free-chaos-auricular22:before {
	content: "\e000";
}
.fl-free-chaos-bluetooth18:before {
	content: "\e001";
}
.fl-free-chaos-bookmark43:before {
	content: "\e002";
}
.fl-free-chaos-business180:before {
	content: "\e003";
}
.fl-free-chaos-business181:before {
	content: "\e004";
}
.fl-free-chaos-cellphone63:before {
	content: "\e005";
}
.fl-free-chaos-chat72:before {
	content: "\e006";
}
.fl-free-chaos-circle102:before {
	content: "\e007";
}
.fl-free-chaos-clock97:before {
	content: "\e008";
}
.fl-free-chaos-cloud295:before {
	content: "\e009";
}
.fl-free-chaos-cross97:before {
	content: "\e00a";
}
.fl-free-chaos-email97:before {
	content: "\e00b";
}
.fl-free-chaos-file88:before {
	content: "\e00c";
}
.fl-free-chaos-file89:before {
	content: "\e00d";
}
.fl-free-chaos-home147:before {
	content: "\e00e";
}
.fl-free-chaos-key168:before {
	content: "\e00f";
}
.fl-free-chaos-layout36:before {
	content: "\e010";
}
.fl-free-chaos-layout37:before {
	content: "\e011";
}
.fl-free-chaos-layout38:before {
	content: "\e012";
}
.fl-free-chaos-list86:before {
	content: "\e013";
}
.fl-free-chaos-locked51:before {
	content: "\e014";
}
.fl-free-chaos-map100:before {
	content: "\e015";
}
.fl-free-chaos-menu53:before {
	content: "\e016";
}
.fl-free-chaos-minus104:before {
	content: "\e017";
}
.fl-free-chaos-moon150:before {
	content: "\e018";
}
.fl-free-chaos-musical114:before {
	content: "\e019";
}
.fl-free-chaos-pencil105:before {
	content: "\e01a";
}
.fl-free-chaos-placeholder33:before {
	content: "\e01b";
}
.fl-free-chaos-play98:before {
	content: "\e01c";
}
.fl-free-chaos-plus79:before {
	content: "\e01d";
}
.fl-free-chaos-power105:before {
	content: "\e01e";
}
.fl-free-chaos-right201:before {
	content: "\e01f";
}
.fl-free-chaos-search97:before {
	content: "\e020";
}
.fl-free-chaos-speaker103:before {
	content: "\e021";
}
.fl-free-chaos-speaker104:before {
	content: "\e022";
}
.fl-free-chaos-speaker106:before {
	content: "\e023";
}
.fl-free-chaos-speaker108:before {
	content: "\e024";
}
.fl-free-chaos-square176:before {
	content: "\e025";
}
.fl-free-chaos-square179:before {
	content: "\e026";
}
.fl-free-chaos-star171:before {
	content: "\e027";
}
.fl-free-chaos-sunny16:before {
	content: "\e028";
}
.fl-free-chaos-table41:before {
	content: "\e029";
}
.fl-free-chaos-tag69:before {
	content: "\e02a";
}
.fl-free-chaos-three166:before {
	content: "\e02b";
}
.fl-free-chaos-three167:before {
	content: "\e02c";
}
.fl-free-chaos-two369:before {
	content: "\e02d";
}
.fl-free-chaos-two370:before {
	content: "\e02e";
}
.fl-free-chaos-up153:before {
	content: "\e02f";
}
.fl-free-chaos-user155:before {
	content: "\e030";
}
.fl-free-chaos-verification22:before {
	content: "\e031";
}
