@font-face {
  font-family: Playfair Display;
  src: url(../fonts/186952c0c9864204eb877744edf97405.woff2) format("woff2"),
    url(../fonts/08bb07b25c575e4dee0fcb4ea375ddc5.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Lato;
  src: url(../fonts/12a234e87f37d441adc9bb2e3dec13de.woff2) format("woff2"),
    url(../fonts/2032f2b161a7a109a9d0cc3f6ad3b735.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Open Sans;
  src: url(../fonts/93fe8f3415688d16bb947e2c96ea380e.woff2) format("woff2"),
    url(../fonts/759ea19bccf8a5ca50a58e474aced157.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Playfair Display;
  src: url(../fonts/2dd8f19b24f27ce93329334efe204146.woff2) format("woff2"),
    url(../fonts/85b816fafffafd6f9907082d5fe93fd9.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Hind;
  src: url(../fonts/c2a74bc644e289761331276f1819958d.woff2) format("woff2"),
    url(../fonts/d46ae152a308171a665fa5d93cf1600e.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Raleway;
  src: url(../fonts/798e56bfaa71879fd0d56159fcce6960.woff2) format("woff2"),
    url(../fonts/d0241429df3540c1b78c93caff41520c.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Ubuntu;
  src: url(../fonts/c636b9b677fbd28b6596bf4ff1043f44.woff2) format("woff2"),
    url(../fonts/278321edc4a28fc3f2441b1472e9347a.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Lato;
  src: url(../fonts/69279aef7fbc11101022a9f06079bbbf.woff2) format("woff2"),
    url(../fonts/e87e43c57f21dd637791c6bfa534c1af.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Hind;
  src: url(../fonts/de81c52da4198e5cac75e6c945a1e50c.woff2) format("woff2"),
    url(../fonts/b982b084aa69e0a7a899abfc2a55ea2b.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Lora;
  src: url(../fonts/ba2f1d3c4d84c92400d1781ad3345918.woff2) format("woff2"),
    url(../fonts/094fd51654ee296424b53a8aa1a7f2bf.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Open Sans;
  src: url(../fonts/5d5735e57127db2f7a2ad879fc6056b8.woff2) format("woff2"),
    url(../fonts/6fde2eb6728eb97fd914dcd0641d332b.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Oswald;
  src: url(../fonts/9ec852f6a03f3a058e0ef5970e06a83b.woff2) format("woff2"),
    url(../fonts/20c8d6d443414facd15c1840c19727f7.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Lora;
  src: url(../fonts/b4a4104702e2fd403d0c0b609284fea3.woff2) format("woff2"),
    url(../fonts/db2c7010e83446782a8a4bfa27880e4e.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Oswald;
  src: url(../fonts/edaa7375a6aa70847d9ac82c5a1aaf1d.woff2) format("woff2"),
    url(../fonts/2f4d6d71f6f2bc432af3d4015bb014cf.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Ubuntu;
  src: url(../fonts/b7350427f5d9fbceabb3325336d69531.woff2) format("woff2"),
    url(../fonts/ba4e7809d474b7b6936d09346695c3ae.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Roboto;
  src: url(../fonts/ba18ba6bb49f6fb83b70d5ccbc7b4e41.woff2) format("woff2"),
    url(../fonts/d4b81101c31b571dbd3fe2accc2a22ad.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Raleway;
  src: url(../fonts/b304ecae07c288122831c4d40c772482.woff2) format("woff2"),
    url(../fonts/b3e24b3a170fed00bd406985cfe82a8e.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Indie Flower;
  src: url(../fonts/19101f04d6e9441449821ad0b776789b.woff2) format("woff2"),
    url(../fonts/ee7535888cb5757c57db7f24882776fc.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Roboto;
  src: url(../fonts/81f751c74973b61ebedbf61f3ecbf480.woff2) format("woff2"),
    url(../fonts/c5867568e1f469f4b62ba9c51a9407cd.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}