.sitemap-title {
  text-transform: uppercase;
}

.sitemap {
  margin-top: 0.9375rem;

  h2 {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 400;
    margin-left: -15px;
    width: 100%;
    margin-bottom: 20px;
  }

  ul {
    margin-left: -15px;
    margin-top: 9px;

    &.nested {
      margin-left: 20px;
    }

    li {
      font-size: 14px;
      margin-bottom: 9px;
    }
  }
}

/*** Responsive part ***/

@media (max-width: 575px) {
  .sitemap {
    margin-top: 0;
  }
}
