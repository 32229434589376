@font-face {
  font-family: Montserrat;
  src: url(../fonts/f070a6b17db615aa3f6df1df9428e832.woff2) format("woff2"),
    url(../fonts/bd9d6aaeac0a8cbe381baa8a94bf1572.woff) format("woff"),
    url(../fonts/b88da42aa3bad2ffc3ff9bcb67ad9b44.ttf) format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Montserrat;
  src: url(../fonts/0631fccdd95497bce520e7426292b3b9.woff2) format("woff2"),
    url(../fonts/2bf1f39c42e89ddb7c007b4be150fb85.woff) format("woff"),
    url(../fonts/2eb880200f30afdf59de5adff9c4cd52.ttf) format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: Montserrat;
  src: url(../fonts/3255356e2ef8d50c80e33fa56ce3e561.woff2) format("woff2"),
    url(../fonts/323f5fb651a125f7e756545fa9f7924a.woff) format("woff"),
    url(../fonts/26fa2e74346a332d5ab52eccec9880a6.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Montserrat;
  src: url(../fonts/a2cfc79505f7e5ca258ffd68da5431d1.woff2) format("woff2"),
    url(../fonts/43d07d8a6931ef269c28fa44f9fc1786.woff) format("woff"),
    url(../fonts/402dd1d689c91aa59c718f517dc72ce3.ttf) format("truetype");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: Montserrat;
  src: url(../fonts/7c70f832333fa16a44d043d085501087.woff2) format("woff2"),
    url(../fonts/6b0c9c5bbb2c48246889e10fae299745.woff) format("woff"),
    url(../fonts/42291783095dfc96f7a1a5829bb12af5.ttf) format("truetype");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: Montserrat;
  src: url(../fonts/a417bcb6222f949ee9635b28fcfcc429.woff2) format("woff2"),
    url(../fonts/1193b69df518c1c70bc0f6b9efe8eb25.woff) format("woff"),
    url(../fonts/d3bc113f338134c2b3e770de77b64209.ttf) format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Montserrat;
  src: url(../fonts/aab897981ce728bf9faaf8d7e9273e82.woff2) format("woff2"),
    url(../fonts/3ea5ca02b4430d5778cec0f47461a440.woff) format("woff"),
    url(../fonts/1f015d0304277ccede70ad96a3e690f8.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Montserrat;
  src: url(../fonts/69030457151b999f8d46b81dd4e4e8fc.woff2) format("woff2"),
    url(../fonts/227e65c6ad5b4f9ea58b8ae37c9fe075.woff) format("woff"),
    url(../fonts/324a020365710c37487d3ce78db4efef.ttf) format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: Montserrat;
  src: url(../fonts/4e861b47db165af12ec0447c91b0167f.woff2) format("woff2"),
    url(../fonts/bb14faecf9be1b87dc0b81202b4a3537.woff) format("woff"),
    url(../fonts/4ffc6d02294218b69a4cae713d657056.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Montserrat;
  src: url(../fonts/61877b8beedd638d4c8c95a01ad8ea1c.woff2) format("woff2"),
    url(../fonts/fdb8dfa5d6ec46d2e100e6127b8e2a26.woff) format("woff"),
    url(../fonts/80ff3007c2f32ca7d05bf044119053ec.ttf) format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Montserrat;
  src: url(../fonts/6a6712b919c24af6794e9cce41ccbade.woff2) format("woff2"),
    url(../fonts/26e7a7f137151b6bf95f5f159d647cfc.woff) format("woff"),
    url(../fonts/0980017490fa1ad4603b2ee052e41a24.ttf) format("truetype");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: Montserrat;
  src: url(../fonts/68bab908bdb4f129906eda9a65792ed8.woff2) format("woff2"),
    url(../fonts/b5dc87584986d8d8e5621bc45d5ddd47.woff) format("woff"),
    url(../fonts/aebc9f08f534b4ba90214d1ef0414f9a.ttf) format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: Montserrat;
  src: url(../fonts/6b8307d4d485772acfa7afe8265fb942.woff2) format("woff2"),
    url(../fonts/f2209ce8d795fb65ba8c148548495f69.woff) format("woff"),
    url(../fonts/1021d2536d406a6c24b6e6c0c2597ab4.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Montserrat;
  src: url(../fonts/09e9af57c990afbf2833f00d90880b6b.woff2) format("woff2"),
    url(../fonts/39d20d2ee515e7899da61c13dea56820.woff) format("woff"),
    url(../fonts/16ccc3e71ceb57d6f30da12a69e0ac36.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Montserrat;
  src: url(../fonts/11732f8e09b3808fc5dad6f675d35b8d.woff2) format("woff2"),
    url(../fonts/300f569dd8f4058fc5a44fee28499b86.woff) format("woff"),
    url(../fonts/3ffe97d159f83d59104f5e06fea46850.ttf) format("truetype");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: Montserrat;
  src: url(../fonts/668a44fc2d1138eb6e2a150b6b0305d3.woff2) format("woff2"),
    url(../fonts/552e0533bafa3475c49d5a3c62167771.woff) format("woff"),
    url(../fonts/41e17af20fd229ccb6afe2a93c30e876.ttf) format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: Montserrat;
  src: url(../fonts/6ddb4e3b60bba72a57823976c43042af.woff2) format("woff2"),
    url(../fonts/97645edc975da1f2c1c10fa905b9b70b.woff) format("woff"),
    url(../fonts/cc7498f269d704472248434bb155fb95.ttf) format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: Montserrat;
  src: url(../fonts/16c1a5b7a2037ec2bad9740c8b0ff8ee.woff2) format("woff2"),
    url(../fonts/0a6aaa828663fbb8f2f018b3e71bca3c.woff) format("woff"),
    url(../fonts/b3515eeaaf88854dfbe98f391bc37bb6.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}