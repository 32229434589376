.bon-about-us {
    img {
        width: 100%;
        height: auto;
    }

    h3 {
        text-align: center;

    }

    .heading {
        overflow: hidden;
        padding: 50px 0;

        &:before,
        &:after {
            content: "";
            display: inline-block;
            vertical-align: middle;
            width: 100%;
            height: 1px;
            background: #dcdcdc;
            border: solid #fff;
            border-width: 0 20px;
        }

        &:before {
            margin-left: -100%;
        }

        &:after {
            margin-right: -100%;
        }
    }

    .about-us-img {
        position: relative;

        .about-us-img-poster {
            width: 150px;
            height: 150px;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
        }

        video {
            width: 100%;
            height: auto;

            &:focus {
                outline: 0;
                outline-offset: 0;
            }
        }

        &:hover .about-us-img-poster {
            opacity: 0.75;
            transition: all 0.3s ease;
        }
    }

    .about-us-block {
        .about-us-text {
            display: flex;
            flex-wrap: wrap;

            .about-us-info {
                .col-md-7 {
                    padding-left: 15px;
                }

                p {
                    line-height: 2;
                    letter-spacing: 0.75px;
                    color: #545454;
                }

                .bon-description-text-list {
                    line-height: 2;

                    li {
                        font-size: 13px;
                        padding-left: 25px;
                        position: relative;
                        color: #545454;

                        &:before {
                            content: "\e5ca";
                            font-family: "Material Icons";
                            color: $brand-primary;
                            font-size: 13px;
                            position: absolute;
                            left: 0;
                        }
                    }
                }
            }

            .about-us-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;

                .bon-description-icon-list {
                    display: flex;
                    flex-wrap: wrap;
                    height: 100%;
                    margin-bottom: 0;
                    align-content: space-around;

                    li {
                        width: 50%;
                        text-align: center;

                        i {
                            font-size: 50px;
                        }

                        h3 {
                            margin-top: 15px;
                            margin-bottom: 10px;
                            font-size: 14px;
                            font-weight: 500;

                        }

                        p {
                            margin-bottom: 0;
                            line-height: 1;
                        }
                    }
                }
            }
        }
    }

    .about-us-team {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
        grid-gap: 20px;

        .about-us-picture-block {
            .box {
                position: relative;
                width: 230px;
                height: 230px;
                background: #000;
                transition: 0.3s all ease;
                //border-radius: 50%;
                overflow: hidden;

                .imgBx {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;

                    &:before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        //background: linear-gradient(180deg, #8b9db1, #fff);
                        background: #8b9db1;
                        z-index: 1;
                        opacity: 0;
                        transition: 0.5s;
                    }

                    img {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                &:hover .imgBx:before {
                    opacity: 0.5;
                }

                .content {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    display: flex;
                    padding: 20px;
                    align-items: flex-end;

                    &>ul {
                        margin: 0 auto;
                    }

                    h2 {
                        color: #fff;
                        transition: 0.5s;

                        margin-bottom: 5px;
                        font-size: 20px;
                        transform: translateY(200px);
                        text-align: center;
                    }

                    ul {
                        font-size: 16px;
                        transition: 0.5s;
                        transform: translateY(200px);
                        display: flex;
                        justify-content: space-around;
                        width: 120px;

                        li {
                            i {
                                color: #fff;
                                font-size: 23px;
                                text-shadow: 1px 1px 2px black;
                            }
                        }
                    }
                }

                &:hover .content h2 {
                    transform: translateY(0px);
                    transition-delay: 0.6s;
                }

                &:hover .content ul {
                    transform: translateY(0px);
                    transition-delay: 0.3s;
                }
            }

            .about-us-social-name {
                text-align: center;
                letter-spacing: 0.75px;

                h5 {
                    font-size: 18px;
                    margin: 20px 0 12px 0;
                }

                h6 {
                    font-size: 15px;
                    color: #6a6a6a;
                }
            }
        }
    }

    .about-us-picture {
        .about-us-info-acardion {
            .accordion {
                width: 100%;
                background: #fff;
                border-radius: 4px;
                margin-bottom: 0;

                &>li {
                    margin-bottom: 20px;
                }

                &>li:last-of-type {
                    margin-bottom: 0;
                }
            }

            .accordion .link {
                cursor: pointer;
                display: block;
                padding: 14px;
                color: #232615;
                font-size: 14px;
                letter-spacing: 0.75px;
                text-transform: uppercase;
                border: 1px solid #dcdcdc;
                position: relative;
                transition: all 0.4s ease;
            }

            .accordion li i {
                position: absolute;
                top: 16px;
                left: 12px;
                font-size: 18px;
                color: #595959;
                transition: all 0.4s ease;
            }

            .accordion li i.mercury-icon-angle-bottom {
                right: 12px;
                left: auto;
                font-size: 16px;
            }

            .accordion li.open .link {
                // color: #b63b4d;
                background: #2326150d;
            }

            .accordion li.open i {
                // color: #b63b4d;
                // background: #2326150d;
            }

            .accordion li.open i.mercury-icon-angle-bottom {
                transform: rotate(180deg);
            }

            .submenu {
                display: none;
                border: 1px solid #dcdcdc;
                font-size: 14px;
                padding: 15px;
                border-top: 0;
            }

            .submenu a {
                display: block;
                text-decoration: none;
                color: #d9d9d9;
                padding: 12px;
                padding-left: 42px;
                transition: all 0.25s ease;
            }

            .submenu a:hover {
                background: #b63b4d;
                color: #fff;
            }
        }

        .about-us-info-content {

            .about-us-info-content-block {
                .about-us-info-content-block-name {
                    background: #e6edf2;
                    padding-top: 75px;
                    margin-top: 58px;

                    h4 {
                        margin-bottom: 25px;
                    }
                }

                &:nth-child(2) {
                    background: #f4f4f4;
                }

                .about-us-info-content-block-img {
                    width: 108px;
                    margin: 0 auto;
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    z-index: 1;

                    img {
                        border-radius: 50%;
                    }
                }
            }

            h4,
            p {
                text-align: center;
            }

            p {
                padding: 0px 20px 20px;
                margin: 0;
            }
        }
    }
}

@media screen and(max-width: 1199px) {
    .about-us-team .about-us-picture-block .box {
        margin: 0 auto;
    }
}

@media (max-width: 991px) {
    .bon-description-icon-list li {
        padding: 35px 0;
    }
}

@media (max-width: 575px) {
    .bon-about-us {
        .about-us-picture {
            .about-us-info-content {
                .about-us-info-content-block {
                    .about-us-info-content-block-name {
                        padding-top: 115px;
                        margin-top: 25px;
                    }
                }
            }
        }

        .about-us-img {
            .about-us-img-poster {
                display: none;
            }
        }
    }
}

@media (max-width: 767px) {
    .about-us-info-content-block {
        margin: 20px 0;
    }

    .about-us-info-content {
        margin-top: 20px;
    }
}