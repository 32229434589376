.icon-text {
    transition: all .3s ease;
    font-size: 16px;
    color: white;
    span {
        color: $brand-danger;
    }
}

#header {
    background: $vert-medium;
    transition: all 0.3s ease;
    position: relative;
    &.fadeInForHeader {
        opacity: 0;
    }
    .block-social {
        @media (max-width: 767px) {
            margin-right: 10px;
            ul {
                margin-right: 0;
                li {
                    margin-bottom: 0;
                }
            }
        }
    }
    .ybc-menu-toggle {
        color: $white;
        .icon-bar {
            background-color: $white;
        }
    }
    .header-banner {
        background-color: $vert-dark !important;
        color: $white;
        font-weight: 500;
        transition: all .3s ease;
        @media (min-width: 992px) {
            background-color: $brand-header !important;
        }
         a {
             color: $white;
         }

        .user-info-text {
            font-size: 14px;
            font-weight: 500;
        }
        .header-contact-info {
            display: flex;
            justify-content: space-between;
            padding: 10px;
            @media (min-width: 992px) {
                justify-content: flex-end;
                padding: 10px 20px;
            }
            @media (min-width: 1200px) {
                justify-content: space-between;
            }
        }

        .header-contact {
            display: flex;
            margin-bottom: 0;

            &.right-block {
                .block-social {
                    ul {
                        margin-left: 10px;
                        margin-bottom: 0;
                        li {
                            width: 20px;
                            height: 20px;
                            max-width: 20px !important;
                        }
                    }
                }
            }

            .shop_phone {
                i, a {
                    transition: all .3s ease;
                }
            }

            &.left-block{
                flex-wrap: wrap;
            }

            li {
                i {
                    margin-right: 5px;

                }

                i.material-icons {
                    font-size: 13px;
                }
            }

            .link-item {
                transition: all .2s linear;

                i.material-icons {
                    font-size: 21px;
                    transition: all .2s linear;
                }

                &:hover {
                    color: $brand-hover;

                    i.material-icons {
                        color: $brand-hover;
                    }
                }
            }

            .header_address_icon,
            .header_address,
            .header_phone_icon,
            .header_phone{
                font-size: 13px;
                font-weight: 500;
            }

            .header_address_icon,
            .header_phone_icon{
                margin-right: 7px;
            }

            .header_phone{
                margin-right: 30px;
            }
            
            .shop_address_country{
                display: flex;
            }
        }
		
		.block-social {
			ul {
			  display: flex;
			  margin-left: -20px;

			  li {
				margin-left: 10px;
				a {
				  border: none;
				  display: block !important;
				  width: 20px;
				  height: 20px;
				  max-width: 20px !important;

				  &:hover {
					&:before {
					  opacity: 0.7;
					}
				  }

				  &:before {
					font-size: 18px;
				  }
				}
			  }
			}
		  }
    }

    .logo {
        max-width: 150px;
        width: auto;
        @media (min-width: 992px) {
            max-width: 130px;
            width: auto;
            margin-top: -57px;
            margin-bottom: 0;
        }
        @media (min-width: 1260px) {
            margin-top: -42px;
            margin-bottom: -40px;
        }
    }

    #_desktop_logo {
        padding: 0;
        h1 {
            margin-bottom: 0;
            .h1-visible {
                visibility: hidden;
            }
        }
    }

    .blockcart {

        .cart-products-count {
            position: absolute;
            left: -20px;
            top: -9px;
            background: $brand-hover;
            border-radius: 50%;
            box-shadow: 0 4px 4px rgba(0, 0, 0, .14);
            font-size: 12px;
            font-weight: 600;
            height: 20px;
            width: 20px;
            line-height: 20px;
            text-align: center;
            display: block;
            transition: all .2s linear;
            color: #3a3a3a;
        }

        &:hover {
            .cart-products-count {
                box-shadow: none;
            }
        }
    }

    .header-top {
        background-color: $vert-medium !important;
        .position-static-menu{
            //padding: 0 20px;
        }

        @media (max-width: 1199px) and (min-width: 768px) {
            .changestatus.transition_floating .close_menu {
                background-color: $orange;
            }
        }

        @media (max-width: 991px) and (min-width: 768px) {
            .ets_mm_megamenu.layout_layout5:not(.ybc_vertical_menu) .mm_menus_ul {
                background: $white;
            }
        }

        .position-static {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @media (max-width: 991px) {
                flex-direction: row-reverse;
            }

            @media (min-width: 992px) {
                flex-wrap: wrap;
                padding: 0 20px
            }

            .mm_menus_ul {
                @media (min-width: 992px) {
                    display: flex;
                    justify-content: flex-end;
                }
                .mm_menu_content_title {
                    font-size: 16px;
                    font-weight: 600;
                    text-transform: none;
                }
            }

            .bon-nav-bar {
                display: flex;
                align-items: center;



                &>div {
                    &:not(.modal) {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        @media screen and (any-hover: hover){
                            &:hover {
                                cursor: pointer;
                            }
                        }
                    }
                }
                &>div:not(:last-child) {
                    padding-right: 40px;
                }

                #_desktop_cart {
                    .blockcart {
                        font-weight: 400;
                        color: $gray-black;
                        transition: all .2s linear;
                        line-height: 146%;

                        i {
                            transition: all .2s linear;
                            line-height: 130%;
                        }

                        a {
                            font-size: 25px;
                            text-decoration: none;
                            position: relative;
                        }
                    }
                }
            }
        }
        &>.container{
            padding: 0;
            position: relative;
        }
    }

    #_desktop_user_info {
        &:hover {
            .user-info {
                .bon-login-popup {
                    visibility: visible;
                    opacity: 1;
                    pointer-events: auto;
                    transform: translateY(0);
                }
            }
        }

        .user-info {
            position: relative;

            i {
                font-size: 25px;
                font-weight: 400;
                line-height: 130%;
            }

            a{
                font-size: 14px;
            }

            .bon-login-popup {
                visibility: hidden;
                transform: translateY(100px);
                transition: all 0.3s linear;
                opacity: 0;
                pointer-events: none;
                position: absolute;
                width: 200px;
                right: -9px;
                z-index: 5002;
                top: 30px;
                border: 1px solid #ebebeb;
                box-shadow: 0 2px 8px rgba(0, 0, 0, .16);
                background: #fff;


                &-button {
                    margin: 0 auto;
                    width: 100%;
                    text-align: center;
                    padding: 0 5px;
                }

                .btn {
                    font-size: 14px !important;
                    margin-top: 15px;
                    width: 100%;
                    padding: 8px;
                    &:last-child {
                        margin-bottom: 15px;
                    }
                }

                & .bon-logout {
                    margin-top: 15px !important;
                    width: 100%;
                }
            }
        }
    }

    #_desktop_user_info {
        i {
            transition: all 0.3s linear;
            color: #fff;
        }

        &:hover,
        &:focus {
            i {
                color: $brand-hover;
            }
        }

        i.active {
            transition: all .2s linear;
            color: $brand-primary;
        }
        &::after{
            content: "";
            position: absolute;
            width: 115px;
            height: 15px;
        }
    }

    @media (min-width: 1200px) {
        .left-block, .right-block {
            width: 25%;
            justify-content: flex-end;
        }
        .center-block {
            width: 50%;
            justify-content: center;
        }
    }
}

/*** Responsive part ***/
@media screen and (min-width: 1881px) {
    #_desktop_logo {

    }
}
@media (min-width: 991px) and (max-width: 1880px) {
    #header .header-top .position-static .bon-nav-bar > div:not(:last-child) {
        padding-right: calc(15px + (40 - 15) * ((100vw - 991px) / (1880 - 991)));
    }

}
@media (min-width: 1261px) {
    .bon-nav-bar > div,
    #_desktop_logo {
        padding: 22px 0;
    }
    .icon-text {
        padding-left: 15px;
    }
    body #header {
        .header-top {
            position: relative;
            border-bottom: 1px solid rgba(236, 236, 236, 0.3);
            &.sticky-head {
                background-color: $vert-medium !important;
                #_desktop_logo {
                    display: none !important;
                }
                .logo-sticky {
                    display: block !important;
                    padding: 0 !important;
                    img {
                        height: 100%;
                        margin-top: 0;
                        padding: 5px 15px !important;
                        width: 150px;
                    }
                }
            }
        }
    }
    #index {
        #header {
            border: none;
            .header-top {
                position: absolute;
                width: 100%;
                background: transparent !important;
                border-bottom: 1px solid rgba(236, 236, 236, 0.3);
                &.sticky-head {
                    background-color: $vert-medium !important;
                }
            }
            .header-banner {
                border: none;
            }
        }
    }
}
@media (max-width: 1260px) {
    body #header {
        .header-top {
            background-color: #313131;
            border-bottom: 1px solid rgba(236, 236, 236, 0.3);
        }
    }
    #header .header-top .position-static .bon-nav-bar > div:not(.modal) .icon-text {
        margin-top: -1px;
    }
    #_desktop_top_menu .top-menu[data-depth="0"] li:hover .sub-menu {
        -webkit-transform: translateY(74px);
        transform: translateY(74px);
    }
    #header .header-top {
        padding: 15px 0;
        position: relative;
    }
    #header .header-top .position-static .bon-nav-bar>div,
    #boncompare {
        flex-direction: column;
    }
}



@media screen and (min-width: 577px) and (max-width: 991px){
    #header .header-top .position-static .bon-nav-bar > div:not(:last-child) {
        padding-right: calc(25px + (40 - 25) * ((100vw - 320px) / (991 - 320)));
    }
}
@media (max-width: 991px) {
    #header .header-top {
        padding: 16px 0;
    }

    #header {
        padding: 0;
        .header-top {

            .position-static {
                position: relative !important;
                padding-right: 10px;
                padding-left: 10px;

                .bon-nav-bar {
                    margin-top: 0;
                    position: relative;
                    margin-right: 10px;
                }
            }



            .header-contact.left-block {
                display: flex;
                justify-content: space-between;
                border-top: 1px solid #414141;
                padding-bottom: 30px;

                a {
                    color: #fff;

                    i {
                        margin-right: 15px;
                    }

                }

                li {
                    padding-top: 20px;
                }

            }
        }

        .header-banner {
            padding: 20px 0;

            .header-contact-info {
                padding: 0 10px;
                align-items: center;
            }

            .header-contact.left-block {
                display: none !important;
            }

            .header-contact.right-block {
                a {
                    font-size: 14px;
                    margin-right: 7px;

                }

                li {
                    padding-right: 12px;

                    i.material-icons {
                        vertical-align: -4px;
                    }
                }
                
            }
        }
        
        #_desktop_currency_selector,
        #_desktop_language_selector{
            display: none;
        }

    }

    #_desktop_setting-header {
        display: none;
    }

    #_mobile_logo {

        h1 {
            margin-bottom: 0;
        }
    }
}

@media (max-width: 767px) {
    #header {
        .header-top {
            .position-static {
                margin: 0;
            }

            .menu-list {
                flex-wrap: wrap;

                li {
                    width: 50%;
                    margin-right: 0;
                }
            }
        }
    }
}

@media  screen and (min-width: 577px){
    #header .header-contact.right-block .customersignin_wrapper {
        display: inline-block!important;
    }
    #header #_desktop_user_info .user-info i,
    #header .header-top .position-static .bon-nav-bar #_desktop_user_info{
        display: none;
    }
}

@media screen and (max-width: 576px){
    #header .header-top .position-static .bon-nav-bar > div:not(:last-child) {
        padding-right: calc(30px + (40 - 30) * ((100vw - 320px) / (991 - 320)));
    }
    #header .header-top {
        .position-static .bon-nav-bar > div .icon-text {
            display: none;
        }
        .position-static .bon-nav-bar > .customersignin_wrapper{
            display: none;
        }
    }

    #header .header-banner {
        padding: 10px 0;
        .header-contact-info {
            flex-direction: row;
        }
        .header-contact.right-block {
            a {
                font-size: 11px;
                margin-right: 0;
            }

            li {
                margin-left: 0;
            }
            #_desktop_user_info {
                display: none!important;
                
            }
            .customersignin_wrapper {
            
            }
        }
    }
}

@media (min-width: 768px) {
    .transition_fadeInUp:not(.click_open_submenu) li:hover .mm_columns_ul, .transition_fade:not(.click_open_submenu) .mm_menus_li.mm_has_sub:hover .mm_columns_ul, .transition_fade.click_open_submenu .mm_menus_li.mm_has_sub .mm_columns_ul.open {
        animation: none !important;
        animation-name: none !important;
        animation-duration: unset !important;

    }
}